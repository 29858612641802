<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-sheet
          class="d-flex flex-row justify-center align-center pa-5"
          outlined
          rounded
        >
          <span class="green--text text-h3 mr-3 font-weight-medium">{{ matchedProducts.length }}</span>
          <span>Items Matched</span>
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-sheet
          class="d-flex flex-row justify-center align-center pa-5"
          outlined
          rounded
        >
          <span class="orange--text text-h3 mr-3">{{ unmatchedProducts.length }}</span>
          <span>Items Unmatched</span>
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-sheet
          class="d-flex flex-row justify-center align-center pa-5"
          outlined
          rounded
        >
          <span class="primary--text text-h3 mr-3">{{ uncountedProducts.length }}</span>
          <span>Items Uncounted</span>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SummarisedStockCountFormSubHeader',
  props: {
    uncountedProducts: {
      type: Array,
      required: true,
      default: () => ([])
    },
    unmatchedProducts: {
      type: Array,
      required: true,
      default: () => ([])
    },
    matchedProducts: {
      type: Array,
      required: true,
      default: () => ([])
    }
  }
}
</script>
