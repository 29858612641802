var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-end",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Inventory Actions "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-list',{staticClass:"pa-0",attrs:{"width":"236"}},[_c('v-list-item',{attrs:{"disabled":_vm.noWarehousesExist,"to":"/stock-count-overview"}},[_c('v-list-item-content',[_vm._v(" Stock Reports ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.noWarehousesExist || !_vm.multiWarehouseEnabled,"to":"/transfers-overview"}},[_c('v-list-item-content',[_vm._v(" Transfer Reports ")])],1),_c('v-list-item',{attrs:{"disabled":_vm.loading.allStockDownload},on:{"click":_vm.downloadAllStock}},[_c('v-list-item-content',[_vm._v(" Download All Stock ")])],1),_c('v-dialog',{attrs:{"eager":"","light":"","width":"900"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.noWarehousesExist}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_vm._v(" Sales Channels Management ")])],1)]}}]),model:{value:(_vm.dialogs.salesChannels),callback:function ($$v) {_vm.$set(_vm.dialogs, "salesChannels", $$v)},expression:"dialogs.salesChannels"}},[_c('section',{staticClass:"pa-5 white"},[_c('transfer-sales-channels',{ref:"transfersaleschannels",on:{"cancel":_vm.closeSalesChannels,"saved":_vm.closeSalesChannels}})],1)]),_c('v-dialog',{attrs:{"light":"","width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.createInventoryLocationDisallowed}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_vm._v(" Create Inventory Location ")])],1)]}}]),model:{value:(_vm.dialogs.createWarehouse),callback:function ($$v) {_vm.$set(_vm.dialogs, "createWarehouse", $$v)},expression:"dialogs.createWarehouse"}},[_c('section',{staticClass:"pa-5 white"},[_c('inventory-location-form',{on:{"cancel":function($event){_vm.dialogs.createWarehouse = false},"save":_vm.handleNewLocation}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }