<template>
  <section>
    <h2 class="headings--text text-h2 mb-4">Summary</h2>

    <h4 class="headings--text text-h4 mb-0">{{ form.name }}</h4>
    <p v-if="form.description" class="mt-2" >{{ form.description }}</p>

    <summarised-stock-count-form-header v-bind="subComponentBindings" :warehouse="warehouse" />
    <summarised-stock-count-form-sub-header v-bind="subComponentBindings" />
    <summarised-stock-count-form-panels v-bind="subComponentBindings" />

    <v-container fluid>
        <v-row>
          <v-col cols="12">
            <p v-if="error" class="text-right red--text">
              {{ error }}
            </p>
            <section class="d-sm-flex flex-row justify-end">
              <v-btn
                color="primary"
                class="mr-8"
                outlined
                large
                :disabled="loading"
                @click="downloadStockCountCSV"
              >
                CSV Export
              </v-btn>
              <v-btn
                color="primary"
                class="mr-8"
                large
                outlined
                :disabled="loading"
                @click="downloadStockCountReport"
              >
                Print Summary
              </v-btn>
              <v-btn
                v-if="readonly"
                color="primary"
                large
                @click="$emit('close')"
              >
                Close Summary
              </v-btn>
              <v-btn
                v-else
                color="primary"
                large
                :disabled="loading"
                to="/"
              >
                Close Summary
              </v-btn>
            </section>
          </v-col>
        </v-row>
      </v-container>
  </section>
</template>

<script>
import SummarisedStockCountFormHeader from './SummarisedStockCountFormHeader'
import SummarisedStockCountFormSubHeader from './SummarisedStockCountFormSubHeader'
import SummarisedStockCountFormPanels from './SummarisedStockCountFormPanels'
import Messages from '@/lib/messages.json'

export default {
  name: 'SummaryStockCountForm',
  props: {
    form: {
      type: Object,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => (false)
    }
  },
  components: {
    SummarisedStockCountFormHeader,
    SummarisedStockCountFormSubHeader,
    SummarisedStockCountFormPanels
  },
  data: () => ({
    panel: null,
    loading: false,
    error: '',
    success: false,
    summary: {}
  }),
  computed: {
    userid () {
      return this.$store.getters['user/userid']
    },
    unmatchedProducts () {
      return this.form.products.filter(product => product.status === 'unmatched')
    },
    matchedProducts () {
      return this.form.products.filter(product => product.status === 'matched')
    },
    uncountedProducts () {
      return this.form.products.filter(product => product.status === 'uncounted')
    },
    subComponentBindings () {
      return {
        uncountedProducts: this.uncountedProducts,
        matchedProducts: this.matchedProducts,
        unmatchedProducts: this.unmatchedProducts,
        form: this.form,
        warehouse: this.warehouse,
        readonly: this.readonly
      }
    }
  },
  methods: {
    downloadStockCountCSV () {
      this.loading = true

      return this.$store.dispatch('inventory/downloadStockCountCSV', this.form.count_id)
        .catch((error) => {
          this.error = Messages.downloadStockCountCSV.error
          console.error(error.message ?? error.statusText ?? error)
        })
        .finally(() => (this.loading = false))
    },
    downloadStockCountReport () {
      this.loading = true

      return this.$store.dispatch('inventory/downloadStockCountReport', this.form.count_id)
        .catch((error) => {
          this.error = Messages.downloadStockCountReport.error
          console.error(error.message ?? error.statusText ?? error)
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>
