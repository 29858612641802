var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"page":_vm.pageModel,"headers":_vm.headers,"items":_vm.model,"search":_vm.search,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"item-class":_vm.itemClasses,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.pageModel=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-0 body-2",attrs:{"disabled":_vm.loading,"label":"Search by stock count id, name...","prepend-inner-icon":"mdi-magnify","flat":"","clearable":"","simple":"","solo":"","plain":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"mb-1"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"d-block body-2"},[_vm._v(" "+_vm._s(item.reference)+" ")])])]}},{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reference !== '' ? item.reference : '-')+" ")]}},{key:"item.warehouse",fn:function(ref){
var item = ref.item;
return [_c('warehouse-table-cell',{attrs:{"uuid":item.warehouse_id}})]}},{key:"item.staff_account",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s([null, '', undefined].includes(item.userid) ? '-' : item.userid)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('stock-count-date-cell',{attrs:{"date":item.created}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"subtitle-2 text-capitalize",attrs:{"color":"green","text-color":"white","small":""}},[_vm._v(" "+_vm._s([null, '', undefined].includes(item.status) ? 'Complete' : item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('stock-count-actions-cell',{attrs:{"item":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }