import fetch from './fetch'
import store from '../store'

export async function request (endpoint, { body, method, ...customConfig } = {}) {
  const headers = {
    'Content-Type': 'application/json',
    'X-Site-ID': store.getters.siteid,
    Authorization: `Bearer ${store.getters['user/JWT']}`
  }

  const defaultMethod = body ? 'POST' : 'GET'

  const config = {
    method: method || defaultMethod,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let data

  try {
    const response = await fetch(endpoint, config)

    if (response.ok) {
      data = await response.json()

      return data
    }

    throw response
  } catch (err) {
    return Promise.reject(err)
  }
}

request.get = function (endpoint, customConfig = {}) {
  return request(endpoint, { ...customConfig, method: 'GET' })
}

request.post = function (endpoint, body, customConfig = {}) {
  return request(endpoint, { ...customConfig, body, method: 'POST' })
}

request.put = function (endpoint, body, customConfig = {}) {
  return request(endpoint, { ...customConfig, body, method: 'PUT' })
}

request.delete = function (endpoint, body, customConfig = {}) {
  return request(endpoint, { ...customConfig, body, method: 'DELETE' })
}
