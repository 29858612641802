import { request } from '@/lib/request'
import objToQueryString from '@/lib/objToQueryString'
import merge from 'lodash.merge'
import messages from '@/lib/messages.json'

export default {
  init ({ dispatch }) {
    return new Promise((resolve, reject) => {
      return Promise.all([dispatch('loadConfig')])
        .then(() => resolve())
        .catch((error) => reject(error))
    })
  },
  // Config
  loadConfig ({ dispatch, getters, commit }) {
    return new Promise((resolve, reject) => {
      return request.get(getters.configRequestsEndpoint)
        .then((config) => {
          const mergedConfig = merge({}, getters.defaultConfig, config)
          commit('STORE_INVENTORY_CONFIG', mergedConfig)
          resolve(mergedConfig)
        })
        .catch((error) => reject(error))
    })
  },
  updateConfig ({ getters, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      return request.put(getters.configRequestsEndpoint, payload)
        .then(_ => {
          dispatch('loadConfig')
          dispatch('notifications/displayNotification', { type: 'success', text: messages.salesChannelsConfiguration.success }, { root: true })
          resolve()
        })
        .catch(error => reject(error))
    })
  },
  createStockTransfer ({ dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      request.post(getters.transferRequestsEndpoint, payload)
        .then((data) => {
          dispatch('notifications/displayNotification', { type: 'success', text: messages.inventoryStockTransfer.success }, { root: true })
          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  loadStockTransfers ({ getters, commit }, payload) {
    const requestQueryParameters = {
      warehouse_id: '',
      start: 0,
      limit: 50,
      ...payload
    }

    if ([null, ''].includes(requestQueryParameters.warehouse_id)) {
      delete requestQueryParameters.warehouse_id
    }

    const endpoint = getters.transferRequestsEndpoint.concat('?', objToQueryString(requestQueryParameters))

    return new Promise((resolve, reject) => {
      request.get(endpoint)
        .then((response) => {
          commit('ADD_TRANSFERS', response)
          resolve(response)
        })
        .catch((error) => reject(error))
    })
  },
  clearTransfers ({ commit }) {
    commit('CLEAR_TRANSFERS')
  },
  // Products
  loadProduct ({ getters }, payload) {
    return new Promise((resolve, reject) => {
      request.get(getters.apiRequestsEndpoint.concat('/product/', payload))
        .then((data) => resolve(data))
        .catch((error) => reject(error))
    })
  },
  updateProductWarehouses ({ getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      request.post(getters.apiRequestsEndpoint.concat('/product/', payload.product.id), payload.warehouses)
        .then((data) => {
          dispatch('notifications/displayNotification', { type: 'success', text: messages.productInventoryLocationAssignment.success }, { root: true })
          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  // Warehouse
  loadWarehouse ({ getters, commit }, payload) {
    return new Promise((resolve, reject) => {
      request.get(getters.apiRequestsEndpoint.concat('/warehouse/', payload))
        .then((data) => {
          commit('SET_WAREHOUSE_INVENTORY', {
            key: payload,
            value: data
          })

          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  loadWarehouseProduct ({ getters, commit }, payload) {
    return new Promise((resolve, reject) => {
      request.get(getters.apiRequestsEndpoint.concat('/warehouse/', payload.warehouse, '/', payload.product))
        .then((data) => {
          commit('SET_WAREHOUSE_PRODUCT_INVENTORY', {
            warehouse: payload.warehouse,
            product: data[0]
          })

          resolve(data[0])
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // Stock Count
  createStockCount ({ getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      request.post(getters.stockCountRequestsEndpoint, payload)
        .then((data) => {
          dispatch('notifications/displayNotification', { type: 'success', text: messages.inventoryStockCount.success }, { root: true })
          resolve(data)
        })
        .catch((error) => reject(error))
    })
  },
  downloadStockCountCSV ({ dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const stockCountData = getters.stockCounts.find((stockCount) => stockCount.count_id === payload) ?? {}

      const reportConfig = {
        template: {
          name: 'STOCK_COUNT',
          recipe: 'text'
        },
        options: {
          template: {
            endpoint: `${rootGetters.serviceEndpoint('inventory')}/api/v1/admin/inventory/stock-count/${payload}`
          }
        },
        filename: `StockCountExport-${stockCountData.created}.csv`
      }

      return dispatch('reports/download', reportConfig, { root: true })
        .then(() => {
          dispatch('notifications/displayNotification', { type: 'success', text: messages.downloadStockCountCSV.success }, { root: true })
          resolve()
        })
        .catch((err) => reject(err))
    })
  },
  downloadStockCountReport ({ dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const stockCountData = getters.stockCounts.find((stockCount) => stockCount.count_id === payload) ?? {}
      const reportConfig = {
        template: {
          name: 'stock_report',
          recipe: 'chrome-pdf'
        },
        options: {
          template: {
            endpoint: `${rootGetters.serviceEndpoint('inventory')}/api/v1/admin/inventory/stock-count/${payload}`
          }
        },
        filename: `StockCountReport-${stockCountData.reference}.pdf`
      }

      return dispatch('reports/download', reportConfig, { root: true })
        .then(() => {
          dispatch('notifications/displayNotification', { type: 'success', text: messages.downloadStockCountReport.success }, { root: true })
          resolve()
        })
        .catch((err) => reject(err))
    })
  },
  downloadAllStockTransfers ({ dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const reportConfig = {
        template: {
          name: 'stock_transfer',
          recipe: 'text'
        },
        options: {
          template: {
            endpoint: rootGetters.serviceEndpoint('datastore').concat('/graphql'),
            variables: {
              ms_endpoint: rootGetters.serviceEndpoint('inventory')
            }
          }
        },
        filename: `AllStockTransfers-${Date.now()}.csv`
      }

      return dispatch('reports/download', reportConfig, { root: true })
        .then(() => {
          dispatch('notifications/displayNotification', { type: 'success', text: messages.downloadAllStockTransfers.success }, { root: true })
          resolve()
        })
        .catch((err) => reject(err))
    })
  },
  downloadAllStock ({ dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      const reportConfig = {
        template: {
          name: 'stock_totals',
          recipe: 'text'
        },
        options: {
          template: {
            endpoint: rootGetters.serviceEndpoint('datastore').concat('/graphql'),
            variables: {
              ms_endpoint: rootGetters.serviceEndpoint('inventory')
            }
          }
        },
        filename: `AllStock-${Date.now()}.csv`
      }

      return dispatch('reports/download', reportConfig, { root: true })
        .then(() => {
          dispatch('notifications/displayNotification', { type: 'success', text: messages.downloadAllStock.success }, { root: true })
          resolve()
        })
        .catch((err) => reject(err))
    })
  },

  loadStockCounts ({ getters, commit }, payload) {
    const requestQueryParameters = {
      warehouse_id: '',
      start: 0,
      limit: 50,
      ...payload
    }

    if ([null, ''].includes(requestQueryParameters.warehouse_id)) {
      delete requestQueryParameters.warehouse_id
    }

    const endpoint = getters.stockCountRequestsEndpoint.concat('?', objToQueryString(requestQueryParameters))

    return new Promise((resolve, reject) => {
      request.get(endpoint)
        .then((response) => {
          commit('ADD_STOCK_COUNTS', response)
          resolve(response)
        })
        .catch((error) => reject(error))
    })
  },
  clearStockCounts ({ commit }) {
    commit('CLEAR_STOCK_COUNTS')
  }
}
