var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('address-card',{attrs:{"title":_vm.name,"icon":_vm.icon,"address":_vm.addressString},scopedSlots:_vm._u([{key:"append-address",fn:function(){return [_c('v-menu',{attrs:{"offset-x":"","left":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$vcEllipsisIcon")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',{staticClass:"pa-0",attrs:{"width":"236"}},[_c('v-dialog',{attrs:{"width":"1000","light":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":!_vm.multiWarehouseEnabled}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Create Stock Transfer")])],1)],1)]}}]),model:{value:(_vm.dialogs.stockTransfer),callback:function ($$v) {_vm.$set(_vm.dialogs, "stockTransfer", $$v)},expression:"dialogs.stockTransfer"}},[_c('stock-transfer-form',{ref:"stocktransferform",attrs:{"prepopulate-transfer":{ origin_warehouse_id: _vm.id },"visible":_vm.dialogs.stockTransfer},on:{"transferCreated":function($event){_vm.dialogs.stockTransfer = false},"cancel":function($event){_vm.dialogs.stockTransfer = false}}})],1),_c('v-list-item',{attrs:{"to":("/inventory-location/" + _vm.id + "/count-stock")}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Create Stock Count ")])],1)],1),_c('v-dialog',{attrs:{"width":"1000","light":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"ripple":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Edit Location ")])],1)],1)]}}]),model:{value:(_vm.dialogs.editLocation),callback:function ($$v) {_vm.$set(_vm.dialogs, "editLocation", $$v)},expression:"dialogs.editLocation"}},[_c('section',{staticClass:"pa-5 white"},[_c('inventory-location-form',{ref:"inventorylocationform",attrs:{"location":_vm.item,"update":""},on:{"cancel":function($event){_vm.dialogs.editLocation = false},"updated":function($event){_vm.dialogs.editLocation = false}}})],1)])],1)],1)]},proxy:true},{key:"pre-actions",fn:function(){return [(_vm.salesChannels.length > 0)?_c('section',{staticClass:"mt-4"},[_c('p',{staticClass:"headings--text subtitle-2"},[_vm._v("Sales Channels:")]),_vm._l((_vm.salesChannels),function(channel){return _c('v-chip',{key:channel,staticClass:"mr-3 headings--text body-2",attrs:{"outlined":"","label":""}},[_vm._v(" "+_vm._s(channel)+" ")])})],2):_vm._e()]},proxy:true},{key:"address-card-actions",fn:function(){return [_c('section',{staticClass:"d-flex justify-end align-end mt-auto"},[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","to":("/inventory-location/" + _vm.id + "/inventory")}},[_vm._v(" View Inventory ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }