<template>
  <v-chip
    v-if="status !== ''"
    :color="color"
    :text-color="textColor"
    small
    class="subtitle-2 text-capitalize"
  >
    {{ status }}
  </v-chip>
</template>

<script>
export default {
  name: 'TransferStatusCell',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    color () {
      if (this.status === 'pending') return 'orange'
      if (this.status === 'complete') return 'green'

      return ''
    },
    textColor () {
      if (this.status === 'pending') return 'brown'

      return 'white'
    }
  }
}
</script>
