<template>
  <v-snackbar
    v-if="notifications.length > 0"
    bottom
    right
    fixed
    :value="true"
    :timeout="-1"
    color="transparent"
    elevation="0"
  >
    <v-slide-y-transition
      class="py-0"
      tag="div"
      group
      appear
    >
      <Notification
        v-for="(notification, index) in notifications"
        :key="index"
        v-bind="notification"
      />
    </v-slide-y-transition>
  </v-snackbar>
</template>

<script>
import Notification from './Notification'

export default {
  name: 'NotificationsManager',
  components: {
    Notification
  },
  computed: {
    notifications () {
      return this.$store.getters['notifications/all']
    }
  }
}
</script>
