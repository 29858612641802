<template>
  <v-list-item-content>
    <v-list-item-title>{{ item.title }}</v-list-item-title>
    <v-list-item-subtitle><small>SKU: {{ sku }} | Status: <span :class="{ 'orange--text': !item.status }">{{ status }}</span></small></v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
export default {
  name: 'AutocompleteProductItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    sku () {
      try {
        return this.item.productsettings.sku
          ? this.item.productsettings.sku
          : '-'
      } catch {
        return '-'
      }
    },
    status () {
      try {
        return this.item.status
          ? 'Published'
          : 'Unpublished'
      } catch {
        return '-'
      }
    }
  }
}
</script>
