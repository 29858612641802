export default {
  config: (state) => state.config,
  defaultCountry: (state) => {
    try {
      return state.config.address.default_country
    } catch {
      return 'au'
    }
  },
  allowedCountries: (state) => {
    try {
      return state.config.address.allowed_countries
    } catch {
      return {
        au: 'Australia'
      }
    }
  },
  regions: (state) => state.regions,
  products: (state) => state.products,
  wineclubs: (state) => state.wineclubs,
  singleProduct: (state) => (pid) => {
    return state.products[pid] ?? {}
  }
}
