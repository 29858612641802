<template>
  <section>
    <h4 class="headings--text text-h4 mb-0">{{ form.name }}</h4>
    <p
      v-if="form.description"
      class="mt-2"
    >{{ form.description }}</p>

    <section class="mt-11">
      <p class="text-h6 headings--text">Product Filter</p>
      <p>These products can then be tabbed through to add the stock count on the number field.</p>
      <v-data-table
        :headers="headers"
        :items="filteredProductsModel"
        :search="search"
        item-key="value"
        disable-pagination
        hide-default-footer
        fixed-header
        height="500"
        class="mb-16"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search via SKU or Product Title"
            class="mx-0 body-2"
            flat
            clearable
            simple
            solo
            plain
            outlined
            dense
          ></v-text-field>
          <v-tabs v-model="filterIndex">
            <v-tab
              v-for="item in filters"
              :key="item.label"
              class="text-capitalize text-h6"
            >
              {{ item.label }}
              <transition
                name="bounce"
                mode="out-in"
              >
                <v-chip
                  pill
                  outlined
                  small
                  class="ml-2 px-2 counter-pill"
                  :class="tabUpdated(item.value)"
                >
                  {{ item.quantity }}
                </v-chip>
              </transition>
            </v-tab>
          </v-tabs>
          <v-divider class="mb-8" />
        </template>
        <template v-slot:item.status="slotProps">
          <compare-stock-count-row-status :status="slotProps.value" />
        </template>
        <template v-slot:item.stock="{ item }">
          <span :class="loadStatusTextColor(item)">
            {{ item.stock }}
          </span>
        </template>
        <template v-slot:item.counted_stock="{ item }">
          <v-text-field
            :key="item.product_id"
            type="number"
            :value="item.counted_stock"
            tabindex="0"
            hide-details
            outlined
            dense
            @blur="(value) => updateCountedStock(item.product_id, value)"
          />
        </template>
      </v-data-table>
      <section class="d-md-flex flex-row align-center justify-space-between">
        <section>
          <v-btn
            color="body"
            outlined
            large
            @click="$router.go(-1)"
          >
            Cancel Stock Count
          </v-btn>
        </section>
        <section class="d-flex flex-row align-center justify-end">
          <v-menu
            v-model="menus.notes"
            :close-on-content-click="false"
            min-width="400"
            top
            left
            offset-y
            eager
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="mr-4"
                outlined
                dark
                large
              >
                {{ form.note !== '' ? 'Edit Note' : 'Add Note' }}
              </v-btn>
            </template>

            <stock-count-note-editor
              ref="stockcountnoteeditor"
              :form.sync="formModel"
              @cancel="menus.notes = false"
              @save="menus.notes = false"
            />
          </v-menu>
          <v-dialog
            v-model="dialogs.confirmationDialog"
            persistent
            max-width="400"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                large
                :disabled="form.products.length <= 0 || loading"
                :loading="loading"
                v-on="on"
                v-bind="attrs"
              >
                Complete Stock Count

                <template v-slot:loader>
                  <custom-loader />
                </template>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 headings--text text-center">
                Submit Stock Count
              </v-card-title>
              <v-card-text>
                <p>Are you sure you want to submit this stock count?</p>

                <p>Once submitted, stock counts are only available for preview and not adjustable.</p>
              </v-card-text>

              <v-card-actions class="justify-end">
                <v-btn
                  color="warning"
                  @click="dialogs.confirmationDialog = false"
                >
                  No
                </v-btn>
                <v-btn
                  color="success"
                  @click="confirmStockCountSubmission"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
import CompareStockCountRowStatus from './CompareStockCountRowStatus'
import CustomLoader from '@/components/generic/CustomLoader'
import productMethods from '@/mixins/productMethods'
import StockCountNoteEditor from './StockCountNoteEditor'

export default {
  name: 'ItemisedStockCountForm',
  props: {
    form: {
      type: Object,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    }
  },
  mixins: [productMethods],
  components: {
    CompareStockCountRowStatus,
    CustomLoader,
    StockCountNoteEditor
  },
  data: () => ({
    dialogs: {
      confirmationDialog: false
    },
    headers: [
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'status',
        class: 'headings--text'
      },
      {
        text: 'SKU',
        align: 'start',
        sortable: true,
        value: 'productsettings.sku',
        class: 'headings--text'
      },
      {
        text: 'Product',
        align: 'start',
        sortable: true,
        value: 'title',
        class: 'headings--text'
      },
      {
        text: 'Expected Stock',
        align: 'start',
        sortable: true,
        value: 'stock',
        class: 'headings--text'
      },
      {
        text: 'Counted Stock',
        align: 'start',
        sortable: false,
        value: 'counted_stock',
        width: '150',
        class: 'headings--text'
      }
    ],
    menus: {
      notes: false
    },
    filterIndex: 0,
    search: '',
    tabChanged: {
      uncounted: false,
      matched: false,
      unmatched: false
    },
    loading: false,
    error: false
  }),
  computed: {
    formModel: {
      get () {
        return this.form
      },
      set (val) {
        return this.$emit('update:form', val)
      }
    },
    filters () {
      return [
        {
          label: 'All',
          value: 'all',
          quantity: this.productsCount.all
        },
        {
          label: 'Uncounted',
          value: 'uncounted',
          quantity: this.productsCount.uncounted
        },
        {
          label: 'Matched',
          value: 'matched',
          quantity: this.productsCount.matched
        },
        {
          label: 'Unmatched',
          value: 'unmatched',
          quantity: this.productsCount.unmatched
        }
      ]
    },
    statusFilter () {
      return this.filters[this.filterIndex].value
    },
    productsCount () {
      const products = this.productsModel

      const productsCount = {
        all: products?.length,
        uncounted: 0,
        matched: 0,
        unmatched: 0
      }

      products.map((product) => productsCount[product.status]++)

      return productsCount
    },
    productsModel () {
      return this.form.products.map((product) => {
        const stockUncounted = [null, '', undefined].includes(
          product.counted_stock
        )
        const stockMatches =
          !stockUncounted &&
          Number(product.counted_stock) === Number(product.stock)

        let status = 'unmatched'
        if (stockUncounted) status = 'uncounted'
        if (stockMatches) status = 'matched'

        product.status = status

        return product
      })
    },
    filteredProductsModel () {
      if (this.statusFilter === 'all') return this.productsModel

      return this.productsModel.filter(
        (product) => product.status === this.statusFilter
      )
    }
  },
  methods: {
    loadStatusTextColor (item) {
      if (item.status === 'uncounted') return 'primary--text'
      if (item.status === 'matched') return 'green--text'
      else return 'orange--text'
    },
    updateCountedStock (productID, event, brr) {
      const that = this
      const productIndex = that.form.products.findIndex(
        (product) => product.product_id === productID
      )

      setTimeout(function () {
        that.$set(
          that.form.products[productIndex],
          'counted_stock',
          event.srcElement.value
        )
      }, 1000)
    },
    confirmStockCountSubmission () {
      this.dialogs.stockCountConfirmation = false
      this.submitStockCount()
    },
    submitStockCount () {
      this.loading = true
      this.error = false

      return this.$store
        .dispatch('inventory/createStockCount', {
          name: this.form.name,
          description: this.form.description,
          products: this.form.products,
          warehouse_id: this.warehouse.warehouse_id,
          reference: this.form.reference,
          notes: this.form.note
        })
        .then((results) => {
          this.$emit('update:form', { ...this.form, ...results[0] })
          this.$emit('next')
        })
        .catch((responseError) => {
          console.error(responseError.statusText)
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    tabUpdated (key) {
      return this.tabChanged[key] ? 'updated' : ''
    }
  },
  watch: {
    'menus.notes': {
      handler (active) {
        if (active) this.$refs.stockcountnoteeditor.setNote()
      }
    },
    'productsCount.unmatched': {
      handler (newValue, oldValue) {
        if (oldValue >= 0 && newValue > oldValue) { this.tabChanged.unmatched = true }
        setTimeout(() => {
          this.tabChanged.unmatched = false
        }, 600)
      },
      immediate: true
    },
    'productsCount.uncounted': {
      handler (newValue, oldValue) {
        if (oldValue >= 0 && newValue > oldValue) { this.tabChanged.uncounted = true }
        setTimeout(() => {
          this.tabChanged.uncounted = false
        }, 600)
      },
      immediate: true
    },
    'productsCount.matched': {
      handler (newValue, oldValue) {
        if (oldValue >= 0 && newValue > oldValue) { this.tabChanged.matched = true }
        setTimeout(() => {
          this.tabChanged.matched = false
        }, 600)
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.counter-pill {
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
}
.bounce-enter-active {
  transition: all 0.2s ease;
}
.bounce-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.bounce-enter,
.bounce-leave-to {
  transform: translateY(-0.25em);
  opacity: 0;
}
.v-chip.v-chip--outlined.v-chip.v-chip.updated {
  background-color: #2850d0 !important;
  border-color: #2850d0;
  color: #fff;
}
</style>
