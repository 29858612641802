<template>
  <section>
    <table-filters
      :filters="filters"
      :loading="loading"
      :disabled="loading"
      @tabClicked="updateFilter"
    />

    <v-divider class="mb-8" />

    <data-table-error
      v-if="error"
      retry-allowed
      @retry="loadTableData"
    >
      <template v-slot:title>
        Oops!
      </template>
      <template v-slot:description>
        An error occurred while loading the requested data.
      </template>
    </data-table-error>
    <warehouse-transfers-list-view-table
      :active-filter="activeFilter"
      :table-data="tableData"
      :loading="loading"
      :error="error"
      :page.sync="page"
      :items-per-page="itemsPerPage"
    />
  </section>
</template>

<script>
import WarehouseTransfersListViewTable from './WarehouseTransfersListViewTable'
import DataTableError from '@/components/generic/DataTableError'
import TableFilters from '@/components/generic/TableFilters'

export default {
  name: 'WarehouseTransfers',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    WarehouseTransfersListViewTable,
    TableFilters,
    DataTableError
  },
  data: () => ({
    loading: false,
    error: false,
    tab: null,
    activeFilter: 'all',
    page: 1,
    itemsPerPage: 10,
    queryArguments: {
      start: 0,
      limit: 50
    },
    totalRecordsRequested: false
  }),
  computed: {
    tableData () {
      return this.$store.getters['inventory/transfers']
    },
    filters () {
      const allCount = this.tableData.length
      const pending = this.tableData.reduce((acc, transfer) => {
        if (transfer.transfer_status === 'pending') { acc++ }
        return acc
      }, 0)

      const complete = this.tableData.reduce((acc, transfer) => {
        if (transfer.transfer_status === 'complete') { acc++ }
        return acc
      }, 0)

      return [
        {
          label: 'All Transfers',
          value: 'all',
          quantity: allCount
        },
        {
          label: 'Pending Transfers',
          value: 'pending',
          quantity: pending
        },
        {
          label: 'Completed Transfers',
          value: 'complete',
          quantity: complete
        }
      ]
    }
  },
  created () {
    this.freshLoadTableData()
  },
  methods: {
    updateFilter (payload) {
      this.activeFilter = payload.value
    },
    freshLoadTableData () {
      try {
        this.queryArguments = { ...this.queryArguments, start: 0 }
        this.totalRecordsRequested = false

        this.$store.dispatch('inventory/clearTransfers')
          .then(() => this.loadTableData())
      } catch {
        this.error = true
      }
    },
    loadTableData () {
      this.loading = true
      this.error = false

      return this.$store.dispatch('inventory/loadStockTransfers', { ...this.queryArguments, warehouse_id: this.id })
        .then((data) => {
          if (data.length < this.queryArguments.limit) {
            this.totalRecordsRequested = true
          }
        })
        .catch((responseError) => {
          this.error = true
          console.error(responseError.statusText)
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadMore () {
      this.queryArguments.start += this.queryArguments.limit

      this.loadTableData()
    }
  },
  watch: {
    page: {
      handler: function (newval) {
        if (!this.totalRecordsRequested && (this.tableData.length / this.itemsPerPage) === newval) {
          this.loadMore()
        }
      }
    },
    loading: {
      handler: function (newval) {
        this.$emit('loadingChanged', newval)
      },
      immediate: true
    },
    id: {
      handler: 'freshLoadTableData'
    }
  }
}
</script>
