<template>
  <summarised-stock-count-form-panel
    :products="matchedProducts"
    header-classes="green--text"
    no-products-label="There are no Matched items in this stock count."
  >
    <template v-slot:expansion-panel-header>
      Matched ({{ matchedProducts.length }})
    </template>
  </summarised-stock-count-form-panel>
</template>

<script>
import SummarisedStockCountFormPanel from './SummarisedStockCountFormPanel'

export default {
  name: 'MatchedStockCountPanel',
  props: {
    matchedProducts: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  components: {
    SummarisedStockCountFormPanel
  },
  computed: {
    accountedInventoryTotal () {
      return this.matchedProducts.reduce((acc, product) => (acc += product.productsettings.unitPrice), 0.00)
    }
  }
}
</script>
