<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <warehouse-locations class="mb-10"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WarehouseLocations from '@/components/warehouse-locations/WarehouseLocations.vue'

export default {
  name: 'Home',
  components: {
    WarehouseLocations
  }
}
</script>
