<template>
  <section class="stock-transfer-details-form">
    <h4 class='text-h4 headings--text mb-4'>Transfer Details</h4>

    <v-container fluid class="px-0">
      <v-row>
        <v-col>
          <input-label
            label="Transfer ID"
            tooltip="A unique identifier for each Stock Transfer."
          />
          <v-text-field
            v-model="formModel.reference"
            placeholder='Transfer Reference ID'
            :rules="[
              (v) => !!(v) || 'Transfer ID is required.'
            ]"
            outlined
            persistent-hint
            dense
            hide-details="auto"
            flat
            single-line
            @focus="disableAutoGenerate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <input-label
            label="Transfer Reason"
          />
          <v-select
            v-model="formModel.transfer_reason"
            :items='reasonOptions'
            :menu-props="{ bottom: true, offsetY: true }"
            label='Select a transfer reason'
            hide-details="auto"
            outlined
            solo
            flat
            persistent-hint
          />
        </v-col>
      </v-row>
      <v-row v-if="formModel.transfer_reason === 'Other'">
        <v-col>
          <input-label label="Other" />
          <v-textarea
            v-model="formModel.transfer_other"
            hint='This is a description for the field.'
            label='This component is a multi-line input text-field, useful for larger amounts of text entered by the user.'
            name='transfer-reason'
            maxlength="255"
            placeholder='This component is a multi-line input text-field, useful for larger amounts of text entered by the user.'
            outlined
            persistent-hint
            dense
            hide-details="auto"
            flat
            single-line
            counter
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import InputLabel from '@/components/generic/InputLabel'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'StockTransferFormDetails',
  components: {
    InputLabel
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => (false)
    }
  },
  data: () => ({
    autoGenerate: true,
    reasonOptions: ['Stock Transfer', 'Return Items', 'Restock Items', 'Other']
  }),
  computed: {
    ...mapGetters({
      find: 'warehouses/find'
    }),
    formModel: {
      get () {
        return this.value
      },
      set (val) {
        return this.$emit('input', val)
      }
    },
    originWarehouseData () {
      return this.find(this.value.origin_warehouse_id)
    }
  },
  methods: {
    disableAutoGenerate () {
      this.autoGenerate = false
    },
    generateTransferReference () {
      try {
        // if (!this.originWarehouseData || !this.originWarehouseData.name || !this.autoGenerate) return ''
        const type = 'TR'
        const date = dayjs().format('DDMMYY-HH:mm')
        // const inventory = this.originWarehouseData.name.substring(0, 3)

        this.formModel.reference = type.concat('-', date)
        this.formModel.transfer_reason = 'Stock Transfer'
      } catch {}
    }
  },
  watch: {
    originWarehouseData: {
      handler (val) {
        if (this.autoGenerate && val) this.generateTransferReference()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
