<template>
  <v-menu
    v-model="active"
    bottom
    left
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="primary"
        class="float-end"
      >
        Inventory Actions
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list
      class="pa-0"
      width="236"
    >
      <v-list-item
        :disabled="noWarehousesExist"
        to="/stock-count-overview"
      >
        <v-list-item-content>
          Stock Reports
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :disabled="noWarehousesExist || !multiWarehouseEnabled"
        to="/transfers-overview"
      >
        <v-list-item-content>
          Transfer Reports
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :disabled="loading.allStockDownload"
        @click="downloadAllStock"
      >
        <v-list-item-content>
          Download All Stock
        </v-list-item-content>
      </v-list-item>
      <v-dialog
        v-model="dialogs.salesChannels"
        eager
        light
        width="900"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
            :disabled="noWarehousesExist"
          >
            <v-list-item-content>
              Sales Channels Management
            </v-list-item-content>
          </v-list-item>
        </template>

        <section class="pa-5 white">
          <transfer-sales-channels
            ref="transfersaleschannels"
            @cancel="closeSalesChannels"
            @saved="closeSalesChannels"
          />
        </section>
      </v-dialog>
      <v-dialog
        v-model="dialogs.createWarehouse"
        light
        width="1000"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
            :disabled="createInventoryLocationDisallowed"
          >
            <v-list-item-content>
              Create Inventory Location
            </v-list-item-content>
          </v-list-item>
        </template>

        <section class="pa-5 white">
          <inventory-location-form
            @cancel="dialogs.createWarehouse = false"
            @save="handleNewLocation"
          />
        </section>
      </v-dialog>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import InventoryLocationForm from '@/components/inventory-location-form/InventoryLocationForm'
import TransferSalesChannels from '@/components/TransferSalesChannels'
import Messages from '@/lib/messages.json'

export default {
  name: 'warehouse-locations-actions',
  components: {
    InventoryLocationForm,
    TransferSalesChannels
  },
  data: () => ({
    active: false,
    dialogs: {
      createWarehouse: false,
      salesChannels: false,
      stockTransfer: false
    },
    loading: {
      allStockDownload: false
    }
  }),
  computed: {
    ...mapGetters({
      warehouses: 'warehouses/all',
      multiWarehouseEnabled: 'warehouses/multiWarehouseEnabled'
    }),
    noWarehousesExist () {
      return this.warehouses.length <= 0
    },
    createInventoryLocationDisallowed () {
      const warehouseLimitMet = this.warehouses.length < 1

      if (this.multiWarehouseEnabled) return false
      else return !warehouseLimitMet
    }
  },
  methods: {
    toggleDialog () {
      this.dialog = !this.dialog
    },
    closeDialog () {
      this.dialog = false
    },
    closeSalesChannels () {
      this.dialogs.salesChannels = false
    },
    handleSaved () {
      this.closeDialog()
    },
    closeStockTransfer () {
      this.dialogs.stockTransfer = false
    },
    handleNewLocation () {
      this.dialogs.createWarehouse = false
    },
    downloadAllStock () {
      this.loading.allStockDownload = true
      return this.$store
        .dispatch('inventory/downloadAllStock')
        .catch((error) => {
          this.error = Messages.downloadAllStock.error
          console.error(error.message ?? error.statusText ?? error)
        })
        .finally(() => (this.loading.allStockDownload = false))
    }
  },
  watch: {
    'dialogs.salesChannels': {
      handler: function (active) {
        if (active) this.$refs.transfersaleschannels.setConfigOptions()
      },
      deep: true
    },
    dialogs: {
      handler: function (val) {
        if (Object.values(val).some(active => active)) this.active = false
      },
      deep: true
    }
  }
}
</script>
