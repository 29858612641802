<template>
  <section>
    <titlebar
      title="Sales Channels"
      class="mb-7"
    >
      <template v-slot:title="slotProps">
        <h4 class='text-h4 headings--text mb-5'>{{ slotProps.title }}</h4>
      </template>
      <template v-slot:subtitle>
        <span>Select a Primary Inventory Location for each Sales Channel.</span>
      </template>
    </titlebar>
    <v-data-table
      :headers="headers"
      :items="configOptions"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      hide-default-footer
    >
      <template v-slot:item.salesChannel="{ item }">
        {{ channelDescriptions[item.key] }}
      </template>
      <template v-slot:item.warehouse="{ item }">
        <section
          v-if="item.key === 'pos'"
          class="py-5 px-0"
        >
          <v-container fluid>
            <v-row>
              <template v-for="(channel, channelKey) in item">
                <v-col
                  v-if="channelKey !== 'key' && posChannels[channelKey]"
                  :key="channelKey"
                >
                  <section v-if="posChannels[channelKey]">
                    <span>{{ posChannels[channelKey].name }}</span>
                    <v-select
                      v-model="channel.warehouse"
                      :items="warehouseOptions"
                      :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }"
                      hide-details="auto"
                      label="Select"
                    ></v-select>
                  </section>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </section>
        <section
          v-else-if="item.key === 'somnambulist'"
          class="py-5 px-0"
        >
         <v-container fluid>
            <v-row>
              <template v-for="(channel, channelKey) in item">
                <v-col
                  v-if="channelKey !== 'key' && wineclubs[channelKey]"
                  :key="channelKey"
                  cols="12"
                >
                  <section v-if="wineclubs[channelKey]">
                    <span>{{ wineclubs[channelKey].title }}</span>
                    <v-select
                      v-model="channel.warehouse"
                      :items="warehouseOptions"
                      :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }"
                      hide-details="auto"
                      label="Select"
                    ></v-select>
                  </section>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </section>
        <v-select
          v-else
          v-model="item.warehouse"
          :items="warehouseOptions"
          :menu-props="{ maxHeight: '400', bottom: true, offsetY: true }"
          hide-details="auto"
          label="Select"
          class="my-5"
        ></v-select>
      </template>

      <template v-slot:item.from="{ item }">
        <v-select
          v-model="item.transfer"
          :items="warehouseOptions.filter(warehouse => warehouse.value !== item.warehouse)"
          :menu-props="{ maxHeight: '400' }"
          color="primary"
          label="Select"
          multiple
          chips
          small-chips
        ></v-select>
      </template>
    </v-data-table>
    <section class="text-right">
      <p
        v-if="error"
        class="red--text"
      >
        {{ messages.salesChannelsConfiguration.error }}
      </p>
      <v-btn
        color="primary"
        outlined
        class="mr-3"
        :disabled="loading"
        :loading="loading"
        @click="cancelConfig"
      >
        Cancel

        <template v-slot:loader>
          <custom-loader />
        </template>
      </v-btn>
      <v-btn
        color="primary"
        :disabled="loading"
        :loading="loading"
        @click="saveConfig"
      >
        Save

        <template v-slot:loader>
          <custom-loader />
        </template>
      </v-btn>
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Messages from '@/lib/messages.json'
import CustomLoader from '@/components/generic/CustomLoader'
import Titlebar from '@/components/generic/Titlebar'

export default {
  name: 'sales-channels-form',
  components: {
    CustomLoader,
    Titlebar
  },
  created () {
    this.messages = Messages
  },
  data () {
    return {
      error: false,
      loading: false,
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: 'Sales Channel',
          align: 'start',
          sortable: false,
          value: 'salesChannel',
          width: '10%'
        },
        {
          text: 'Primary Inventory Location',
          align: 'start',
          sortable: false,
          value: 'warehouse',
          width: '40%'
        }
      ],
      configOptions: [],
      messages: {}
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouses/all',
      config: 'inventory/config',
      defaultConfig: 'inventory/defaultConfig',
      variableValues: 'variableValues',
      wineclubs: 'ecommerce/wineclubs'
    }),
    warehouseOptions () {
      return this.warehouses.map((warehouse) => ({
        text: warehouse.name,
        value: warehouse.warehouse_id
      }))
    },
    posChannels () {
      return this.variableValues('pos_channels')
    },
    channelDescriptions () {
      return this.variableValues('transaction_type_descriptions')
    }
  },
  mounted () {
    this.setConfigOptions()
  },
  methods: {
    setConfigOptions () {
      this.configOptions = Object.entries(
        JSON.parse(JSON.stringify(this.config))
      ).map(([key, val]) => {
        return {
          key,
          ...val
        }
      })
    },
    clearConfigOptions () {
      this.configOptions = []
    },
    cancelConfig () {
      this.setConfigOptions()
      this.$emit('cancel')
    },
    saveConfig () {
      try {
        this.loading = true
        const updatedConfig = this.configOptions.reduce((acc, option) => {
          const config = {
            ...acc,
            [option.key]: option
          }

          delete config[option.key].key

          return config
        }, {})

        return this.$store
          .dispatch('inventory/updateConfig', { config: updatedConfig })
          .then(() => this.$emit('saved'))
          .catch((err) => {
            this.error = true
            console.error(err.message)
          })
      } catch (err) {
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
