<template>
  <v-simple-checkbox
    v-model="item.assigned"
    color="primary"
    :disabled="isDisabled"
    :ripple="false"
  />
</template>

<script>
export default {
  name: 'AssignedCell',
  props: {
    item: {
      type: Object,
      required: true
    },
    existingProductInventory: {
      type: Array,
      required: true
    }
  },
  computed: {
    isDisabled () {
      return this.existingProductInventory.some(warehouse => this.item.warehouse_id === warehouse.warehouse_id)
    }
  },
  watch: {
    item: {
      handler: function (val) {
        if (val.stock > 0 && !val.assigned) this.$set(this.item, 'assigned', true)
      },
      deep: true
    }
  }
}
</script>
