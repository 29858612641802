import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Logout from '../views/Logout.vue'
import WarehouseInventoryManagement from '../views/WarehouseInventoryManagement.vue'
import WarehouseStockCount from '../views/WarehouseStockCount.vue'
import WarehouseStockCountsOverview from '../views/WarehouseStockCountsOverview.vue'
import WarehouseTransfersOverview from '../views/WarehouseTransfersOverview.vue'
import ProductWarehouseAssignment from '../views/ProductWarehouseAssignment'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/inventory-location/:uuid/inventory',
    name: 'Location Inventory List',
    component: WarehouseInventoryManagement,
    props: true
  },
  {
    path: '/inventory-location/:uuid/count-stock',
    name: 'Inventory Location Stock Count',
    component: WarehouseStockCount,
    props: true
  },
  {
    path: '/stock-count-overview/:uuid?',
    name: 'Inventory Location Stock Count Overview',
    component: WarehouseStockCountsOverview,
    props: true
  },
  {
    path: '/transfers-overview/:uuid?',
    name: 'Inventory Location Transfers Overview',
    component: WarehouseTransfersOverview,
    props: true
  },
  {
    path: '/products/assign/:id?',
    name: 'Product Search',
    component: ProductWarehouseAssignment,
    props: true
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    props: true
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
