export default {
  STORE_INVENTORY_CONFIG: (state, payload) => (state.config = payload),
  SET_WAREHOUSE_INVENTORY: (state, payload) => {
    state.warehouses = {
      ...state.warehouses,
      [payload.key]: payload.value
    }
  },
  SET_WAREHOUSE_PRODUCT_INVENTORY: (state, payload) => {
    const warehouse = state.warehouses[payload.warehouse]

    // Handle this being the first warehouse inventory loaded.
    if ([null, undefined].includes(warehouse)) {
      state.warehouses = {
        ...state.warehouses,
        [payload.warehouse]: [payload.product]
      }
    } else {
      const productIndex = warehouse.findIndex(product => Number(product.product_id) === payload.product.id)

      // replace or add product
      if (productIndex !== -1) warehouse[productIndex] = payload.product
      else warehouse.push(payload.product)
    }
  },
  ADD_TRANSFERS: (state, payload) => {
    state.transfers = [...state.transfers, ...payload]
  },
  ADD_STOCK_COUNTS: (state, payload) => {
    state.stockCounts = [...state.stockCounts, ...payload]
  },
  CLEAR_STOCK_COUNTS: (state, payload) => {
    state.stockCounts = []
  },
  CLEAR_TRANSFERS: (state, payload) => {
    state.transfers = []
  }
}
