var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productsModel,"search":_vm.search,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"item-class":_vm.itemClasses,"calculate-widths:":"","true":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-0 body-2",attrs:{"label":"Search for Products by Name or SKU","prepend-inner-icon":"mdi-magnify","flat":"","clearable":"","simple":"","solo":"","plain":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('section',{staticClass:"text-center py-10"},[_c('v-icon',{staticClass:"mb-4",attrs:{"color":"primary","large":""}},[_vm._v(" mdi-alert-circle-outline ")]),_c('h4',{staticClass:"headings--text text-h4 mb-1"},[_vm._t("title",function(){return [_vm._v(" No Products Available. ")]})],2),_c('v-btn',{attrs:{"color":"primary","to":"/products/assign/"}},[_vm._v(" Add products to this location ")])],1)]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('warehouse-inventory-finder-title-cell',{attrs:{"item":item}})]}},{key:"item.productsettings.sku",fn:function(ref){
var item = ref.item;
return [(item.productsettings)?_c('span',[_vm._v(" "+_vm._s([null, '', undefined].includes(item.productsettings.sku) ? '-' : item.productsettings.sku)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.stock",fn:function(slotProps){return [_c('span',{class:_vm.loadStockClass(slotProps.item.stock)},[_c('stock-cell',{attrs:{"item":slotProps.item},on:{"reloadTable":_vm.reloadTable,"update:item":function($event){return _vm.$set(slotProps, "item", $event)}}})],1)]}},{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [(item.productsettings && item.productsettings.prices)?_c('span',{staticClass:"subtitle-2"},[_vm._v(" $"+_vm._s(Number(item.productsettings.prices.unit_price).toFixed(2))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$vcEllipsisIcon")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0",attrs:{"width":"236"}},[_c('v-list-item',{on:{"click":function($event){return _vm.stockAdjustment(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Stock Count")])],1)],1),_c('v-dialog',{attrs:{"eager":"","width":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Stock Transfer")])],1)],1)]}}],null,true),model:{value:(_vm.dialogs.stockTransfer),callback:function ($$v) {_vm.$set(_vm.dialogs, "stockTransfer", $$v)},expression:"dialogs.stockTransfer"}},[_c('stock-transfer-form',{ref:"tablerowstocktransferform",attrs:{"prepopulate-transfer":{
                origin_warehouse_id: _vm.warehouseID,
                products: [item]
              }},on:{"transferCreated":_vm.handleTransferCreated,"cancel":function($event){_vm.dialogs.stockTransfer = false}}})],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }