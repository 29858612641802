<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="12">
        <v-sheet
          class="pa-10"
          outlined
          rounded
        >
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <p class="muted--text">Stock Count ID</p>
              <p>{{ reference }}</p>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <p class="muted--text">Location</p>
              <p>{{ warehouseName }}</p>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <p class="muted--text">Created Date</p>
              <p>{{ date }}</p>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <p class="muted--text">Created By</p>
              <p>{{ createdBy }}</p>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <p class="muted--text">Notes</p>
              {{ notes }}
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import dayjsAdvanced from 'dayjs/plugin/advancedFormat'
import dayjsUTC from 'dayjs/plugin/utc'

dayjs.extend(dayjsAdvanced)
dayjs.extend(dayjsUTC)

export default {
  props: {
    form: {
      type: Object,
      required: false,
      default: () => ({})
    },
    warehouse: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    date () {
      return Object.prototype.hasOwnProperty.call(this.form, 'created') && this.readonly
        ? dayjs(this.form.created).format('DD/MM/YYYY - HH:mm:ss')
        : dayjs().format('DD/MM/YYYY - HH:mm:ss')
    },
    warehouseName () {
      return Object.prototype.hasOwnProperty.call(this.form, 'warehouse_name')
        ? this.form.warehouse_name
        : '-'
    },
    createdBy () {
      return Object.prototype.hasOwnProperty.call(this.form, 'userid') && this.readonly && this.form.userid !== this.$store.getters['user/userid']
        ? this.form.userid
        : this.$store.getters['user/fullname']
    },
    reference () {
      return this.form.reference
    },
    notes () {
      return this.form.notes.length > 0
        ? this.form.notes
        : '-'
    }
  }
}
</script>
