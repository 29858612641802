export default {
  apiEndpoint: '/api/v1/admin/warehouse',
  activeRequests: {
    warehouses: false
  },
  failedRequests: {
    warehouses: true
  },
  warehouses: []
}
