<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col>
          <titlebar
            title="Inventory Locations"
            title-classes="text-h3 headings--text d-inline-flex"
          >
            <template v-slot:actions>

              <warehouse-locations-actions/>
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="float-end mr-2"
                :disabled="noWarehousesExist"
                to="/products/assign"
              >
                Product Search
              </v-btn>
            </template>
          </titlebar>
        </v-col>
      </v-row>
    </v-container>
    <warehouse-list />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

import Titlebar from '@/components/generic/Titlebar'
import WarehouseList from '@/components/warehouse-locations/WarehouseList.vue'
import WarehouseLocationsActions from './WarehouseLocationsActions.vue'

export default {
  name: 'Home',
  components: {
    Titlebar,
    WarehouseList,
    WarehouseLocationsActions
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    ...mapGetters({
      warehouses: 'warehouses/all',
      multiWarehouseEnabled: 'warehouses/multiWarehouseEnabled'
    }),
    noWarehousesExist () {
      return this.warehouses.length <= 0
    }
  }
}
</script>
