<template>
  <section class="titlebar">
    <section>
      <slot name="title" :title="title">
        <component
          :is="titleTag"
          :class="titleClasses"
        >
          <slot name="title-inner" :title="title">
            {{ title }}
          </slot>
        </component>
      </slot>

      <slot name="actions">
        <template v-if="primaryAction">
          <v-spacer />

          <v-btn
            v-bind="primaryAction.attributes"
            v-on:click="primaryAction.onClick"
          >
            <slot name="primary-action-inner" v-bind="primaryAction">
              {{ primaryAction.label }}
            </slot>

            <v-icon
              v-if="primaryAction.icon"
              right
              medium
            >
              {{ primaryAction.icon }}
            </v-icon>
          </v-btn>
        </template>
      </slot>
    </section>
    <slot name="subtitle">
      <p v-if="subtitle">
        {{ subtitle }}
      </p>
    </slot>
  </section>
</template>

<script>
export default {
  name: 'titlebar',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    titleClasses: {
      type: String,
      required: false,
      default: 'text-h5 headings--text mb-4 mb-md-0'
    },
    titleTag: {
      type: String,
      required: false,
      default: 'h5'
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    primaryAction: {
      type: [Object, Boolean],
      required: false,
      default: () => (false)
    }
  },
  data: () => ({
    primaryActionAttributes: {
      color: 'primary'
    }
  })
}
</script>
