import { request } from '@/lib/request'
import messages from '@/lib/messages.json'

export default {
  init ({ dispatch }) {
    return Promise.all([dispatch('list')])
  },
  create ({ dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      return request.post(getters.requestsEndpoint, payload)
        .then(warehouse => {
          const updatedWarehouseList = [...rootGetters['warehouses/all'], warehouse]
          dispatch('store', updatedWarehouseList)
          dispatch('notifications/displayNotification', { type: 'success', text: messages.createInventoryLocation.success }, { root: true })
          resolve(updatedWarehouseList)
        })
        .catch((error) => {
          console.error(`Failed loading warehouses: ${error}`)
          reject(error)
        })
    })
  },
  update ({ dispatch, getters, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      return request.put(getters.requestsEndpoint.concat('/', payload.warehouse_id), payload)
        .then(updatedWarehouse => {
          const mutableWarehouses = [...rootGetters['warehouses/all']]
          const warehouseIndex = mutableWarehouses.findIndex(warehouse => warehouse.warehouse_id === updatedWarehouse.warehouse_id)

          dispatch('notifications/displayNotification', { type: 'success', text: messages.updateInventoryLocation.success }, { root: true })

          // Not found in existing data, reload warehouses.
          if (warehouseIndex === -1) {
            return dispatch('list')
          } else {
            mutableWarehouses[warehouseIndex] = updatedWarehouse
            dispatch('store', mutableWarehouses)
            resolve(mutableWarehouses)
          }
        })
        .catch((error) => {
          console.error(`Failed loading warehouses: ${error}`)
          reject(error)
        })
    })
  },
  list ({ dispatch, getters, rootGetters }) {
    return Promise.all([
      dispatch('resetFailedRequest', 'warehouses'),
      dispatch('setActiveRequest', { key: 'warehouses', value: true })
    ])
      .then(() => request.get(getters.requestsEndpoint.concat('/list')))
      .then(warehouses => dispatch('store', warehouses))
      .catch((error) => {
        dispatch('setFailedRequest', { key: 'warehouses', value: true })
        console.error(`Failed loading warehouses: ${error}`)
      })
      .finally(() => dispatch('resetActiveRequest', 'warehouses'))
  },
  store ({ commit }, payload) {
    commit('SET_WAREHOUSES_LIST', payload)
  },
  resetActiveRequest ({ dispatch }, payload) {
    dispatch('setActiveRequest', { key: payload, value: false })
  },
  setActiveRequest ({ commit }, payload) {
    commit('SET_ACTIVE_REQUEST', payload)
  },
  resetFailedRequest ({ dispatch }, payload) {
    dispatch('setFailedRequest', { key: payload, value: false })
  },
  setFailedRequest ({ commit }, payload) {
    commit('SET_FAILED_REQUEST', payload)
  }
}
