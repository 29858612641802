import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import BinIcon from '@/components/icons/Bin.vue'
import UpAndDownArrowsIcon from '@/components/icons/UpAndDownArrows.vue'
import ShopfrontIcon from '@/components/icons/Shopfront.vue'
import WarehouseIcon from '@/components/icons/Warehouse.vue'
import BuildingIcon from '@/components/icons/Building.vue'
import EllipsisIcon from '@/components/icons/Ellipsis.vue'
import BondedStorageIcon from '@/components/icons/BondedStorage.vue'
import PartnerIcon from '@/components/icons/PartnerIcon.vue'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      vcBinIcon: {
        component: BinIcon
      },
      vcUpAndDownArrows: {
        component: UpAndDownArrowsIcon
      },
      vcShopfrontIcon: {
        component: ShopfrontIcon
      },
      vcWarehouseIcon: {
        component: WarehouseIcon
      },
      vcBuildingIcon: {
        component: BuildingIcon
      },
      vcEllipsisIcon: {
        component: EllipsisIcon
      },
      vcBondedStorageIcon: {
        component: BondedStorageIcon
      },
      vcPartnerStorageIcon: {
        component: PartnerIcon
      }
    }
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#2850D0',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        info: '#F2994A',
        success: '#27AE60',
        warning: '#F2994A',
        headings: '#1F2F61',
        body: '#5B6789'
      }
    }
  }
})
