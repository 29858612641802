<template>
  <span>
    <v-btn
      class="mx-2"
      dark
      fab
      :outlined="status === 'uncounted'"
      x-small
      :color="color"
      tabindex="-1"
    >
      <v-icon small tabindex="-1">{{ icon }}</v-icon>
    </v-btn>
    <span :class="`${color}--text text-capitalize`">
      {{ status }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'CompareStockCountRowStatus',
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    icon () {
      if (this.status === 'uncounted') return ''
      if (this.status === 'matched') return 'mdi-check'
      else return 'mdi-close'
    },
    color () {
      if (this.status === 'uncounted') return 'primary'
      if (this.status === 'matched') return 'green'
      else return 'orange'
    }
  }
}
</script>
