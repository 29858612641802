<template>
  <section>
    <v-data-table
      :page.sync="pageModel"
      :headers="headers"
      :items="model"
      :search="search"
      :loading="loading"
      :items-per-page="itemsPerPage"
      :item-class="itemClasses"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :disabled="loading"
          label="Search by stock count id, name..."
          prepend-inner-icon="mdi-magnify"
          class="mx-0 body-2"
          flat
          clearable
          simple
          solo
          plain
          outlined
          dense
        ></v-text-field>
      </template>
      <template v-slot:item.name="{ item }">
        <div>
          <span class="mb-1">{{ item.name }}</span>
          <span class="d-block body-2">
            {{ item.reference }}
          </span>
        </div>
      </template>
      <template v-slot:item.reference="{ item }">
        {{ item.reference !== '' ? item.reference : '-' }}
      </template>
      <template v-slot:item.warehouse="{ item }">
        <warehouse-table-cell :uuid="item.warehouse_id" />
      </template>
      <template v-slot:item.staff_account="{ item }">
        {{
          [null, '', undefined].includes(item.userid)
            ? '-'
            : item.userid
        }}
      </template>
      <template v-slot:item.created="{ item }">
        <stock-count-date-cell :date="item.created" />
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          color="green"
          text-color="white"
          small
          class="subtitle-2 text-capitalize"
        >
          {{
          [null, '', undefined].includes(item.status)
            ? 'Complete'
            : item.status
        }}
        </v-chip>

      </template>
      <template v-slot:item.actions="{ item }">
        <stock-count-actions-cell :item="item" />
      </template>
    </v-data-table>
  </section>
</template>

<script>
import warehouseTableCell from './WarehouseTableCell'
import stockCountDateCell from './StockCountDateCell'
import stockCountActionsCell from './stockCountActionsCell'

export default {
  name: 'StockCountListViewTable',
  props: {
    activeFilter: {
      type: String,
      required: true,
      default: () => 'all',
      validator: (value) => ['all', 'pending', 'complete'].includes(value)
    },
    error: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    tableData: {
      type: [Array, Object],
      required: true
    },
    page: {
      type: [String, Number],
      required: true
    },
    itemsPerPage: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    warehouseTableCell,
    stockCountDateCell,
    stockCountActionsCell
  },
  data: () => ({
    pageCount: 0,
    search: '',
    sortBy: 'created',
    sortDesc: true,
    headers: [
      {
        text: 'Stock Count Name',
        align: 'start',
        sortable: true,
        value: 'name',
        class: 'headings--text',
        width: '25%'
      },
      {
        text: 'Created Date',
        align: 'start',
        sortable: true,
        value: 'created',
        width: '15%',
        class: 'headings--text'
      },
      {
        text: 'Location',
        align: 'start',
        sortable: true,
        value: 'warehouse',
        class: 'headings--text'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
        class: 'headings--text'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions',
        width: '100px',
        class: 'headings--text'
      }
    ]
  }),
  computed: {
    pageModel: {
      get () {
        return this.page
      },
      set (val) {
        return this.$emit('update:page', val)
      }
    },
    model () {
      return this.tableData
    }
  },
  methods: {
    itemClasses (item) {
      return 'headings--text body-2'
    }
  },
  watch: {
    page: {
      handler: function (newval) {
        this.$emit('updatePage', newval)
      }
    }
  }
}
</script>
