<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <unmatched-stock-count-panel :unmatched-products="unmatchedProducts" />
          <matched-stock-count-panel :matched-products="matchedProducts" />
          <uncounted-stock-count-panel :uncounted-products="uncountedProducts" />
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import unmatchedStockCountPanel from './UnmatchedStockCountPanel'
import matchedStockCountPanel from './MatchedStockCountPanel'
import uncountedStockCountPanel from './UncountedStockCountPanel'

export default {
  name: 'SummarisedStockCountFormPanels',
  components: {
    unmatchedStockCountPanel,
    matchedStockCountPanel,
    uncountedStockCountPanel
  },
  props: {
    uncountedProducts: {
      type: Array,
      required: true
    },
    matchedProducts: {
      type: Array,
      required: true
    },
    unmatchedProducts: {
      type: Array,
      required: true
    },
    form: {}
  },
  data: () => ({
    panel: null
  })
}
</script>
