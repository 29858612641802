<template>
  <section>
    <v-container
      class="px-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <section v-if="loading">
            <v-row>
              <v-col
                v-for="i in 3"
                :key="i"
                cols="12"
                lg="4"
                md="6"
              >
                <v-skeleton-loader
                  class="mb-6"
                  elevation="2"
                  type="list-item-avatar, divider, list-item-three-line"
                />
              </v-col>
            </v-row>
          </section>
          <section v-else-if="warehouses.length">
            <v-row>
              <v-col
                :key="item.title"
                cols="12"
                lg="4"
                md="6"
                v-for="item in sortedWarehouses"
              >
                <warehouse-list-item :item="item" />
              </v-col>
            </v-row>
          </section>
          <section v-else>
            <v-row>
              <v-col cols="12">
                <v-sheet
                  class="pa-5 text-center"
                  outlined
                  rounded
                >
                  <section v-if="failed">
                    <v-icon
                      class="mb-4"
                      large
                    >
                      mdi-alert
                    </v-icon>
                    <h3 class="text-h4 headings--text mb-1">
                      An error ocurred while loading
                      warehouses.</h3>
                    <v-btn
                      color="primary"
                      @click="loadWarehouses"
                    >
                      Retry
                    </v-btn>
                  </section>
                  <section v-else>
                    <v-icon
                      class="mb-4"
                      color="primary"
                      x-large
                    >
                      $vcBuildingIcon
                    </v-icon>
                    <h4 class="text-h4 headings--text mb-1">
                      No warehouse locations found.</h4>

                    <v-dialog
                      v-model="dialog"
                      light
                      width="1000"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Create a warehouse
                        </v-btn>
                      </template>

                      <section class="pa-5 white">
                        <inventory-location-form @save="toggleDialog" @cancel="this.dialog = false"/>
                      </section>
                    </v-dialog>
                  </section>
                </v-sheet>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InventoryLocationForm from '@/components/inventory-location-form/InventoryLocationForm'
import WarehouseListItem from './WarehouseListItem'

export default {
  name: 'warehouse-list',
  components: {
    WarehouseListItem,
    InventoryLocationForm
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    ...mapGetters({
      warehouses: 'warehouses/all',
      failedRequests: 'warehouses/failedRequests',
      activeRequests: 'warehouses/activeRequests'
    }),
    loading () {
      return this.activeRequests.warehouses
    },
    failed () {
      return this.failedRequests.warehouses
    },
    sortedWarehouses () {
      return this.warehouses.slice().sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions({
      loadWarehouses: 'warehouses/list'
    }),
    toggleDialog () {
      this.dialog = !this.dialog
    }
  }
}
</script>
