<template>
  <!-- <v-text-field
    v-if="quantityEditable"
    v-model="itemModel.low_stock"
    type="number"
    width="160px"
    hide-details="auto"
    dense
    class="mb-0i"
    outlined
    solo
    flat
    :rules="[
      function(val) {
        const regex = new RegExp('^\\d+$')
        if (!regex.test(val)) return 'Please enter in a numerical value for Quantity'
        if (Number.isNaN(val)) return 'Please enter in a numerical value for Quantity'
        if (val < 0) return 'You cannot assign negative inventory to a location.'

        return true
      }
    ]"
  /> -->
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#000"
          plain
          small
          v-bind="attrs"
          v-on="on"
          class="font-weight-bold"
        >
            {{ item.low_stock }}
          <v-icon
            right
            dark
          >
          mdi-menu-down
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <!-- <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="message"
                color="purple"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Enable messages</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="hints"
                color="purple"
              ></v-switch>
            </v-list-item-action>
            <v-list-item-title>Enable hints</v-list-item-title>
          </v-list-item>
        </v-list> -->

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="menu = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
</template>

<script>
export default {
  name: 'LowStockCell',
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true
  }),
  props: {
    item: {
      type: Object,
      required: true
    },
    existingInventory: {
      type: Array,
      required: true
    }
  },
  computed: {
    itemModel: {
      get () {
        return this.item
      },
      set (val) {
        this.$emit('update:item', val)
      }
    },
    quantityEditable () {
      return !this.existingInventory.find(warehouse => (this.item.warehouse_id === warehouse.warehouse_id))
    }
  }
}
</script>
