<template>
  <section>
    {{ formattedDate }}
  </section>
</template>

<script>
import dayjs from 'dayjs'
import dayjsAdvanced from 'dayjs/plugin/advancedFormat'

dayjs.extend(dayjsAdvanced)

export default {
  name: 'StockCountDateCell',
  props: {
    date: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedDate () {
      return dayjs(this.date).format('DD/MM/YYYY - HH:mm:ss')
    }
  }
}
</script>
