<template>
  <section>
    <input-label
      label="Stock Count Name"
      tooltip="Provide a name for your Stock Count, eg. July Stock Take 2021."
    />
    <v-text-field
      v-model="formModel.name"
      label=""
      hide-details="auto"
      flat
      outlined
      solo
      required
      class="mb-8"
    />

    <input-label
      label="Description (optional)"
    />
    <v-textarea
      v-model="formModel.description"
      placeholder="Our Annual stocktake at the barossa valley warehouuse before christmas sales come through."
      rows="4"
      row-height="15"
      hint="This is a description for this field."
      class="mb-8"
      outlined
      required
      persistent-hint
    />

    <p class="headings--text text-h6 mb-4">Warehouse for Stock Count:</p>

    <v-sheet
      class="d-sm-flex flex-row align-center pa-4 mb-8"
      outlined
      rounded
    >
      <address-resource
        :title="name"
        :address="addressString"
        :icon="icon"
        title-classes="subtitle-1"
      />

      <v-dialog
        v-model="dialog"
        width="600"
        rounded
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            outlined
            color="primary"
          >
            Change
          </v-btn>
        </template>

        <warehouse-select-dialog
          :initial-value="uuid"
          @save="changeWarehouse"
          @cancel="closeDialog"
        />
      </v-dialog>
    </v-sheet>

    <section class="mb-8">
      <input-label label="Stock Count ID" />
      {{ formModel.reference }}
      <!-- <v-text-field
        v-model="formModel.reference"
        placeholder="Stock Count ID"
        rows="4"
        :rules="[
          (v) => !!(v) || 'Stock Count ID is required.'
        ]"
        row-height="15"
        class="mb-8"
        hide-details="auto"
        outlined
        required
        persistent-hint
        @focus="disableAutoGenerate"
      /> -->
    </section>

    <p class="text-h6 headings--text">Stock Count Applies to:</p>

    <v-radio-group v-model="formModel.applicationType">
      <v-radio
        v-for="item in stockApplicationOptions"
        :key="item.key"
        :label="item.label"
        color="primary"
        :value="item.key"
      />
    </v-radio-group>

    <v-scroll-y-transition>
      <section v-if="formModel.applicationType === 'specific-products'">
        <v-autocomplete
          v-model="formModel.products"
          :items="combinedProductsData"
          :loading="loading"
          :filter="productSearchFilter"
          :disabled="loading"
          :search-input.sync="search"
          class="body-2"
          item-text="title"
          item-value="title"
          prepend-inner-icon="mdi-magnify"
          dense
          solo
          flat
          hide-no-data
          hide-selected
          multiple
          outlined
          return-object
        >
          <template v-slot:selection></template>
          <template v-slot:item="data">
            <autocomplete-product-item :item="data.item" />
          </template>
        </v-autocomplete>
        <v-data-table
          :headers="headers"
          :items="formModel.products"
          fixed-header
          height="500"
          disable-pagination
          hide-default-footer
          hide-default-header
          dense
        >
          <template v-slot:item.main="{ item }">
            <resource-item
              :key="item.id"
              :sku="item.productsettings.sku"
              :title="item.title"
              :image="generateProductImagePath(item)"
              :three-line="false"
            />
          </template>
          <template v-slot:item.actions="slotProps">
            <section class="d-flex flex-row align-start justify-end">
              <v-btn
                icon
                small
                plain
                class="ml-3"
                @click="removeProductByID(slotProps.item.id)"
              >
                <v-icon color="primary">$vcBinIcon</v-icon>
              </v-btn>
            </section>
          </template>
        </v-data-table>
      </section>
    </v-scroll-y-transition>

    <section class="d-flex flex-row justify-space-between">
      <v-btn
        color="body"
        large
        outlined
        @click="$router.go(-1)"
      >
        Cancel Stock Count
      </v-btn>
      <v-btn
        color="primary"
        large
        :disabled="!progressionAllowed"
        @click="generateStockCount"
      >
        Generate Stock Count
      </v-btn>
    </section>
  </section>
</template>

<script>
import InputLabel from '@/components/generic/InputLabel'
import warehouseMixin from '@/mixins/warehouse'
import WarehouseSelectDialog from '@/components/WarehouseSelectDialog'
import AddressResource from '@/components/generic/AddressResource'
import ResourceItem from '@/components/generic/ResourceItem'
import productMethods from '@/mixins/productMethods'
import AutocompleteProductItem from '@/components/generic/AutocompleteProductItem'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'CreateStockCountForm',
  mixins: [warehouseMixin, productMethods],
  props: {
    uuid: {
      type: String,
      required: true,
      default: ''
    },
    form: {
      type: Object,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  components: {
    WarehouseSelectDialog,
    InputLabel,
    AddressResource,
    ResourceItem,
    AutocompleteProductItem
  },
  computed: {
    ...mapGetters({
      find: 'warehouses/find',
      filestores: 'filestores/all',
      productData: 'ecommerce/singleProduct'
    }),
    progressionAllowed () {
      if (this.form.name === '') return false
      if (this.form.reference === '') return false

      if (this.form.applicationType === 'specific-products') {
        return this.form.products.length > 0
      }

      return true
    },
    item () {
      return this.warehouse
    },
    selectProductsEnabled () {
      return this.form.applicationType === 'specific-products'
    },
    combinedProductsData () {
      return [...this.warehouseProducts].map((product) => {
        return {
          ...product,
          ...this.productData(product.product_id),
          ...{ counted_stock: null, assigned: true }
        }
      })
    },
    formModel: {
      get () {
        return this.form
      },
      set (val) {
        return this.$emit('update:form', val)
      }
    }
  },
  data: () => ({
    dialog: false,
    stockApplicationOptions: [
      {
        label: 'All Products',
        key: 'all-products'
      },
      {
        label: 'Specific Products',
        key: 'specific-products'
      }
    ],
    headers: [
      {
        text: 'Product',
        align: 'start',
        sortable: false,
        value: 'main',
        width: '80%'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions',
        width: '20%'
      }
    ],
    items: [],
    search: '',
    autoGenerateReference: true
  }),
  methods: {
    removeProductByID (productID) {
      const productIndex = this.form.products.findIndex(
        (product) => product.id === productID
      )

      if (productIndex === -1) return

      this.formModel.products.splice(productIndex, 1)
    },
    changeWarehouse (uuid) {
      this.$router.push(`/inventory-location/${uuid}/count-stock`)
      this.closeDialog()
    },
    closeDialog () {
      this.dialog = false
    },
    generateStockCount () {
      this.$emit('next')
    },
    disableAutoGenerate () {
      this.autoGenerateReference = false
    },
    generateTransferReference () {
      const type = 'SC'
      const date = dayjs().format('DDMMYY-HH:mm')
      // const inventory = this.item.name.substring(0, 3)

      this.formModel.reference = type.concat('-', date)
    }
  },
  watch: {
    'form.applicationType': function (val, previous) {
      this.formModel.products = []

      if (val === 'all-products') {
        this.formModel.products = this.combinedProductsData
      }
    },
    warehouse: {
      handler (val) {
        if (!this.autoGenerateReference || !val.name) return

        this.generateTransferReference()
      },
      immediate: true
    },
    loading: {
      handler (val) {
        if (!val && this.formModel.applicationType === 'all-products') {
          this.formModel.products = this.combinedProductsData
        }
      },
      immediate: true
    }
  }
}
</script>
