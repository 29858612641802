module.exports = `
  query getProducts($start: Int, $limit: Int) {
    products(start: $start, limit: $limit, active: null) {
      ... on ContentInterface {
        status
        id
        title
      }
      ...on ProductInterface {
        images {
          image {
            preset
            filestorename
            filepath
            data {
              alt
              title
            }
          }
        }
        productsettings {
          prices {
            unit_price
          }
          sku
        }
      }
    }
  }

  query getWineClubs {
    wineclubs: somnambulistclublist{
      title,
      id,
      clubid
    }
  }
`
