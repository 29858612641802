export default {
  methods: {
    generateProductImagePath (item) {
      try {
        if (!Object.prototype.hasOwnProperty.call(item, 'images') || item.images.length === 0) return ''

        const image = item.images[0].image
        if (!image) return ''

        const filestore = this.filestores[image.filestorename]

        return filestore.endpoint.concat('/', filestore.filestoreID, '/', image.filepath)
      } catch (_) {
        return ''
      }
    },
    productSearchFilter (item, queryText) {
      const filters = [item.title ?? '']

      if (Object.prototype.hasOwnProperty.call(item, 'productsettings') && Object.prototype.hasOwnProperty.call(item.productsettings, 'sku')) filters.push(item.productsettings.sku)

      return filters.some(itemText => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
    }
  }
}
