import merge from 'lodash/merge'
import applogger from '@/lib/applogger'
const jsonwebtoken = () => import('jsonwebtoken')

const inBrowser = () => typeof window !== 'undefined'

// const decodeJWT = function (jwt) {
//   return jsonwebtoken()
//     .then(lib => {
//       return lib.decode(jwt)
//     })
// }

const verifyJWT = function (auth) {
  return jsonwebtoken()
    .then(lib => {
      if (lib.verify(auth.jwt, auth.pubkey)) {
        return lib.decode(auth.jwt)
      }
      throw Error('Authorization Token Verification failed.')
    })
    .then(JWTPayload => {
      if (JWTPayload.expires && new Date() <= new Date(JWTPayload.expires)) {
        return JWTPayload
      }
      throw new Error('Authorization Token Expired')
    })
}

// local storage safe wrappers
function setLocalStorage (key, value) {
  try {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, value)
    }
  } catch (err) {
    // No-op
  }
}
function removeLocalStorage (key) {
  try {
    if (typeof localStorage !== 'undefined') {
      localStorage.removeItem(key)
    }
  } catch (err) {
    // No-op
  }
}

const anonymous = {
  user_id: 0,
  roles: [0],
  permissions: [],
  name: 'Anonymous'
}

const state = () => ({
  authJWT: '',
  RSAPublicKey: '',
  JWTPayload: {},
  Person: false,
  anonPermissions: null,
  userPermissions: null,
  initUserPromise: null,
  refreshInterval: null,
  loggedIn: false
})

const actions = {
  login ({ commit, state, rootGetters, dispatch }, payload) {
    return rootGetters.getGraphQLClient().query({
      query: `query login($u: String!, $p: String!) {
        jwt: login(username: $u, password: $p)
        pubkey: authkey
      }`,
      variables: { u: payload.username, p: payload.password },
      opName: 'login'
    }).then((response) => dispatch('loginAttemptHandler', response))
  },
  loginAttemptHandler ({ commit, state, rootGetters, dispatch }, response) {
    const errorsFound = Object.prototype.hasOwnProperty.call(response, 'errors') && response.errors.length > 0

    if (!response.data.jwt || !response.data.pubkey) {
      throw new Error('Authentication failed.')
    } else if (errorsFound) {
      throw new Error(response.errors[0].message)
    }

    return Promise.all([response.data])
      .then(([auth]) => Promise.all([verifyJWT(auth), auth]))
      .then(([payload, auth]) => {
        commit('storeJWT', { ...auth, JWTPayload: payload })
        dispatch('checkLoginFreshness', { jwt: auth.jwt }, { root: true })
        return dispatch('getUserPermissions')
      })
      .then((permissions) => {
        commit('setUserPermissions', permissions)
        dispatch('updateLocalStorage', 'user', { root: true })
        return (Object.keys(state.JWTPayload).length > 0)
      })
  },
  logout ({ commit }) {
    commit('removeAuth')
    clearInterval(state.refreshInterval)
    state.refreshInterval = null
    return this.dispatch('generalReset')
  },

  getUserPermissions ({ rootGetters, rootState, getters }) {
    const uid = getters.userid
    const url = rootGetters.registry.api.dataStore + `/vuejs/user/permissions/${uid}`
    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${getters.verifiedJWT}`
      }
    })
      .then((resp) => resp.json())
      .then((resp) => (resp.data.permissions || {}))
      .catch(e => {
        applogger.error('Error retreiving User Permissions: ', e.message)
        return {}
      })
  },

  storeunVerifiedJWT ({ state, commit }, payload) {
    return verifyJWT({ jwt: payload.jwt, pubkey: payload.pubkey })
      .then(JWTPayload => {
        commit('storeJWT', { ...payload, JWTPayload })
      })
  }
}

const getters = {
  isLoggedIn: (state) => {
    return (Object.keys(state.JWTPayload).length > 0)
  },
  userid: (state) => {
    return (Object.keys(state.JWTPayload).length > 0) ? state.JWTPayload.user_id : anonymous.user_id
  },
  mail: (state) => {
    return (Object.keys(state.JWTPayload).length > 0) ? state.JWTPayload.email : ''
  },
  username: (state) => {
    return (Object.keys(state.JWTPayload).length > 0) ? state.JWTPayload.name : anonymous.name
  },
  fullname: (state) => {
    return (Object.keys(state.JWTPayload).length > 0) ? state.JWTPayload.name.fullname : anonymous.name
  },
  hasAnyRole: (state) => {
    return (roles) => {
      if (Object.keys(state.JWTPayload).length > 0) {
        return Object.keys(state.JWTPayload.user_roles)
          .map(r => parseInt(r))
          .some(x => roles.map(r => parseInt(r)).includes(x))
      }
      return false
    }
  },
  hasAllRoles: (state) => {
    return (roles) => {
      if (Object.keys(state.JWTPayload).length > 0) {
        return Object.keys(state.JWTPayload.user_roles)
          .map(r => parseInt(r))
          .every(x => roles.map(r => parseInt(r)).includes(x))
      }
      return false
    }
  },
  isDeveloper: (state) => {
    if (state.JWTPayload && Object.keys(state.JWTPayload).length > 0 && Object.keys(state.userPermissions).length > 0) {
      return (state.userPermissions.global.indexOf('developer access') !== -1)
    }
    return false
  },
  checkPermissions: (state) => {
    return (permission) => {
      if (state.userPermissions && Object.keys(state.userPermissions).length > 0) {
        return (
          (state.userPermissions.global && state.userPermissions.global.includes('developer access')) ||
          (permission && state.userPermissions.role && state.userPermissions.role.includes(permission)) ||
          (permission && state.userPermissions.global && state.userPermissions.global.includes(permission))
        )
      }
      return false
    }
  },
  JWT: (state) => {
    return (state.authJWT) ? state.authJWT : false
  },
  verifiedJWT: (state) => {
    return (state.authJWT && state.RSAPublicKey && verifyJWT({ jwt: state.authJWT, pubkey: state.RSAPublicKey })) ? state.authJWT : false
  },
  name: (state) => {
    return (Object.keys(state.JWTPayload).length > 0) ? state.JWTPayload.name : null
  }
}

const mutations = {
  SAVE_ACCOUNT_PERSON (state, person) {
    state.Person = person
  },
  storeJWT (state, authToken) {
    if (!Object.prototype.hasOwnProperty.call(authToken, 'jwt')) {
      applogger.error('storeJWT called without a  jwt parameter')
    }

    if (inBrowser()) {
      setLocalStorage('JWTToken', authToken.jwt)
    }

    state.authJWT = authToken.jwt

    if (authToken.pubkey) {
      state.RSAPublicKey = authToken.pubkey
    }

    if (!Object.prototype.hasOwnProperty.call(authToken, 'JWTPayload')) {
      // This is bad, but we call the verifyJWT function here to ensure that we have a valid JWT payload for other functions
      verifyJWT({ jwt: state.authJWT, pubkey: state.RSAPublicKey })
        .then(JWTPayload => {
          state.JWTPayload = JWTPayload
          // if (inBrowser() && window.logrocket !== undefined) {
          //   window.logrocket.login(state.JWTPayload)
          // }
        })
    } else {
      state.JWTPayload = authToken.JWTPayload
      // if (inBrowser() && window.logrocket !== undefined) {
      //   window.logrocket.login(state.JWTPayload)
      // }
    }
  },
  setAnonPermissions (state, permissions) {
    permissions = { global: [], ...permissions }
    // setLocalStorage('AnonPermissions', JSON.stringify(permissions))
    state.anonPermissions = permissions
  },
  setUserPermissions (state, permissions) {
    permissions = merge({}, permissions, { global: state.JWTPayload.permissions || [], role: [], create: [] })
    // setLocalStorage('UserPermissions', JSON.stringify(permissions))
    state.userPermissions = permissions
  },
  removeAuth (state) {
    removeLocalStorage('JWTToken')
    removeLocalStorage('UserPermissions')
    state.authJWT = ''
    state.RSAPublicKey = ''
    state.loggedIn = false
    state.JWTPayload = {}
    state.userPermissions = state.anonPermissions

    // if (inBrowser() && window.logrocket !== undefined) {
    //   window.logrocket.logout()
    // }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
