<template>
  <v-container>
    <v-row>
      <v-col class="py-0">
        <titlebar
          title="Stock Count"
          title-classes="text-h3 headings--text"
        />
        <p
          v-if="form.step === 1"
          class="mt-8 black--text mb-0">
          Create a Stock Count to adjust Product Stock levels at your selected Inventory Location. Select from specific or all Products (Stock Take) to generate your Stock Count.
        </p>
        <v-divider class="my-8" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-scroll-y-transition mode="out-in">
          <component
            :is="activeForm"
            :key="form.step"
            :uuid="uuid"
            :form.sync="form"
            :warehouse="warehouse"
            :warehouse-products="warehouseProducts"
            :loading="loading"
            @next="next"
            @prev="prev"
            class="pb-16"
          />
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Titlebar from '@/components/generic/Titlebar'
import CreateStockCountForm from '@/components/stock-count-forms/CreateStockCountForm'
import RecordedStockCountForm from '@/components/stock-count-forms/RecordedStockCountForm'
import SummarisedStockCountForm from '@/components/stock-count-forms/SummarisedStockCountForm'
import { mapGetters } from 'vuex'

export default {
  name: 'WarehouseStockCount',
  props: {
    uuid: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    Titlebar,
    CreateStockCountForm,
    RecordedStockCountForm,
    SummarisedStockCountForm
  },
  data: () => ({
    form: {
      step: 1,
      count_id: '',
      name: '',
      description: '',
      note: '',
      reference: '',
      applicationType: 'all-products',
      products: []
    },
    error: false,
    loading: false
  }),
  computed: {
    ...mapGetters({
      products: 'ecommerce/products',
      findWarehouse: 'warehouses/find'
    }),
    warehouse () {
      return this.findWarehouse(this.uuid)
    },
    warehouseProducts () {
      return this.$store.getters['inventory/findWarehouseInventory'](this.uuid)
    },
    activeForm () {
      const forms = {
        1: 'create-stock-count-form',
        2: 'recorded-stock-count-form',
        3: 'summarised-stock-count-form'
      }

      return forms[this.form.step]
    },
    initialProducts () {
      return this.$route.query.initialProducts
    }
  },
  mounted () {
    this.loadInventory()
      .then(() => this.checkInitialProducts())
  },
  methods: {
    loadInventory () {
      if (!this.uuid) return

      this.loading = true
      this.error = false

      return this.$store.dispatch('inventory/loadWarehouse', this.uuid)
        .catch((responseError) => {
          this.error = true
          console.error(responseError.statusText)
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkInitialProducts () {
      if (this.initialProducts !== '' && this.initialProducts) {
        const parsed = JSON.parse(this.initialProducts)

        const initialProductsResultset = Object.values(this.products).filter(product => parsed.includes(product.id))
          .map(product => ({
            ...product,
            ...(this.warehouseProducts.find(whProduct => Number(whProduct.product_id) === Number(product.id)) ?? {}),
            counted_stock: null,
            assigned: true
          }))

        this.form.products = initialProductsResultset
      }
    },
    next () {
      this.form.step++
    },
    prev () {
      this.form.step--
    },
    removeProductByID (productID) {
      const productIndex = this.form.products.findIndex((product) => product.id === productID)

      if (!productIndex) return

      this.form.products.splice(productIndex, 1)
    },
    resetProducts () {
      this.form.products = []
    }
  },
  watch: {
    uuid: {
      handler: function () {
        this.resetProducts()
        this.loadInventory()
      }
    }
  }
}
</script>
