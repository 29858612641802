<template>
  <v-list-item class="align-start px-0">
    <v-sheet
      v-if="icon"
      outlined
      rounded
      class="pa-3"
      >
      <v-icon color="primary" size="32px">{{ icon }}</v-icon>
    </v-sheet>
    <v-list-item-content class="py-0 align-start">
      <v-card-title
        class="headings--text font-weight-medium pt-0 px-0"
        :class="titleClasses"
      >
        {{ title }}
      </v-card-title>
      <v-card-subtitle class="py-0 px-0">{{ address }}</v-card-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'address-resource',
  props: {
    titleClasses: {
      type: String,
      required: false,
      default: 'text-h6'
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    address: {
      type: String,
      required: true,
      default: ''
    },
    icon: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>
