<template>
  <section>
    <table-filters
      :filters="filters"
      :loading="loading"
      @tabClicked="updateFilter"
    />

    <v-divider class="mb-8" />

    <warehouse-inventory-finder-table
      :products="products"
      :active-filter="activeFilter"
      :loading="loading"
      :error="error"
      :warehouseID="id"
      @reloadInventory="loadInventory"
    />
  </section>
</template>

<script>
import WarehouseInventoryFinderTable from './WarehouseInventoryFinderTable'
import TableFilters from '@/components/generic/TableFilters'

export default {
  name: 'inventory-finder',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    WarehouseInventoryFinderTable,
    TableFilters
  },
  data: () => ({
    loading: false,
    error: false,
    tab: null,
    activeFilter: 'all'
  }),
  computed: {
    products () {
      return this.$store.getters['inventory/findWarehouseInventory'](this.id)
    },
    filters () {
      const allCount = this.products.length
      const inStock = this.products.reduce((acc, product) => {
        if (product.stock > 0) {
          acc++
        }

        return acc
      }, 0)

      const outOfStock = this.products.reduce((acc, product) => {
        if (product.stock <= 0) {
          acc++
        }

        return acc
      }, 0)

      return [
        {
          label: 'All Products',
          value: 'all',
          quantity: allCount
        },
        {
          label: 'In Stock Products',
          value: 'in-stock',
          quantity: inStock
        },
        {
          label: 'Out Of Stock Products',
          value: 'out-of-stock',
          quantity: outOfStock
        }
      ]
    }
  },
  created () {
    this.loadInventory()
  },
  methods: {
    updateFilter (tabItem) {
      this.activeFilter = tabItem.value
    },
    loadInventory () {
      if (!this.id) return

      this.loading = true
      this.error = false

      return this.$store.dispatch('inventory/loadWarehouse', this.id)
        .catch((responseError) => {
          this.error = true
          this.loading = false
          console.error(responseError.statusText)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    id: {
      handler: 'loadInventory'
    }
  }
}
</script>
