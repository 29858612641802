<template>
  <v-container>
    <v-row>
      <v-col>
        <section class="d-flex flex-row align-items-center">
          <v-btn
            color="primary"
            outlined
            class="px-0 mr-4"
            to="/"
            min-width="40px"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <Titlebar
            title="Inventory Management"
            titleClasses="text-h3 headings--text mr-3"
            class="mb-9 grow"
          >
            <template v-slot:actions>
              <section class="d-flex justify-end align-center">
                <v-dialog
                  v-model="dialogs.stockTransfer"
                  width="1000"
                  light
                  eager
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="mr-5"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!multiWarehouseEnabled"
                    >
                      Create Stock Transfer
                    </v-btn>
                  </template>

                  <section class="pa-5 white">
                    <stock-transfer-form
                      ref="warehouseinventorystocktransferform"
                      :prepopulate-transfer="{ origin_warehouse_id: uuid }"
                      @transferCreated="closeStockTransfer"
                      @cancel="closeStockTransfer"
                    />
                  </section>
                </v-dialog>
                <v-btn
                  color="primary"
                  class="mr-3"
                  :to="`/inventory-location/${uuid}/count-stock`"
                >
                  Create Stock Count
                </v-btn>
              </section>
            </template>
          </Titlebar>
        </section>

        <p class="subtitle-2 mb-4 headings--text">Inventory For:</p>

        <v-sheet
          class="d-sm-flex flex-row align-center pa-4 mb-8"
          outlined
          rounded
        >
          <address-resource
            :title="name"
            :address="addressString"
            :icon="icon"
            title-classes="subtitle-1"
          />

          <v-dialog
            v-model="dialogs.warehouseSelect"
            width="600"
            rounded
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                outlined
                color="primary"
              >
                Change
              </v-btn>
            </template>

            <warehouse-select-dialog
              :initial-value="uuid"
              @save="changeWarehouse"
              @cancel="closeSelectDialog"
            />
          </v-dialog>
        </v-sheet>

        <warehouse-inventory-finder :id="uuid" items-per-page="10" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import warehouseMixin from '@/mixins/warehouse'
import AddressResource from '@/components/generic/AddressResource'
import WarehouseInventoryFinder from '@/components/warehouse-inventory-finder/WarehouseInventoryFinder'
import WarehouseSelectDialog from '@aspedia/vuetify-components/WarehouseSelectDialog'
import Titlebar from '@/components/generic/Titlebar'
import StockTransferForm from '@/components/stock-transfer-form/StockTransferForm'

export default {
  name: 'warehouse-inventory',
  mixins: [warehouseMixin],
  props: {
    uuid: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    AddressResource,
    WarehouseInventoryFinder,
    WarehouseSelectDialog,
    Titlebar,
    StockTransferForm
  },
  data: () => ({
    dialogs: {
      warehouseSelect: false,
      stockTransfer: false
    },
    loading: false,
    error: false
  }),
  computed: {
    ...mapGetters({
      find: 'warehouses/find',
      multiWarehouseEnabled: 'warehouses/multiWarehouseEnabled'
    }),
    item () {
      return this.find(this.uuid)
    }
  },
  methods: {
    changeWarehouse (uuid) {
      this.$router.push(`/inventory-location/${uuid}/inventory`)
      this.closeSelectDialog()
    },
    closeSelectDialog () {
      if (this.dialogs.warehouseSelect) {
        this.dialogs.warehouseSelect = false
      }
    },
    closeStockTransfer () {
      this.dialogs.stockTransfer = false
    }
  },
  watch: {
    'dialogs.stockTransfer': {
      handler: function (active) {
        if (active) this.$refs.warehouseinventorystocktransferform.prepopulateTransferForm()
        else this.$refs.warehouseinventorystocktransferform.reset()
      }
    }
  }
}
</script>
