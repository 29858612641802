<template>
  <summarised-stock-count-form-panel
    :products="uncountedProducts"
    header-classes="primary--text"
    no-products-label="There are no Uncounted items in this stock count."
  >
    <template v-slot:expansion-panel-header>
      Uncounted ({{ uncountedProducts.length }})
    </template>
  </summarised-stock-count-form-panel>
</template>

<script>
import SummarisedStockCountFormPanel from './SummarisedStockCountFormPanel'
export default {
  name: 'UncountedStockCountPanel',
  props: {
    uncountedProducts: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  components: {
    SummarisedStockCountFormPanel
  },
  computed: {
    potentialMissingInventoryTotal () {
      return this.uncountedProducts.reduce((acc, product) => (acc += product.productsettings.unitPrice), 0.00)
    }
  }
}
</script>
