<template>
  <v-container>
    <v-row>
      <v-col>
        <section class="d-flex flex-row align-items-center">
          <v-btn
            color="primary"
            outlined
            class="px-0 mr-4"
            to="/"
            min-width="40px"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <Titlebar
            title="Stock Transfer Overview"
            titleClasses="text-h3 headings--text mr-3 d-inline-flex"
            class="mb-9 grow"
          >
            <template v-slot:actions>
              <div class="float-end">
                <v-btn
                  color="primary"
                  class="mr-7"
                  outlined
                  :disabled="loading.download"
                  @click="downloadAllStockTransfers"
                >
                  Download All Stock Transfers
                </v-btn>
                <v-dialog
                  v-model="dialogs.stockTransfer"
                  width="1000"
                  light
                  eager
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="mr-3"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="warehouseHasNoProducts"
                    >
                      Create Stock Transfer
                    </v-btn>
                  </template>

                  <stock-transfer-form
                    ref="stocktransferform"
                    :prepopulate-transfer="{ origin_warehouse_id: id }"
                    :visible="dialogs.stockTransfer"
                    @transferCreated="dialogs.stockTransfer = false"
                    @cancel="dialogs.stockTransfer = false"
                  />
                </v-dialog>
              </div>
            </template>
          </Titlebar>
        </section>

        <v-sheet
          class="d-sm-flex flex-row align-center pa-4 mb-8"
          outlined
          rounded
        >
          <address-resource
            :title="selectedWarehouseName"
            :address="selectedWarehouseAddress"
            :icon="selectedWarehouseIcon"
            title-classes="subtitle-1"
          />

          <v-btn
            v-if="item"
            outlined
            color="primary"
            class="mr-3"
            @click="clearWarehouse"
          >
            Clear
          </v-btn>

          <v-dialog
            v-model="dialog"
            width="600"
            rounded
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                outlined
                color="primary"
                :disabled="loading.StockTransfers"
              >
                {{ item ? 'Change' : 'Select' }}
              </v-btn>
            </template>

            <warehouse-select-dialog
              :initial-value="uuid"
              title:
              @save="changeWarehouse"
              @cancel="closeDialog"
            />
          </v-dialog>
        </v-sheet>

        <warehouse-transfers-list-view
          :id="uuid"
          @loadingChanged="handleListViewLoading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import warehouseMixin from '@/mixins/warehouse'
import AddressResource from '@/components/generic/AddressResource'
import WarehouseTransfersListView from '@/components/warehouse-transfers-list-view/WarehouseTransfersListView'
import WarehouseSelectDialog from '@aspedia/vuetify-components/WarehouseSelectDialog'
import Titlebar from '@/components/generic/Titlebar'
import StockTransferForm from '@/components/stock-transfer-form/StockTransferForm'
import Messages from '@/lib/messages.json'

export default {
  name: 'WarehouseTransfersOverview',
  mixins: [warehouseMixin],
  props: {
    uuid: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    AddressResource,
    WarehouseSelectDialog,
    WarehouseTransfersListView,
    Titlebar,
    StockTransferForm
  },
  data: () => ({
    dialog: false,
    dialogs: {
      stockTransfer: false
    },
    loading: {
      stockTransfers: false,
      download: false
    },
    error: false
  }),
  computed: {
    ...mapGetters({
      find: 'warehouses/find'
    }),
    item () {
      return this.find(this.uuid)
    },
    selectedWarehouseIcon () {
      return this.item ? this.icon : ''
    },
    selectedWarehouseName () {
      return this.item ? this.name : 'Select Inventory Location'
    },
    selectedWarehouseAddress () {
      return this.item
        ? this.addressString
        : 'This will filter the list below and enable the Stock Transfer creation.'
    }
  },
  methods: {
    clearWarehouse () {
      this.changeWarehouse('')
    },
    handleListViewLoading (payload) {
      this.loading.stockTransfers = payload
    },
    changeWarehouse (uuid) {
      this.$router.push(`/transfers-overview/${uuid}`)
      this.closeDialog()
    },
    closeDialog () {
      if (this.dialog) {
        this.dialog = false
      }
    },
    downloadAllStockTransfers () {
      this.loading.download = true
      return this.$store
        .dispatch('inventory/downloadAllStockTransfers')
        .catch((error) => {
          this.error = Messages.downloadAllStockTransfers.error
          console.error(error.message ?? error.statusText ?? error)
        })
        .finally(() => (this.loading.download = false))
    }
  },
  watch: {
    uuid: {
      handler: 'loadWarehouseInventory',
      immediate: true
    },
    dialogs: {
      handler: function (val) {
        if (Object.values(val).some((active) => active)) {
          this.menu = false
        }
      },
      deep: true,
      immediate: true
    },
    'dialogs.stockTransfer': {
      handler: function (active) {
        if (active) {
          this.$refs.stocktransferform.prepopulateTransferForm()
        } else {
          this.$refs.stocktransferform.reset()
        }
      }
    }
  }
}
</script>
