<template>
  <v-main>
    <v-slide-x-transition mode="out-in">
      <router-view />
    </v-slide-x-transition>
    <notifications />
  </v-main>
</template>

<script>
import Notifications from '@/components/notifications/Notifications'

export default {
  name: 'MainApplication',
  components: {
    Notifications
  }
}
</script>
