<template>
  <section class="stock-transfer-inventory mb-5">
    <h5 class="text-h5 headings--text mb-5">Inventory</h5>
    <v-fade-transition mode="out-in">
      <section
        v-if="originWarehouseProducts.length > 0 || readonly"
        key="existing"
      >
        <v-autocomplete
          v-if="!readonly"
          v-model="inventoryItems"
          :items="originWarehouseProducts"
          :loading="loading"
          :disabled="loading || notReady"
          :search-input.sync="search"
          :filter="productSearchFilter"
          placeholder="Search Products"
          label="Search Products"
          class="body-2"
          item-text="title"
          item-value="title"
          prepend-inner-icon="mdi-magnify"
          dense
          solo
          flat
          hide-no-data
          hide-selected
          multiple
          outlined
          return-object
        >
          <template v-slot:selection></template>
          <template v-slot:item="data">
            <autocomplete-product-item :item="data.item" />
          </template>
        </v-autocomplete>
        <v-data-table
          :headers="headers"
          :items="inventoryItems"
          :loading="loading"
          fixed-header
          disable-pagination
          hide-default-footer
          dense
        >
          <template v-slot:item.main="{ item }">
            <resource-item
              :key="item.id"
              :sku="item.productsettings ? item.productsettings.sku : '-'"
              :title="item.title"
              :image="generateProductImagePath(item)"
              :stock="itemStock(item.id)"
              :three-line="false"
              :display-stock="!readonly"
            >
              <template v-slot:post-content>
                <section v-if="!readonly">
                  <v-list-item-subtitle
                    v-if="!selectedWarehouseInventory.find(product => Number(product.product_id) === Number(item.id))"
                    class="mt-2"
                  >
                    <span class="red--text">This product is not available at your source location.</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else-if="Number(item.transferQuantity) > Number(itemStock)"
                    class="mt-2"
                  >
                    <span class="red--text">Quantity exceeds current stock, the origin point only has <span class="font-weight-bold">{{ Number(itemStock) }}</span> items available.</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else-if="Number(item.transferQuantity) <= 0 || isNaN(item.transferQuantity)"
                    class="mt-2"
                  >
                    <span class="red--text">A minimum of <span class="font-weight-bold">1</span> unit must be transferred between locations.</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-else
                    class="mt-2"
                  >
                    <span class="orange--text"><span class="font-weight-bold">{{ Number(item.transferQuantity) }}</span> units will be removed from the origin transfer point.</span>
                  </v-list-item-subtitle>
                </section>
              </template>
            </resource-item>
          </template>
          <template v-slot:item.actions="slotProps">
            <section class="d-flex flex-row align-start justify-end py-3">
              <v-text-field
                v-if="!readonly && selectedWarehouseInventory.find(product => Number(product.product_id) === Number(slotProps.item.id))"
                v-model.number="slotProps.item.transferQuantity"
                type="number"
                :disabled="readonly || slotProps.item.stock <= 0"
                :max="slotProps.item.max"
                :rules="[
                  function(val) {
                    if (val > slotProps.item.max) return false
                    if (val <= 0) return false

                    return true
                  }
                ]"
                min="0"
                outlined
                dense
              />
              <span v-else-if="readonly">
                {{ slotProps.item.transferQuantity }}
              </span>
              <v-btn
                v-if="!readonly"
                icon
                small
                plain
                class="ml-3"
                :disabled="readonly"
                @click="$emit('removeProductByID', slotProps.item.id)"
              >
                <v-icon color="primary">$vcBinIcon</v-icon>
              </v-btn>
            </section>
          </template>
        </v-data-table>
      </section>
      <section
        v-else
        key="missing"
      >
        <h4 class="text-h4 headings--text text-center text--disabled">
          No products were found to select from.
        </h4>
      </section>
    </v-fade-transition>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ResourceItem from '@/components/generic/ResourceItem'
import productMethods from '@/mixins/productMethods'
import AutocompleteProductItem from '@/components/generic/AutocompleteProductItem'

export default {
  name: 'StockTransferFormInventory',
  props: {
    value: {
      type: Array,
      required: true,
      default: () => ([])
    },
    originWarehouseID: {
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    visible: {
      type: Boolean,
      required: false
    }
  },
  mixins: [productMethods],
  components: {
    ResourceItem,
    AutocompleteProductItem
  },
  data: () => ({
    headers: [
      {
        text: 'Product',
        align: 'start',
        sortable: false,
        value: 'main',
        width: '80%'
      },
      {
        text: 'Transfer Quantity',
        align: 'right',
        sortable: false,
        value: 'actions',
        width: '20%'
      }
    ],
    items: [],
    search: '',
    loading: false,
    defaultInventoryItem: {
      quantity: 0
    }
  }),
  watch: {
    visible (visible) {
      if (visible && !this.readonly) {
        this.loading = true
        return this.$store.dispatch('inventory/loadWarehouse', this.originWarehouseID)
          .then(_ => { this.loading = false })
      }
    }
  },
  computed: {
    ...mapGetters({
      filestores: 'filestores/all',
      findWarehouseInventory: 'inventory/findWarehouseInventory',
      allProducts: 'ecommerce/products',
      productData: 'ecommerce/singleProduct'
    }),
    notReady () {
      return this.originWarehouseID === ''
    },
    selectedWarehouseInventory () {
      return this.findWarehouseInventory(this.originWarehouseID)
    },
    originWarehouseProducts () {
      const products = []
      this.selectedWarehouseInventory.forEach((inv) => {
        products.push(this.productData(inv.product_id))
      })
      return products
    },
    inventoryItems: {
      get () {
        return this.value
      },
      set (val) {
        return this.$emit('input', val)
      }
    }
  },
  methods: {
    itemStock (id) {
      return this.selectedWarehouseInventory.find(product => Number(product.product_id) === Number(id))?.stock ?? ''
    }
  }
}
</script>
