<template>
  <address-card
    :title="name"
    :icon="icon"
    :address="addressString"
  >
    <template v-slot:append-address>
      <v-menu
        v-model="menu"
        offset-x
        left
        close-on-content-click
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-on="on"
            v-bind="attrs"
          >
            <v-icon color="primary">$vcEllipsisIcon</v-icon>
          </v-btn>
        </template>

        <v-list
          width="236"
          class="pa-0"
        >
          <v-dialog
            v-model="dialogs.stockTransfer"
            width="1000"
            light
            eager
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :disabled="!multiWarehouseEnabled"
              >
                <v-list-item-content>
                  <v-list-item-title>Create Stock Transfer</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <stock-transfer-form
              ref="stocktransferform"
              :prepopulate-transfer="{ origin_warehouse_id: id }"
              :visible="dialogs.stockTransfer"
              @transferCreated="dialogs.stockTransfer = false"
              @cancel="dialogs.stockTransfer = false"
            />
          </v-dialog>
          <v-list-item :to="`/inventory-location/${id}/count-stock`">
            <v-list-item-content>
              <v-list-item-title>
                Create Stock Count
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-dialog
            v-model="dialogs.editLocation"
            width="1000"
            light
            eager
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                ripple
                v-on="on"
                v-bind="attrs"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit Location
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <section class="pa-5 white">
              <inventory-location-form
                ref="inventorylocationform"
                :location="item"
                update
                @cancel="dialogs.editLocation = false"
                @updated="dialogs.editLocation = false"
              />
            </section>
          </v-dialog>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:pre-actions>
      <section class="mt-4" v-if="salesChannels.length > 0">
        <p class="headings--text subtitle-2">Sales Channels:</p>
        <v-chip
          v-for="channel in salesChannels"
          :key="channel"
          outlined
          label
          class="mr-3 headings--text body-2"
        >
          {{ channel }}
        </v-chip>
      </section>
    </template>

    <template v-slot:address-card-actions>
      <section class="d-flex justify-end align-end mt-auto">
        <v-btn
          color="primary"
          :to="`/inventory-location/${id}/inventory`"
          class="mt-6"
        >
          View Inventory
        </v-btn>
      </section>
    </template>
  </address-card>
</template>

<script>
import { mapGetters } from 'vuex'
import warehouseMixin from '@/mixins/warehouse'
import AddressCard from '@/components/generic/AddressCard'
import InventoryLocationForm from '@/components/inventory-location-form/InventoryLocationForm'
import StockTransferForm from '@/components/stock-transfer-form/StockTransferForm'

export default {
  name: 'warehouse-list-item',
  mixins: [warehouseMixin],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data: () => ({
    menu: false,
    dialogs: {
      editLocation: false,
      stockTransfer: false
    }
  }),
  components: {
    AddressCard,
    InventoryLocationForm,
    StockTransferForm
  },
  computed: {
    ...mapGetters({
      multiWarehouseEnabled: 'warehouses/multiWarehouseEnabled'
    })
  },
  watch: {
    dialogs: {
      handler: function (val) {
        if (Object.values(val).some(active => active)) {
          this.menu = false
        }
      },
      deep: true,
      immediate: true
    },
    'dialogs.editLocation': {
      handler: function (active) {
        if (active) {
          this.$refs.inventorylocationform.preFillLocationData()
        } else {
          this.$refs.inventorylocationform.reset()
        }
      }
    },
    'dialogs.stockTransfer': {
      handler: function (active) {
        if (active) {
          this.$refs.stocktransferform.prepopulateTransferForm()
        } else {
          this.$refs.stocktransferform.reset()
        }
      }
    }
  }
}
</script>
