<template>
  <v-dialog
    transition="fade-transition"
    fullscreen
    persistent
    :value="true"
  >
    <v-container
      fill-height
    >
      <v-row class="justify-center align-center">
        <section class="text-center">
          <v-progress-circular
            indeterminate
            :size="50"
            :width="3"
            color="primary"
            class="mb-10"
          />
          <h3 class="text-h3 text--disabled">Loading Application</h3>
        </section>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'loading-application'
}
</script>
