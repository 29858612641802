<template>
  <div>
    <div id="authentication-init">
      <div class="loading-content">
        <div class="loading-spinner"></div>
        <h1>Please wait</h1>
        <p>Initializing Authentication .</p>
        <p>Please wait....</p>
      </div>
    </div>

    <div id="login" style="display: none;"></div>
    <div id="loader" style="display: none;">
      <div class="loading-spinner"></div>
      <h1>Please wait</h1>
      <p>The Inventory Manager is currently initialising.</p>
    </div>

    <div id="inventory" style="display: none;"></div>
  </div>
</template>

<script>
import { authenticator } from '@aspedia/vue-generic-login'

export default {
  name: 'Login',
  mounted () {
    const store = this.$store

    return this.loadRegistry(function (registry) {
      const authConfig = {
        storeJWT: true,
        logo: {
          title: registry.site.settings.title,
          logoUrl: 'logo.svg'
        },
        username: {
          label: 'Username/Email Address',
          tooltip: 'Please enter your Username or Email address for your registed account',
          placeholder: 'user@email.com'
        },
        password: {
          label: 'Password',
          tooltip: 'Enter your password to continue'
        },
        loginbutton: {
          label: 'Login',
          tooltip: 'Login'
        },
        timeout: 5000,
        endpointURL: registry.services.datastore.endpoint,
        forgotPassword: false,
        loginErrorMessage: function (username) {
          return 'Unable to login with username: ' + username
        }
      }

      const loginMethods = function () {
        return ['loginAuthToken', 'localStorage', 'loginForm']
      }

      authenticator('#login', authConfig)
        .on('start', (method) => {
          if (method === 'loginForm') {
            document.getElementById('authentication-init').style.display = 'none'
            document.getElementById('login').style.display = 'block'
          }
        })
        .on('loginSuccess', ([method, auth]) => {
          startApp(method, auth, registry)
        })
        .login(loginMethods())

      const startApp = function (method, auth, registry) {
        store.dispatch('init', {
          auth,
          registry
        })
      }
    })
  },
  methods: {
    loadRegistry (callback) {
      return fetch('/registry.json')
        .then(async (response) => {
          if (response.ok) {
            const registry = await response.json()
            callback(registry)
          }
        })
        .catch(err => {
          console.error(err.message)
        })
    }
  }
}
</script>
<style>
#login {
  text-align: center;
  color: #666;
}

#loader {
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 20% 0;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

}

.loading-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 300px;
  text-align: center;

}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#authentication-init {
  top: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #666;
  height: 100vh;
}
</style>
