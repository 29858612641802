<template>
  <section>
    <v-data-table
      :headers="headers"
      :items="productsModel"
      :search="search"
      :loading="loading"
      :items-per-page="itemsPerPage"
      :item-class="itemClasses"
      calculate-widths: true
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search for Products by Name or SKU"
          prepend-inner-icon="mdi-magnify"
          class="mx-0 body-2"
          flat
          clearable
          simple
          solo
          plain
          outlined
          dense
        ></v-text-field>
      </template>
      <template v-slot:no-data>
        <section class="text-center py-10">
          <v-icon
            color="primary"
            class="mb-4"
            large
          >
            mdi-alert-circle-outline
          </v-icon>
          <h4 class="headings--text text-h4 mb-1">
            <slot name="title">
              No Products Available.
            </slot>
          </h4>
          <v-btn
            color="primary"
            to="/products/assign/"
          >
            Add products to this location
          </v-btn>
        </section>
      </template>
      <template v-slot:item.title="{ item }">
        <warehouse-inventory-finder-title-cell :item="item" />
      </template>
      <template v-slot:item.productsettings.sku="{ item }">
        <span v-if="item.productsettings">
          {{
            [null, '', undefined].includes(item.productsettings.sku)
              ? '-'
              : item.productsettings.sku
          }}
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.stock="slotProps">
        <span :class="loadStockClass(slotProps.item.stock)">
          <stock-cell
                @reloadTable="reloadTable"
                :item.sync="slotProps.item"
              />
        </span>
      </template>
      <template v-slot:item.unit_price="{ item }">
        <span v-if="item.productsettings && item.productsettings.prices" class="subtitle-2">
           ${{ Number(item.productsettings.prices.unit_price).toFixed(2) }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu
          offset-x
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
            >
              <v-icon color="primary">$vcEllipsisIcon</v-icon>
            </v-btn>
          </template>

          <v-list
            width="236"
            class="py-0"
          >
            <v-list-item @click="stockAdjustment(item)">
              <v-list-item-content>
                <v-list-item-title>Stock Count</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-dialog
              v-model="dialogs.stockTransfer"
              eager
              width="1000"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-list-item-content>
                    <v-list-item-title>Stock Transfer</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <stock-transfer-form
                ref="tablerowstocktransferform"
                :prepopulate-transfer="{
                  origin_warehouse_id: warehouseID,
                  products: [item]
                }"
                @transferCreated="handleTransferCreated"
                @cancel="dialogs.stockTransfer = false"
              />
            </v-dialog>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import WarehouseInventoryFinderTitleCell from '@/components/warehouse-inventory-finder/WarehouseInventoryFinderTitleCell'
import StockTransferForm from '@/components/stock-transfer-form/StockTransferForm'
import StockCell from '@/components/product-warehouse-assignment/StockCell'

export default {
  name: 'inventory-finder-table',
  props: {
    activeFilter: {
      type: String,
      required: true,
      default: () => ('all'),
      validator: (value) => (['all', 'in-stock', 'out-of-stock'].includes(value))
    },
    products: {
      type: Array,
      required: true,
      default: () => ([])
    },
    error: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    warehouseID: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    StockTransferForm,
    StockCell,
    WarehouseInventoryFinderTitleCell
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    sortBy: 'title',
    sortDesc: false,
    search: '',
    headers: [
      {
        text: 'SKU',
        align: 'start',
        sortable: true,
        value: 'productsettings.sku',
        class: 'headings--text',
        width: '20%'
      },
      {
        text: 'Product',
        align: 'start',
        sortable: true,
        value: 'title',
        class: 'headings--text'
      },
      {
        text: 'Price',
        align: 'start',
        sortable: false,
        value: 'unit_price',
        class: 'headings--text',
        width: '10%'
      },
      {
        text: 'Available Stock',
        align: 'start',
        sortable: true,
        value: 'stock',
        filterable: false,
        class: 'headings--text',
        width: '15%'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions',
        width: '100px',
        class: 'headings--text'
      }
    ],
    dialogs: {
      stockTransfer: false
    },
    productActionOptions: [
      {
        text: 'Stock Count',
        handler: 'stockAdjustment'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      productData: 'ecommerce/singleProduct'
    }),
    combinedProducts () {
      return this.products.map((product) => {
        return { ...product, ...this.productData(product.product_id) }
      })
    },
    productsModel () {
      // Filter out unpublished, unstocked products.
      // const baseProducts = this.combinedProducts.filter(product => (product.status ? true : product.stock > 0))
      const baseProducts = this.combinedProducts

      if (this.activeFilter === 'in-stock') return baseProducts.filter(product => Number(product.stock) > 0)
      if (this.activeFilter === 'out-of-stock') return baseProducts.filter(product => Number(product.stock) <= 0)

      return baseProducts
    }
  },
  methods: {
    viewProduct () {
      return true
    },
    itemClasses (item) {
      return 'headings--text body-2'
    },
    loadStockClass (quantity) {
      if (Number(quantity) <= 0) {
        return 'red--text'
      }

      return 'green--text'
    },
    handleFilterAction (action, item) {
      return this[action.handler](item)
    },
    stockAdjustment (product) {
      const route = this.$route
      const initialProducts = [product.id]

      return this.$router.push({
        path: `/inventory-location/${route.params.uuid}/count-stock`,
        query: {
          initialProducts: JSON.stringify(initialProducts)
        }
      })
    },
    handleTransferCreated () {
      this.dialogs.stockTransfer = false
      this.$emit('reloadInventory')
    },
    reloadTable () {
      this.$emit('reloadInventory')
    }
  },
  watch: {
    'dialogs.stockTransfer': {
      handler: function (active) {
        if (active) {
          this.$refs.tablerowstocktransferform.prepopulateTransferForm()
        } else {
          this.$refs.tablerowstocktransferform.reset()
        }
      }
    }
  }
}
</script>
