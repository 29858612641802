<template>
  <v-menu
    v-model="menuActive"
    offset-x
    left
    close-on-content-click
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
      >
        <v-icon color="primary">$vcEllipsisIcon</v-icon>
      </v-btn>
    </template>

    <v-list
      width="236"
      class="py-0"
    >
      <v-dialog
        v-model="dialogs.summaryDialog"
        width="1000"
        light
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            ripple
            v-on="on"
            v-bind="attrs"
          >
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">
                View Summary
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <section class="pa-5 white">
          <summarised-stock-count-form
            readonly
            :form="{ ...item, products: loadTransferProducts(item.products) }"
            :warehouse="loadWarehouse(item.warehouse_id)"
            @close="dialogs.summaryDialog = false"
          />
        </section>
      </v-dialog>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import summarisedStockCountForm from '@/components/stock-count-forms/SummarisedStockCountForm'

export default {
  name: 'StockCountActionsCell',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    menuActive: false,
    dialogs: {
      summaryDialog: false
    }
  }),
  components: {
    summarisedStockCountForm
  },
  computed: {
    ...mapGetters({
      products: 'ecommerce/products',
      productData: 'ecommerce/singleProduct',
      findWarehouse: 'warehouses/find'
    })
  },
  methods: {
    loadTransferProducts (transferProducts) {
      try {
        return transferProducts.map(transferProduct => ({
          ...this.productData(transferProduct.id),
          ...transferProduct
        }))
      } catch {
        return []
      }
    },
    loadWarehouse (warehouseID) {
      return this.findWarehouse(warehouseID)
    }
  },
  watch: {
    dialogs: {
      handler: function (val) {
        if (Object.values(val).some((active) => active)) this.menuActive = false
      },
      deep: true
    },
    page: {
      handler: function (newval) {
        this.$emit('updatePage', newval)
      }
    }
  }
}
</script>
