<template>
  <section class='stock-transfer white rounded pa-7'>
    <titlebar
      title="Stock Transfer"
      class="mb-7"
    >
      <template v-slot:title="slotProps">
        <h4
          class='text-h4 headings--text'
          :class="!readonly ? 'mb-5' : ''"
        >{{ slotProps.title }}</h4>
      </template>
      <template v-slot:subtitle>
        <span v-if="!readonly">Transfer stock from one Inventory Location to another.</span>
      </template>
    </titlebar>

    <v-sheet
      v-if="readonly"
      class="pa-5 mb-8"
      outlined
      rounded
    >
      <v-row>
        <v-col
          v-if="form.transfer_id"
          cols="12"
          md="4"
        >
          <p class="muted--text subtitle-2">Transfer ID</p>
          <p class="mb-0 headings--text subtitle-1">{{ [null, undefined, ''].includes(form.reference) ? '-' : form.reference }}</p>
        </v-col>
        <v-col
          v-if="form.created"
          cols="12"
          md="4"
        >
          <p class="muted--text subtitle-2">Created Date</p>
          <p class="mb-0 headings--text subtitle-1">{{ formattedCreatedDate }}</p>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <p class="muted--text subtitle-2">Created By</p>
          <p class="mb-0 headings--text subtitle-1">
            {{ createdBy }}</p>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <p class="muted--text subtitle-2">Transfer Reason</p>
          <p class="mb-0 headings--text subtitle-1">
            {{ form.transfer_reason ? form.transfer_reason : '-' }}</p>
        </v-col>
      </v-row>
    </v-sheet>

    <!-- Locations -->
    <titlebar
      title="Locations"
      class="mb-5"
    >
      <template v-slot:actions>
        <v-btn
          v-if="!readonly"
          color="primary"
          class="py-2"
          outlined
          :ripple="false"
          @click="switchDestinations"
        >
          Switch

          <v-icon
            right
            small
          >$vcUpAndDownArrows</v-icon>
        </v-btn>
      </template>
    </titlebar>

    <p class="subtitle-1 mb-1">Transfer Source:</p>

    <v-sheet
      class="d-sm-flex flex-row align-center pa-4 mb-8"
      outlined
      rounded
    >
      <address-resource
        :title="originWarehouseTitle"
        :address="originWarehouseAddress"
        :icon="originWarehouseIcon"
        title-classes="subtitle-1"
      />

      <v-btn
        v-if="form.origin_warehouse_id"
        outlined
        color="primary"
        class="mr-3"
        @click="clearWarehouse('origin_warehouse_id')"
      >
        Clear
      </v-btn>

      <v-dialog
        v-if="!readonly"
        v-model="dialogs.changeOrigin"
        width="600"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            outlined
            color="primary"
            :disabled="loading"
          >
            {{ form.origin_warehouse_id ? 'Change' : 'Select' }}
          </v-btn>
        </template>

        <warehouse-select-dialog
          title="Select Transfer Origin"
          :initial-value="form.origin_warehouse_id"
          :disallowed="[form.destination_warehouse_id]"
          @save="changeOriginWarehouse"
          @cancel="closeDialog('changeOrigin')"
        />
      </v-dialog>
    </v-sheet>

    <p class="subtitle-1 mb-1">Transfer Destination:</p>

    <v-sheet
      class="d-sm-flex flex-row align-center pa-4 mb-8"
      outlined
      rounded
    >
      <address-resource
        :icon="destinationWarehouseIcon"
        :title="destinationWarehouseTitle"
        :address="destinationWarehouseAddress"
        title-classes="subtitle-1"
      />

      <v-btn
        v-if="form.destination_warehouse_id"
        outlined
        color="primary"
        class="mr-3"
        @click="clearWarehouse('destination_warehouse_id')"
      >
        Clear
      </v-btn>

      <v-dialog
        v-if="!readonly"
        v-model="dialogs.changeDestination"
        width="600"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            outlined
            color="primary"
            :disabled="loading"
          >
            {{ form.destination_warehouse_id ? 'Change' : 'Select' }}
          </v-btn>
        </template>

        <warehouse-select-dialog
          title="Select Transfer Destination"
          :initial-value="form.destination_warehouse_id"
          :disallowed="[form.origin_warehouse_id]"
          @save="changeDestinationWarehouse"
          @cancel="closeDialog('changeDestination')"
        />
      </v-dialog>
    </v-sheet>

    <stock-transfer-form-inventory
      class="mb-7"
      v-model="form.products"
      :originWarehouseID="form.origin_warehouse_id"
      :readonly="readonly"
      :visible="visible"
      @removeProductByID="removeProductByID"
    />

    <stock-transfer-form-details
      v-if="!readonly"
      v-model="form"
      :readonly="readonly"
    />

    <section class="text-right mt-4">
      <p
        v-if="error"
        class="red--text"
      >
        {{ messages.inventoryStockTransfer.error }}
      </p>
      <v-btn
        large
        color="primary"
        :outlined="!readonly"
        @click="cancelTransfer"
      >
        {{
          readonly ? 'Close' : 'Cancel Transfer'
        }}
      </v-btn>
      <v-btn
        v-if="!readonly"
        class='ml-2'
        color='primary'
        large
        :disabled="submitDisabled"
        :loading="loading"
        @click="createTransfer"
      >
        Create Transfer
        <template v-slot:loader>
          <custom-loader />
        </template>
      </v-btn>
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import StockTransferFormInventory from './StockTransferFormInventory'
import StockTransferFormDetails from './StockTransferFormDetails'
import Titlebar from '@/components/generic/Titlebar'
import AddressResource from '@/components/generic/AddressResource'
import WarehouseSelectDialog from '@/components/WarehouseSelectDialog'
import warehouseIconMap from '@/lib/warehouseIconMap'
import buildAddressString from '@/lib/buildAddressString'
import customLoader from '@/components/generic/CustomLoader'
import { stockTransfer } from '@/structures'
import merge from 'lodash.merge'
import dayjs from 'dayjs'
import dayjsAdvanced from 'dayjs/plugin/advancedFormat'
import dayjsUTC from 'dayjs/plugin/utc'
import Messages from '@/lib/messages.json'

dayjs.extend(dayjsAdvanced)
dayjs.extend(dayjsUTC)

export default {
  name: 'StockTransferForm',
  props: {
    prepopulateTransfer: {
      type: Object,
      required: false,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false
    },
    visible: {
      type: Boolean,
      required: false
    }
  },
  components: {
    Titlebar,
    StockTransferFormInventory,
    StockTransferFormDetails,
    AddressResource,
    WarehouseSelectDialog,
    customLoader
  },
  data () {
    return {
      dialogs: {
        changeOrigin: false,
        changeDestination: false
      },
      error: false,
      loading: false,
      form: {
        origin_warehouse_id: '',
        destination_warehouse_id: '',
        products: [],
        transfer_status: 'complete',
        transfer_reason: '',
        transfer_other: '',
        reference: ''
      },
      messages: {}
    }
  },
  created () {
    this.messages = Messages
    this.prepopulateTransferForm()
  },
  computed: {
    ...mapGetters({
      findWarehouse: 'warehouses/find',
      warehouses: 'warehouses/all'
    }),
    formattedCreatedDate () {
      return dayjs(this.form.created).format('DD/MM/YYYY - HH:mm:ss')
    },
    submitDisabled () {
      return (
        this.readonly ||
        this.transferDataIncomplete ||
        this.loading ||
        this.transferQuantitiesInvalid
      )
    },
    transferQuantitiesInvalid () {
      return this.form.products.some((product) => {
        const invalidEntry = isNaN(product.transferQuantity)
        const minimumQuantityFailed =
          Number(product.stock) < Number(product.transferQuantity) ||
          Number(product.transferQuantity) <= 0

        return invalidEntry || minimumQuantityFailed
      })
    },
    originWarehouse () {
      return this.findWarehouse(this.form.origin_warehouse_id)
    },
    destinationWarehouse () {
      return this.findWarehouse(this.form.destination_warehouse_id)
    },
    destinationWarehouseIcon () {
      return this.destinationWarehouse
        ? this.getWarehouseIcon(this.destinationWarehouse.type)
        : ''
    },
    destinationWarehouseTitle () {
      return this.destinationWarehouse
        ? this.destinationWarehouse.name
        : 'Please Select'
    },
    destinationWarehouseAddress () {
      return this.destinationWarehouse
        ? this.getAddressString(this.destinationWarehouse.address)
        : 'Select a destination warehouse via the change option.'
    },
    originWarehouseIcon () {
      return this.originWarehouse
        ? this.getWarehouseIcon(this.originWarehouse.type)
        : ''
    },
    originWarehouseTitle () {
      return this.originWarehouse ? this.originWarehouse.name : 'Please Select'
    },
    originWarehouseAddress () {
      return this.originWarehouse
        ? this.getAddressString(this.originWarehouse.address)
        : 'Select an origin warehouse via the change option.'
    },
    transferDataIncomplete () {
      return [
        this.originWarehouse,
        this.destinationWarehouse,
        this.form.transfer_reason,
        this.form.reference,
        this.form.products.length
      ].some((val) => [null, undefined, '', 0].includes(val))
    },
    createdBy () {
      return Object.prototype.hasOwnProperty.call(this.form, 'userid') &&
        this.readonly &&
        this.form.userid !== this.$store.getters['user/userid']
        ? this.form.userid
        : this.$store.getters['user/fullname']
    }
  },
  methods: {
    clearWarehouse (type) {
      this.form[type] = ''
    },
    prepopulateTransferForm () {
      this.form = merge({}, stockTransfer, this.prepopulateTransfer)
      if (
        this.warehouses.length === 2 &&
        this.form.origin_warehouse_id !== '' &&
        this.form.destination_warehouse_id === ''
      ) {
        this.form.destination_warehouse_id = this.warehouses.find(
          (warehouse) =>
            warehouse.warehouse_id !== this.form.origin_warehouse_id
        ).warehouse_id
      }
    },
    getWarehouseIcon (warehouseType) {
      return warehouseIconMap[warehouseType] ?? warehouseIconMap.default
    },
    getAddressString (address) {
      return buildAddressString(address)
    },
    changeOriginWarehouse (uuid) {
      this.form.origin_warehouse_id = uuid
      this.closeDialog('changeOrigin')
      this.clearInventory()
    },
    changeDestinationWarehouse (uuid) {
      this.form.destination_warehouse_id = uuid
      this.closeDialog('changeDestination')
    },
    closeDialog (dialogKey) {
      this.dialogs[dialogKey] = false
    },
    switchDestinations () {
      const origin = this.form.origin_warehouse_id
      const destination = this.form.destination_warehouse_id
      this.form.origin_warehouse_id = destination
      this.form.destination_warehouse_id = origin
    },
    removeProductByID (productID) {
      const productIndex = this.form.products.findIndex(
        (product) => product.id === productID
      )

      if (productIndex === -1) return

      this.form.products.splice(productIndex, 1)
    },
    createTransfer () {
      this.loading = true
      this.error = false

      const form = this.form
      const transferDetails = {
        ...form,
        products: form.products.map((product) => ({
          id: product.id,
          transferQuantity: product.transferQuantity
        })),
        transfer_reason:
          form.reason === 'other' ? form.transfer_other : form.transfer_reason
      }

      delete transferDetails.transfer_other

      return this.$store
        .dispatch('inventory/createStockTransfer', transferDetails)
        .then(() => {
          this.reset()
          this.$emit('transferCreated')
        })
        .catch(() => (this.error = true))
        .finally(() => (this.loading = false))
    },
    reset () {
      this.form = JSON.parse(JSON.stringify(stockTransfer))
    },
    cancelTransfer () {
      this.reset()
      this.$emit('cancel')
    },
    clearInventory () {
      this.form.products = []
    }
  }
}
</script>
