export default {
  endpoints: {
    service: '/api/v1/admin/inventory',
    config: '/api/v1/admin/site'
  },
  config: {},
  warehouses: {},
  transfers: [],
  stockCounts: []
}
