import merge from 'lodash/merge'
import applogger from '@/lib/applogger'
import fetch from '@/lib/fetch'

// // Array prototype last
// const ArrayLast = function(arr) {
//   return arr[arr.length - 1]
// }

// const ReduceOneRight = function(arr) {
//   return arr.slice(0, -1)
// }

function GraphQLClient (serviceEndpoint, queryDoc) {
  this.uri = serviceEndpoint
  this.queryDoc = queryDoc

  const _request = (type, { query, variables, context, opName }) => {
    context = merge(
      context || {},
      {
        timeout: 20000,
        redirects: 5
      }
    )

    if (query === null && this.queryDoc === null) {
      throw new Error('No Query Specified - you need to supply either a Query Argument or a QueryDoc to the constructor.')
    }
    if (query === null && opName === null) {
      throw new Error('Operation Name missing - when using a queryDoc you must specify the opName for each request.')
    }
    query = query || this.queryDoc
    variables = variables || {}

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    return new Promise((resolve, reject) => {
      // This calls all connected middleware that will alter the Query Context prior to the actual request.
      const queryContext = onQueryMiddleware.reduce((cxt, fn) => {
        return fn.call(this, cxt)
      }, { headers, query, variables, opName })

      if (typeof context === 'function') {
        context.call(this, queryContext)
      } else {
        queryContext.headers = {
          ...context.headers,
          ...queryContext.headers
        }
      }

      // Headers are process to remove any headers that are empty of null
      queryContext.headers = Object.entries(queryContext.headers)
        .reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = value
          }
          return acc
        }, {})
      resolve(queryContext)
    })
      .catch(err => {
        applogger.error(err.message)
      })
      .then(queryContext => {
        const options = {
          method: 'POST',
          headers: queryContext.headers,
          redirect: 'follow', // set to `manual` to extract redirect headers, `error` to reject redirect
          credentials: 'omit', // include, *same-origin, omit

          body: JSON.stringify({
            query: queryContext.query,
            variables: queryContext.variables || {},
            operationName: queryContext.opName || null
          })
        }

        return new Promise((resolve, reject) => {
          let timeout
          let didTimeOut = false

          if (queryContext.timeout) {
            timeout = setTimeout(() => {
              didTimeOut = true
              reject(new RequestTimeoutError())
            }, queryContext.timeout)
          }
          fetch(this.uri, options)
            .then(resp => {
              // Clear the timeout as cleanup
              clearTimeout(timeout)
              if (!didTimeOut) {
                resolve(resp)
              }
            })
        })
      })
      .then(resp => {
        return resp.json()
      })
      .then(data => {
        return data
      },
      err => {
        if (err.name === 'AbortError') {
          throw new RequestTimeoutError('Aborted - timeout receiving response')
        }
      })
  }

  this.query = (args) => {
    return _request('query', {
      query: args.query,
      context: args.context,
      variables: args.variables,
      opName: args.opName
    })
  }

  this.mutate = (args) => {
    return _request('mutate', {
      query: args.mutation,
      context: args.context,
      variables: args.variables,
      opName: args.opName
    })
  }

  const onQueryMiddleware = []
  this.onQuery = function (fn) {
    onQueryMiddleware.push(fn)
    return this
  }
}

function GenericError (message) {
  this.name = 'GenericError'
  this.message = (message !== undefined) ? 'Generic Error - ' + message : 'Generic Error'
  this.code = '418'
  // Use V8's native method if available, otherwise fallback
  if ('captureStackTrace' in Error) { Error.captureStackTrace(this, GenericError) } else { this.stack = (new Error()).stack }
}
GenericError.prototype = Object.create(Error.prototype)
GenericError.prototype.constructor = GenericError

function NotFoundError (message) {
  this.name = 'NotFoundError'
  this.message = (message !== undefined) ? 'Not Found - ' + message : 'Not Found'
}
NotFoundError.prototype = Object.create(GenericError.prototype)
NotFoundError.prototype.constructor = NotFoundError

function AccessDeniedError (message) {
  this.name = 'AccessDeniedError'
  this.message = (message !== undefined) ? 'Access Denied - ' + message : 'Access Denied'
}
AccessDeniedError.prototype = Object.create(GenericError.prototype)
AccessDeniedError.prototype.constructor = AccessDeniedError

function AuthorizationRequiredError (message) {
  this.name = 'AuthorizationRequiredError'
  this.message = (message !== undefined) ? 'Authorization required - ' + message : 'Authorization required'
}
AuthorizationRequiredError.prototype = Object.create(GenericError.prototype)
AuthorizationRequiredError.prototype.constructor = AuthorizationRequiredError

function BadRequestError (message) {
  this.name = 'BadRequestError'
  this.message = (message !== undefined) ? 'Bad Request - ' + message : 'Bad Request'
}
BadRequestError.prototype = Object.create(GenericError.prototype)
BadRequestError.prototype.constructor = BadRequestError

function RequestTimeoutError (message) {
  this.name = 'RequestTimeoutError'
  this.message = (message !== undefined) ? 'Request timeout - ' + message : 'Request timeout'
}
RequestTimeoutError.prototype = Object.create(GenericError.prototype)
RequestTimeoutError.prototype.constructor = RequestTimeoutError

function SystemError (message) {
  this.name = 'SystemError'
  this.message = (message !== undefined) ? 'System Error - ' + message : 'System Error'
}
SystemError.prototype = GenericError.prototype
SystemError.prototype.constructor = SystemError

function UnknownServiceError (message) {
  this.name = 'UnknownServiceError'
  this.message = (message !== undefined) ? 'Unknown Service Error - ' + message : 'Unknown Service Error'
}
UnknownServiceError.prototype = GenericError.prototype
UnknownServiceError.prototype.constructor = UnknownServiceError

export {
  GraphQLClient,
  NotFoundError,
  AccessDeniedError,
  AuthorizationRequiredError,
  UnknownServiceError,
  BadRequestError,
  GenericError,
  RequestTimeoutError,
  SystemError
}
