import { mapGetters } from 'vuex'
import warehouseIconMap from '@/lib/warehouseIconMap'
import buildAddressString from '@/lib/buildAddressString'

export default {
  computed: {
    ...mapGetters({
      inventoryConfig: 'inventory/config',
      allowedCountries: 'ecommerce/allowedCountries'
    }),
    id () {
      try {
        return this.item.warehouse_id
      } catch {
        return ''
      }
    },
    name () {
      try {
        return this.item.name
      } catch {
        return ''
      }
    },
    icon () {
      return warehouseIconMap[this.item.type] ?? warehouseIconMap.default
    },
    address () {
      return this.item.address
    },
    addressString () {
      return buildAddressString(this.address, this.allowedCountries)
    },
    salesChannels () {
      const warehouse = this.item
      const descriptions = this.$store.getters.variableValues('transaction_type_descriptions')
      const posChannels = this.$store.getters.variableValues('pos_channels') ?? {}
      const posChannelsLabels = Object.entries(posChannels).reduce((acc, [key, val]) => ({ ...acc, [key]: val.name }), {})

      const flattenedConfig = { ...this.inventoryConfig, ...this.inventoryConfig.pos }

      const labels = { ...descriptions, ...posChannelsLabels }

      return Object.entries(flattenedConfig).reduce((acc, [key, value]) => {
        return (value.warehouse === warehouse.warehouse_id)
          ? [...acc, labels[key]]
          : acc
      }, [])
    },
    warehouseProducts () {
      try {
        return this.$store.getters['inventory/findWarehouseInventory'](this.id)
      } catch {
        return []
      }
    },
    warehouseHasNoProducts () {
      return this.warehouseProducts.length <= 0
    }
  },
  methods: {
    loadWarehouseInventory (uuid) {
      if (!uuid) return

      this.loading = true
      this.error = false

      return this.$store.dispatch('inventory/loadWarehouse', uuid)
        .catch((responseError) => {
          this.error = true
          console.error(responseError.statusText)
        })
        .finally(() => (this.loading = false))
    }
  }
}
