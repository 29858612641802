<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <section class="d-flex flex-row align-items-center">
            <v-btn
              color="primary"
              outlined
              class="px-0 mr-4"
              to="/"
              min-width="40px"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <Titlebar
              title="Product Search"
              titleClasses="text-h3 headings--text mr-3"
              class="mb-9 grow"
            />
          </section>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <input-label label="">
            <template v-slot:label>
              <h3>Select Product</h3>
            </template>
          </input-label>
          <p>Select a product using the search field view and update inventory for that product.</p>
          <v-autocomplete
            v-model="selectedProduct"
            :items="products"
            :search-input.sync="search"
            :filter="productSearchFilter"
            class="body-2"
            item-text="title"
            placeholder="Search for a Product Title"
            item-value="title"
            prepend-inner-icon="mdi-magnify"
            :disabled="loading"
            dense
            solo
            flat
            hide-no-data
            hide-selected
            outlined
            return-object
          >
            <template v-slot:selection></template>
            <template v-slot:item="data">
              <autocomplete-product-item :item="data.item" />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="Object.keys(selectedProduct).length > 0">
        <v-col cols="12">
          <p class="subtitle-2 mb-4 headings--text">Currently Selected:</p>
          <v-sheet
            outlined
            rounded
            class="px-4"
          >
            <resource-item
              v-bind="selectedProduct"
              :sku="selectedProduct.productsettings.sku"
              :image="generateProductImagePath(selectedProduct)"
              :three-line="false"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <data-table-error
            v-if="error"
            retry-allowed
            @retry="retrieveProductInventory"
          >
            <template v-slot:title>
              Something went wrong while attempting to load inventory.
            </template>
          </data-table-error>
          <data-table-error v-if="warehouses.length <= 0">
            <template v-slot:title>
              No Inventory Locations Currently Exist.
            </template>
            <template v-slot:description>
              Please create an inventory location to assign a product.
            </template>
            <template v-slot:post-description>
              <v-dialog
                v-model="dialog"
                light
                width="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Create a warehouse
                  </v-btn>
                </template>

                <section class="pa-5 white">
                  <inventory-location-form
                    @save="dialog = false"
                    @cancel="dialog = false"
                  />
                </section>
              </v-dialog>
            </template>
          </data-table-error>
          <v-data-table
            v-else
            :headers="headers"
            :items.sync="warehouseOptions"
            fixed-header
            :loading="loading"
            height="700"
            disable-pagination
            disabled
            hide-default-footer
            :show-select="false"
          >
            <template v-slot:header.assigned="{header}">
              <span class="d-inline-block">{{ header.text}}</span>
              <v-tooltip
                v-if="header.tooltip"
                max-width="300"
                bottom
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    outlined
                    x-small
                    class="ml-2 d-inline-block"
                    v-bind="attrs"
                    v-on="on"
                    tabindex="-1"
                  >
                    <span class="primary--text">
                      ?
                    </span>
                  </v-btn>
                </template>

                {{ header.tooltip }}
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <section class="mt-4">
                <p>No product selected.</p>
              </section>
            </template>
            <template v-slot:loading>
              Loading...
            </template>
            <template v-slot:item.assigned="{ item }">
              <assigned-cell
                :item="item"
                :existing-product-inventory="existingProductInventory"
              />
            </template>
            <template v-slot:item.location="{ item }">
              <section class="py-4">
                <address-resource
                  :title="item.warehouse_name"
                  :address="buildWarehouseAddress(item.address)"
                  icon="$vcWarehouseIcon"
                />
              </section>
            </template>
            <template v-slot:item.stock="{ item }">
              <stock-cell
                @reloadTable="loadTable"
                :item.sync="item"
                :existing-inventory="existingProductInventory"
              />
            </template>
            <template v-slot:item.low_stock="{ item }">
              <low-stock-cell
                :item.sync="item"
                :existing-inventory="existingProductInventory"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <!-- <section class="text-right mt-4 save-wrapper">
      <v-container>
        <v-row>
          <v-col cols="12">
            <p
              v-if="saveError"
              class="red--text"
            >
              {{ messages.productInventoryLocationAssignment.error }}
            </p>
            <v-btn
              color="primary"
              :disabled="loading || !productSelected || invalidQuantityEntered"
              :loading="loading"
              @click="save"
            >
              <template v-slot:loader>
                <custom-loader />
              </template>

              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Titlebar from '@/components/generic/Titlebar'
import AutocompleteProductItem from '@/components/generic/AutocompleteProductItem'
import InputLabel from '@/components/generic/InputLabel'
import DataTableError from '@/components/generic/DataTableError'
import AddressResource from '@/components/generic/AddressResource'
import ResourceItem from '@/components/generic/ResourceItem'
// import CustomLoader from '@/components/generic/CustomLoader'
import buildAddressString from '@/lib/buildAddressString'
import productMethods from '@/mixins/productMethods'
import StockCell from '@/components/product-warehouse-assignment/StockCell'
import LowStockCell from '@/components/product-warehouse-assignment/LowStockCell'
import AssignedCell from '@/components/product-warehouse-assignment/AssignedCell'
import InventoryLocationForm from '@/components/inventory-location-form/InventoryLocationForm'
import Messages from '@/lib/messages.json'

export default {
  name: 'ProductWarehouseAssignment',
  props: {
    id: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  components: {
    Titlebar,
    AddressResource,
    ResourceItem,
    InputLabel,
    // CustomLoader,
    DataTableError,
    AutocompleteProductItem,
    StockCell,
    LowStockCell,
    AssignedCell,
    InventoryLocationForm
  },
  mixins: [productMethods],
  data: () => ({
    lowStockNotification: 0,
    dialog: false,
    headers: [
      {
        text: 'Inventory Location',
        align: 'start',
        sortable: false,
        value: 'location',
        width: '59%'
      },
      {
        text: 'Quantity',
        align: 'start',
        sortable: false,
        value: 'stock',
        width: '15%'
      },
      {
        text: 'Low Stock',
        align: 'start',
        sortable: false,
        value: 'low_stock',
        width: '15%'
      }
    ],
    search: '',
    selectedProduct: {},
    error: false,
    saveError: false,
    existingProductInventory: [],
    loading: false,
    warehouseOptions: [],
    messages: {}
  }),
  created () {
    this.messages = Messages
  },
  computed: {
    ...mapGetters({
      filestores: 'filestores/all',
      allProducts: 'ecommerce/products',
      productData: 'ecommerce/singleProduct',
      warehouses: 'warehouses/all'
    }),
    invalidQuantityEntered () {
      return this.warehouseOptions.some((warehouse) => {
        const val = warehouse.stock
        const regex = new RegExp('^\\d+$')

        return !regex.test(val) || Number.isNaN(val) || val < 0
      })
    },
    productSelected () {
      return (
        Object.keys(this.selectedProduct).length > 0 &&
        Object.prototype.hasOwnProperty.call(this.selectedProduct, 'id')
      )
    },
    products () {
      return Object.values(this.allProducts)
    }
  },
  mounted () {
    if (this.id) {
      this.loadInitialProduct().then(() => this.loadTable())
    }
  },
  methods: {
    loadTable () {
      this.existingProductInventory = []
      return this.retrieveProductInventory().then(() =>
        this.loadWarehouseOptions()
      )
    },
    loadWarehouseOptions () {
      if (this.loading || !this.productSelected || this.error) return []

      const existingProductInventory = this.existingProductInventory

      this.warehouseOptions = this.warehouses
        .reduce((acc, warehouse) => {
          const existingInventoryData =
            existingProductInventory.find((i) => {
              const warehouseMatches =
                i.warehouse_id === warehouse.warehouse_id
              const productMatches =
                Number(i.product_id) === Number(this.selectedProduct.id)

              return warehouseMatches && productMatches
            }) ?? {}

          return [
            ...acc,
            {
              assigned: false,
              warehouse_id: warehouse.warehouse_id,
              warehouse_name: warehouse.name,
              address: warehouse.address,
              product_id: this.selectedProduct.id,
              stock: 0,
              low_stock: warehouse.low_stock ?? 0,
              ...existingInventoryData
            }
          ]
        }, [])
        .sort((a, b) => b.assigned - a.assigned)
    },
    retrieveProductInventory () {
      if (!this.selectedProduct.id) return Promise.resolve()

      this.loading = true
      this.error = false

      return this.$store
        .dispatch('inventory/loadProduct', this.selectedProduct.id)
        .then((existingInventoryData) => {
          this.existingProductInventory = existingInventoryData.map(
            (warehouseInventory) => {
              return {
                ...warehouseInventory,
                assigned: true
              }
            }
          )
        })
        .catch((response) => {
          if (!response.ok && response.status !== 404) {
            this.error = true
            console.error(response.statusText)
          }
        })
        .finally(() => (this.loading = false))
    },
    loadInitialProduct () {
      return new Promise((resolve, reject) => {
        if (!this.id) { reject(new Error('Unable to load initial product, no ID supplied.')) }
        const product = this.productData(this.id)

        this.selectedProduct = product ?? {}

        if (product) resolve(product)
        else reject(new Error('No product found with the specified ID.'))
      })
    },
    buildWarehouseAddress (address) {
      return buildAddressString(address)
    },
    save () {
      const assignedWarehouses = this.warehouseOptions.filter(
        (warehouse) => warehouse.assigned
      )
      const selectedProduct = this.selectedProduct

      this.loading = true
      this.saveError = false

      return this.$store
        .dispatch('inventory/updateProductWarehouses', {
          product: selectedProduct,
          warehouses: assignedWarehouses
        })
        .then(() => {
          this.reset()
        })
        .catch((responseError) => {
          this.saveError = true
          console.error(responseError.statusText)
        })
        .finally(() => {
          this.loading = false
        })
    },
    reset () {
      this.selectedProduct = {}
      this.existingProductInventory = []
      this.warehouseOptions = []
    }
  },
  watch: {
    warehouses: {
      handler: 'loadTable',
      deep: true
    },
    selectedProduct: {
      handler: 'loadTable',
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.save-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
