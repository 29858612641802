<template>
  <section class="text-center">
    <v-icon
      color="primary"
      class="mb-4"
      large
    >
      {{ icon }}
    </v-icon>
    <h4 class="headings--text text-h4">
      <slot name="title">
        Oops!
      </slot>
    </h4>
    <p>
      <slot name="description">
        Something went wrong.
      </slot>
    </p>
    <slot name="post-description"></slot>
    <v-btn
      v-if="retryAllowed"
      color="primary"
      @click="alertRetry"
    >
      <slot name="retry">
        Try Again
      </slot>
    </v-btn>
  </section>
</template>

<script>
export default {
  name: 'DataTableError',
  props: {
    icon: {
      type: String,
      required: false,
      default: () => 'mdi-alert-circle-outline'
    },
    retryAllowed: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  methods: {
    alertRetry () {
      return this.$emit('retry')
    }
  }
}
</script>
