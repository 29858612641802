<template>
  <section>
    <table-filters
      :filters="filters"
      :loading="loading"
      :disabled="loading"
      @tabClicked="updateFilter"
    />

    <v-divider class="mb-8" />

    <data-table-error
      v-if="error"
      retry-allowed
      @retry="loadTableData"
    >
      <template v-slot:title>
        Oops!
      </template>
      <template v-slot:description>
        An error occurred while loading the requested data.
      </template>
    </data-table-error>
    <stock-count-list-view-table
      v-else
      :table-data="tableData"
      :active-filter="activeFilter"
      :loading="loading"
      :error="error"
      :page.sync="page"
      :items-per-page="itemsPerPage"
    />
  </section>
</template>

<script>
import StockCountListViewTable from './StockCountListViewTable'
import TableFilters from '@/components/generic/TableFilters'
import DataTableError from '@/components/generic/DataTableError'

export default {
  name: 'StockCountListView',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    StockCountListViewTable,
    TableFilters,
    DataTableError
  },
  data: () => ({
    loading: false,
    error: false,
    tab: null,
    activeFilter: 'all',
    page: 1,
    itemsPerPage: 10,
    queryArguments: {
      start: 0,
      limit: 50
    },
    totalRecordsRequested: false
  }),
  computed: {
    filters () {
      const tableData = this.tableData
      return [
        {
          label: 'All',
          value: 'all',
          quantity: tableData.length
        }
      ]
    },
    tableData () {
      return this.$store.getters['inventory/stockCounts']
    }
  },
  created () {
    this.freshLoadTableData()
  },
  methods: {
    updateFilter (tabItem) {
      this.activeFilter = tabItem.value
    },
    freshLoadTableData () {
      try {
        this.queryArguments = { start: 0, limit: 50 }
        this.totalRecordsRequested = false

        this.$store.dispatch('inventory/clearStockCounts')
          .then(() => this.loadTableData())
      } catch {
        this.error = true
      }
    },
    loadTableData () {
      this.loading = true
      this.error = false

      return this.$store.dispatch('inventory/loadStockCounts', { ...this.queryArguments, warehouse_id: this.id })
        .then((data) => {
          if (data.length < this.queryArguments.limit) {
            this.totalRecordsRequested = true
          }
        })
        .catch((response) => {
          if (!response.ok && response.status !== 404) {
            this.error = true
            console.error(response.statusText)
          }
        })
        .finally(() => (this.loading = false))
    },
    loadMore () {
      this.queryArguments.start += this.queryArguments.limit

      this.loadTableData()
    }
  },
  watch: {
    loading: {
      handler: function (newval) {
        this.$emit('loadingChanged', newval)
      },
      immediate: true
    },
    page: {
      handler: function (newval) {
        if (!this.totalRecordsRequested && (this.tableData.length / this.itemsPerPage) === newval) {
          this.loadMore()
        }
      }
    },
    id: {
      handler: 'freshLoadTableData'
    }
  }
}
</script>
