<template>
  <section class="inventory-location-form">
    <h4 class="text-h4 headings--text mb-1">
      Inventory Location
    </h4>

    <v-container
      class="px-0 mb-6"
      fluid
    >
      <v-row>
        <v-col>
          <input-label
            label="Location Name"
            tooltip="Provide a name for your Inventory Location."
          />
          <v-text-field
            v-model="form.name"
            :rules="[
              (v) => !!v || 'Location name is required.'
            ]"
            class='mr-3'
            label="Location Name"
            hide-details="auto"
            flat
            solo
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <input-label label="Inventory Location Type" />
          <v-select
            v-model="form.type"
            :items="typeOptions"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Inventory Location Type"
            hide-details="auto"
            outlined
            solo
            flat
            persistent-hint
          />
        </v-col>
        <v-col cols="6">
          <input-label
            label="Location Low Stock Notification"
            tooltip="When the stock level for an individual product drops to this number, you will recieve an email notification."
          />
          <v-text-field
            v-model.number="form.low_stock"
            :rules="[
              (v) => (Number(v) >= 0) || 'Negative numbers are not allowed for this field.'
            ]"
            class='mr-3'
            placeholder="0"
            hide-details="auto"
            flat
            solo
            outlined
          />
        </v-col>
      </v-row>
    </v-container>

    <v-divider class="mb-6" />

    <h4 class="text-h4 headings--text mb-4">Address</h4>

    <address-form
      v-model="form.address"
      class="mb-6"
    />

    <v-divider class="mb-6" />

    <h4 class="text-h4 headings--text mb-1">Contact Information</h4>
    <v-container
      class="px-0 mb-6"
      fluid
    >
      <v-row>
        <v-col>
          <input-label
            label="Location Email Address"
            tooltip="Email notifications will be sent to this address. eg. Low stock"
          />
          <v-text-field
            v-model="form.notification_email"
            label="example@gmail.com"
            class='mr-3'
            hide-details="auto"
            solo
            flat
            outlined
            persistent-hint
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <input-label label="Phone" />
          <v-text-field
            v-model="form.notification_phone"
            label="Phone"
            class='mr-3'
            hide-details="auto"
            solo
            flat
            outlined
            persistent-hint
          />
        </v-col>

        <v-col cols="6">
          <input-label label="Mobile" />
          <v-text-field
            v-model="form.notification_mobile"
            label="Mobile"
            class='mr-3'
            hide-details="auto"
            solo
            flat
            outlined
            persistent-hint
          />
        </v-col>
      </v-row>
    </v-container>
    <p
      v-if="error"
      class="mt-5 red--text text-right"
    >
      {{
        update
          ? messages.updateInventoryLocation.error
          : messages.createInventoryLocation.error
      }}
    </p>

    <section class="text-right mt-5">
      <v-btn
        color="primary"
        outlined
        class="mr-3"
        :disabled="loading"
        @click="cancel"
      >
        Cancel

        <template v-slot:loader>
          <custom-loader />
        </template>
      </v-btn>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading || !form.name"
        @click="submit"
      >
        Submit

        <template v-slot:loader>
          <custom-loader />
        </template>
      </v-btn>
    </section>
  </section>
</template>

<script>
import { warehouse } from '@/structures'
import { mapActions } from 'vuex'
import merge from 'lodash.merge'
import Messages from '@/lib/messages.json'
import InputLabel from '@/components/generic/InputLabel'
import AddressForm from '@/components/generic/AddressForm'
import CustomLoader from '@/components/generic/CustomLoader'

export default {
  name: 'CreateInventoryLocation',
  props: {
    location: {
      type: Object,
      required: false,
      default: () => warehouse
    },
    update: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    InputLabel,
    AddressForm,
    CustomLoader
  },
  created () {
    this.messages = Messages
  },
  data: () => ({
    loading: false,
    error: false,
    form: {
      name: '',
      type: '',
      low_stock: 0,
      address: {
        street1: '',
        street2: '',
        city: '',
        postcode: '',
        country: '',
        state: ''
      },
      notification_email: '',
      notification_phone: '',
      notification_mobile: '',
      messages: {}
    },
    typeOptions: [
      {
        text: 'Physical',
        value: 'physical'
      },
      {
        text: 'Partner',
        value: 'partner'
      },
      {
        text: 'Bonded',
        value: 'bonded'
      },
      {
        text: 'Storage',
        value: 'storage'
      }
    ]
  }),
  methods: {
    ...mapActions({
      createWarehouse: 'warehouses/create',
      updateWarehouse: 'warehouses/update'
    }),
    preFillLocationData () {
      this.form = merge(
        {},
        warehouse,
        JSON.parse(JSON.stringify(this.location))
      )
    },
    submit () {
      this.error = false
      this.loading = true

      return this.update ? this.updateExisting() : this.createNew()
    },
    createNew () {
      return this.createWarehouse(this.form)
        .then(() => {
          this.reset()
          this.$emit('save')
        })
        .catch((_) => (this.error = true))
        .finally(() => (this.loading = false))
    },
    updateExisting () {
      return this.updateWarehouse(this.form)
        .then(() => this.$emit('updated'))
        .catch((_) => (this.error = true))
        .finally(() => (this.loading = false))
    },
    cancel () {
      this.reset()
      this.$emit('cancel')
    },
    reset () {
      this.form = Object.assign({}, warehouse)
    }
  }
}
</script>
