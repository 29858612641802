<template>
  <section>
    <p class="mb-0 headings--text subtitle-2">{{ name }}</p>
  </section>
</template>

<script>
import warehouseMixin from '@/mixins/warehouse'
import { mapGetters } from 'vuex'
export default {
  name: 'TransferDestinationCell',
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  mixins: [warehouseMixin],
  computed: {
    ...mapGetters({
      find: 'warehouses/find'
    }),
    item () {
      return this.find(this.uuid)
    }
  }
}
</script>
