<template>
  <v-card
    rounded
    class="pa-5 grow d-flex flex-column"
    elevation="2"
    outlined
    height="100%"
  >
    <section class="d-flex flex-row align-start">
      <address-resource v-bind="$props" />

      <slot name="append-address" v-bind="$props"></slot>
    </section>

    <v-divider class="mt-4"/>

    <slot name="pre-actions" v-bind="$props"></slot>

    <slot name="address-card-actions"></slot>
  </v-card>
</template>

<script>
import AddressResource from '@/components/generic/AddressResource.vue'

export default {
  name: 'address-card',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    address: {
      type: String,
      required: true,
      default: ''
    },
    icon: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    AddressResource
  },
  data: () => ({
    dropdownOptions: ['Edit Location', 'Stock Management', 'Stock History']
  })
}
</script>
