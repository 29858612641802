<template>
  <v-container class="px-0" fluid>
    <v-row>
      <v-col>
        <input-label
          label="Address"
        />
        <v-text-field
          v-model="value.street1"
          label="Address"
          hide-details="auto"
          flat
          outlined
          solo
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <input-label
          label=""
        >
          <template v-slot:label>
            Apartment, suite, level, etc <span class="text--disabled">(optional)</span>
          </template>
        </input-label>
        <v-text-field
          v-model="value.street2"
          label="Apartment, suite, level, etc (optional)"
          hide-details="auto"
          flat
          outlined
          solo
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="8">
        <input-label
          label="Suburb"
        />
        <v-text-field
          v-model="value.city"
          label="Suburb"
          hide-details="auto"
          flat
          outlined
          solo
          required
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <input-label label="Postcode" />
        <v-text-field
          v-model.number="value.postcode"
          type="number"
          label="Postcode"
          hide-details="auto"
          flat
          outlined
          solo
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row >
      <v-col md="7">
        <input-label label="Country / Region" />
        <v-select
          v-model="value.country"
          :items='countriesList'
          :menu-props="{ bottom: true, offsetY: true }"
          label='Country'
          hide-details="auto"
          outlined
          solo
          flat
          persistent-hint
        />
      </v-col>
      <v-col md="5">
        <input-label label="State" />
        <v-select
          v-model="value.state"
          :items='countryProvincesList'
          :menu-props="{ bottom: true, offsetY: true }"
          label='State'
          hide-details="auto"
          outlined
          solo
          flat
          persistent-hint
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { address } from '@/structures'
import InputLabel from '@/components/generic/InputLabel'

export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: () => (address)
    }
  },
  data: () => ({
    checkbox: null
  }),
  components: {
    InputLabel
  },
  mounted () {
    // if (!this.value.country || this.value.country === '') {
    //   this.value.country = this.defaultCountry
    // }
  },
  computed: {
    ...mapGetters({
      getGraphQLClient: 'getGraphQLClient',
      allowedCountries: 'ecommerce/allowedCountries',
      defaultCountry: 'ecommerce/defaultCountry',
      regions: 'ecommerce/regions'
    }),
    countryRegions () {
      try {
        return this.regions[this.value.country]
      } catch (_) {
        return {}
      }
    },
    countriesList () {
      try {
        return Object.entries(this.allowedCountries)
          .map(([value, text]) => ({ text, value }))
      } catch (_) {
        return []
      }
    },
    countryProvincesList () {
      try {
        return Object.entries(this.countryRegions)
          .map(([value, text]) => ({ text, value }))
      } catch (_) {
        return []
      }
    }
  },
  methods: {
    submit () {}
  }
}
</script>
