<template>
  <section class="pa-4">
    <v-textarea
      v-model="note"
      hide-details="auto"
      placeholder="Enter details about uncounted, matched or unmatched inventory."
      dense
      outlined
      solo
      flat
      class="mb-3"
    />
    <section class="text-right">
      <v-btn
        color="primary"
        outlined
        class="mr-3"
        @click="cancelHandler"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        @click="saveHandler"
      >
        Save
      </v-btn>
    </section>
  </section>
</template>

<script>
export default {
  name: 'StockCountNoteEditor',
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    note: ''
  }),
  mounted () {
    this.setNote()
  },
  methods: {
    setNote () {
      this.note = this.form.note
    },
    cancelHandler () {
      return this.$emit('cancel')
    },
    saveHandler () {
      this.$emit('update:form', { ...this.form, note: this.note })
      this.$emit('save')
    }
  }
}
</script>
