import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import warehouses from './warehouses'
import inventory from './inventory'
import filestores from './filestores'
import notifications from './notifications'
import reports from './reports'
import ecommerce from './ecommerce'
import { GraphQLClient } from '@/lib/graphqlclient'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    applicationReady: false,
    applicationLoading: false,
    registry: {},
    enums: {
      TransactionType: []
    },
    variables: {
      TransactionType: []
    }
  },
  mutations: {
    SET_APPLICATION_READY: (state, payload) => (state.applicationReady = payload),
    SET_APPLICATION_LOADING: (state, payload) => (state.applicationLoading = payload),
    SET_APPLICATION_REGISTRY: (state, payload) => (state.registry = payload),
    STORE_ENUM_VALUES (state, payload) {
      Vue.set(state.enums, payload.key, payload.value)
    },
    STORE_VARIABLE_VALUES (state, payload) {
      Vue.set(state.variables, payload.key, payload.value)
    }
  },
  actions: {
    init ({ dispatch }, payload) {
      return Promise.all([
        dispatch('setApplicationLoading', true),
        dispatch('setApplicationConfig', payload)
      ])
        .then(() => dispatch('loadApplicationVariables'))
        .then(() => Promise.all([
          dispatch('warehouses/init'),
          dispatch('ecommerce/init'),
          dispatch('filestores/load')
        ]))
        .then(() => Promise.all([dispatch('inventory/init')]))
        .finally(() => Promise.all([
          dispatch('setApplicationReady', true),
          dispatch('setApplicationLoading', false)
        ]))
    },
    loadApplicationVariables ({ dispatch }) {
      const applicationVariables = ['transaction_type_descriptions', 'pos_channels']

      return Promise.all(
        applicationVariables.map(variable => dispatch('queryAndStoreVariables', { name: variable }))
      )
    },
    setApplicationConfig ({ dispatch, commit }, payload) {
      return dispatch('user/storeunVerifiedJWT', payload.auth)
        .then(() => dispatch('setRegistry', payload.registry))
        .catch((error) => console.error(`Error initializing application config: ${error.message}`))
    },
    setRegistry ({ commit }, payload) {
      commit('SET_APPLICATION_REGISTRY', payload)
    },
    setApplicationReady ({ commit }, payload) {
      commit('SET_APPLICATION_READY', payload)
    },
    setApplicationLoading ({ commit }, payload) {
      commit('SET_APPLICATION_LOADING', payload)
    },
    queryAndStoreEnums ({ getters, commit }, payload) {
      const ENUM_VALUES_QUERY = `
        query GetEnumValues($name: String!) {
          __type(name: $name) {
            enumValues {
              name
              description
            }
          }
        }
      `

      return getters.getGraphQLClient().query({
        query: ENUM_VALUES_QUERY,
        variables: { name: payload.name }
      })
        .then(res => {
          if (res && res.data && res.data.__type && res.data.__type.enumValues) {
            commit('STORE_ENUM_VALUES', {
              key: payload.name,
              value: res.data.__type.enumValues
            })
          }
        })
    },
    queryAndStoreVariables ({ getters, commit }, payload) {
      const VARIABLE_VALUES_QUERY = `
        query GetVariable($name: [String!]!) {
          variable(name: $name)
        }
      `

      return getters.getGraphQLClient().query({
        query: VARIABLE_VALUES_QUERY,
        variables: { name: payload.name }
      })
        .then(res => {
          if (res && res.data && res.data.variable && Object.prototype.hasOwnProperty.call(res.data.variable, payload.name)) {
            commit('STORE_VARIABLE_VALUES', {
              key: payload.name,
              value: res.data.variable[payload.name]
            })
          }
        })
    }
  },
  getters: {
    siteid: (state) => state.registry.site.id ?? '',
    serviceEndpoint: (state) => (payload) => {
      try {
        return state.registry.services[payload].endpoint
      } catch {
        return ''
      }
    },
    featureFlag: (state) => (payload) => {
      try {
        return state.registry.features[payload] ?? false
      } catch {
        return false
      }
    },
    getGraphQLClient: (state, getters) => (payload) => {
      return new GraphQLClient(getters.serviceEndpoint('datastore').concat('/graphql'))
    },
    applicationReady: (state) => state.applicationReady,
    applicationLoading: (state) => state.applicationLoading,
    enumValues (state) {
      return (key) => {
        return (Object.protoype.hasOwnProperty.call(state.enums, key))
          ? state.enums[key]
          : []
      }
    },
    variableValues (state) {
      return (key) => {
        return (Object.prototype.hasOwnProperty.call(state.variables, key))
          ? state.variables[key]
          : []
      }
    }
  },
  modules: {
    warehouses,
    filestores,
    ecommerce,
    inventory,
    user,
    notifications,
    reports
  }
})
