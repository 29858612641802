export default {
  namespaced: true,
  state: {
    notifications: []
  },
  getters: {
    all: (state) => state.notifications
  },
  mutations: {
    DISPLAY_NOTIFICATION (state, payload) {
      state.notifications = [...state.notifications, payload]
    },
    REMOVE_NOTIFICATION (state) {
      const notifications = [...state.notifications]
      notifications.shift()
      state.notifications = [...notifications]
    }
  },
  actions: {
    async displayNotification ({ dispatch, commit }, payload) {
      commit('DISPLAY_NOTIFICATION', payload)
      await new Promise((resolve, reject) => setTimeout(() => resolve(), 4000))
      dispatch('removeNotification')
    },
    removeNotification ({ commit }) {
      commit('REMOVE_NOTIFICATION')
    }
  }
}
