let applogger = {
  log: () => {},
  info: () => {},
  error: () => {},
  debug: () => {},
  warn: () => {}
}

if (process.env.NODE_ENV !== 'production') {
  applogger = {
    log: console.log.bind(console),
    info: console.info.bind(console),
    error: console.error.bind(console),
    debug: console.debug.bind(console),
    warn: console.warn.bind(console)
  }
}

export default applogger
