var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"page":_vm.pageModel,"headers":_vm.headers,"items":_vm.model,"search":_vm.search,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"item-class":_vm.itemClasses,"footer-props":_vm.footerOptions,"sort-by":"created","sort-desc":true},on:{"update:page":function($event){_vm.pageModel=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-0 body-2",attrs:{"label":"Search by transfer id, name...","prepend-inner-icon":"mdi-magnify","flat":"","clearable":"","simple":"","solo":"","plain":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s([null, ''].includes(item.reference) ? '-' : item.reference)+" ")]}},{key:"item.userid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s([null, ''].includes(item.userid) ? '-' : item.userid)+" ")]}},{key:"item.transfer_status",fn:function(ref){
var item = ref.item;
return [_c('transfer-status-cell',{attrs:{"status":item.transfer_status}})]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_c('transfer-quantity-cell',{attrs:{"products":item.products}})]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('transfer-date-cell',{attrs:{"date":item.created}})]}},{key:"item.destination_warehouse",fn:function(ref){
var item = ref.item;
return [_c('transfer-warehouse-cell',{attrs:{"uuid":item.destination_warehouse_id}})]}},{key:"item.source_warehouse",fn:function(ref){
var item = ref.item;
return [_c('transfer-warehouse-cell',{attrs:{"uuid":item.origin_warehouse_id}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$vcEllipsisIcon")])],1)]}}],null,true)},[_c('v-list',{attrs:{"width":"236"}},[_c('v-dialog',{attrs:{"width":"1000","light":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"ripple":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2"},[_vm._v(" View Transfer ")])],1)],1)]}}],null,true),model:{value:(_vm.transferDialog),callback:function ($$v) {_vm.transferDialog=$$v},expression:"transferDialog"}},[_c('section',{staticClass:"pa-5 white"},[_c('stock-transfer-form',{ref:"stocktransferform",attrs:{"readonly":"","prepopulate-transfer":Object.assign({}, item,
                  {products: _vm.loadTransferProducts(item.products)})},on:{"cancel":function($event){_vm.transferDialog = false}}})],1)])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }