<template>
  <v-list-item class="px-0">
    <component
      :is="selectComponent"
      :value="item.warehouse_id"
      color="primary"
      class="align-items-start"
      dense
      rounded
    >
      <template v-slot:label>
        <address-resource
          :title="name"
          :address="addressString"
          :icon="icon"
          title-classes="subtitle-1"
        />
      </template>
    </component>
  </v-list-item>
</template>

<script>
import AddressResource from '@aspedia/vuetify-components/generic/AddressResource'
import warehouse from '@/mixins/warehouse'
import { VCheckbox, VRadio } from 'vuetify/lib'

export default {
  name: 'warehouse-select-item',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ([])
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Array || Object,
      required: false,
      default: () => (null)
    }
  },
  mixins: [warehouse],
  components: {
    AddressResource,
    VCheckbox,
    VRadio
  },
  computed: {
    selectComponent () {
      return this.multiple
        ? 'v-checkbox'
        : 'v-radio'
    }
  }
}
</script>
