<template>
  <section class="white pa-6">
    <Titlebar
      :title="title"
    />

    <warehouse-select-list
      v-model="selectedWarehouseID"
      :warehouses="warehouseOptions"
    />

    <section class="d-flex flex-row justify-end align-center">
      <v-btn
        color="primary"
        outlined
        class="mr-3"
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        @click="save"
      >
        {{ submitText }}
      </v-btn>
    </section>
  </section>
</template>

<script>
import Titlebar from '@aspedia/vuetify-components/generic/Titlebar'
import WarehouseSelectList from '@/components/WarehouseSelectList'
import { mapGetters } from 'vuex'

export default {
  name: 'warehouse-select-dialog',
  props: {
    initialValue: {
      type: String,
      required: true,
      default: () => ('')
    },
    disallowed: {
      type: Array,
      required: false,
      default: () => ([])
    },
    title: {
      type: String,
      required: false,
      default: () => ('Select Warehouse')
    },
    submitText: {
      type: String,
      required: false,
      default: () => ('Select')
    }
  },
  components: {
    Titlebar,
    WarehouseSelectList
  },
  data: () => ({
    selectedWarehouseID: ''
  }),
  created () {
    this.selectedWarehouseID = this.initialValue ?? ''
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouses/all'
    }),
    warehouseOptions () {
      return this.warehouses.filter(warehouse => !this.disallowed.includes(warehouse.warehouse_id))
    }
  },
  methods: {
    save () {
      return this.$emit('save', this.selectedWarehouseID)
    },
    cancel () {
      return this.$emit('cancel', this.initialValue)
    }
  },
  watch: {
    initialValue: {
      handler: function (val) {
        this.selectedWarehouseID = val ?? ''
      }
    }
  }
}
</script>
