<template>
  <section>
    {{ quantity }}
  </section>
</template>

<script>
export default {
  name: 'TransferQuantityCell',
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  computed: {
    quantity () {
      let count = 0
      this.products.forEach(product => {
        if (Object.prototype.hasOwnProperty.call(product, 'transferQuantity')) count += Number(product.transferQuantity)
      })
      return count
    }
  }
}
</script>
