<template>
  <v-expansion-panel class="mb-8">
    <v-expansion-panel-header
      class="text-h3"
      :class="headerClasses"
    >
      <slot name="expansion-panel-header" />
      <template v-slot:actions>
        <v-icon color="primary" large>$expand</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        v-if="products.length > 0"
        :items="products"
        :headers="headers"
        class="mb-13"
        disable-pagination
        hide-default-footer
        fixed-header
      >
        <template v-slot:item.sku="{ item }">
          <span v-if="item.productsettings">
            {{
              [null, '', undefined].includes(item.productsettings.sku)
                ? '-'
                : item.productsettings.sku
            }}
          </span>
          <span v-else>-</span>
        </template>
        <template v-slot:item.difference="{ item }">
          <span v-if="item.counted_stock < item.stock">-</span>
          {{ Math.abs(item.counted_stock - item.stock) }}
        </template>
      </v-data-table>
      <h4 v-else class="text-h4 text-center disabled--text">{{ noProductsLabel }}</h4>

      <slot name="under-table"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'SummarisedStockCountFormPanel',
  props: {
    products: {
      type: Array,
      required: true
    },
    headerClasses: {
      type: String,
      required: false,
      default: ''
    },
    noProductsLabel: {
      type: String,
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: 'SKU',
        align: 'start',
        sortable: false,
        value: 'sku',
        width: '10%'
      },
      {
        text: 'Product',
        align: 'start',
        sortable: false,
        value: 'title',
        width: '60%'
      },
      {
        text: 'Expected Stock',
        align: 'end',
        sortable: false,
        value: 'stock',
        width: '10%'
      },
      {
        text: 'Counted',
        align: 'end',
        sortable: false,
        value: 'counted_stock',
        width: '10%'
      },
      {
        text: 'Difference',
        align: 'end',
        sortable: false,
        value: 'difference',
        width: '10%'
      }
    ]
  })
}
</script>
