<template>
  <div>
    <span class="mb-1">{{ item.title }}</span>
    <span v-if="Object.prototype.hasOwnProperty.call(item, 'status')" :class="{ 'orange--text': !item.status }" class="d-block body-2">
      {{ item.status ? 'Published' : 'Unpublished' }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    unitPrice () {
      try {
        return Number(this.item.productsettings.prices.unit_price).toFixed(2)
      } catch {
        return ''
      }
    }
  }
}
</script>
