<template>
  <v-data-table
    :page.sync="pageModel"
    :headers="headers"
    :items="model"
    :search="search"
    :loading="loading"
    :items-per-page="itemsPerPage"
    :item-class="itemClasses"
    :footer-props="footerOptions"
    sort-by="created"
    :sort-desc=true
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        label="Search by transfer id, name..."
        prepend-inner-icon="mdi-magnify"
        class="mx-0 body-2"
        flat
        clearable
        simple
        solo
        plain
        outlined
        dense
      ></v-text-field>
    </template>
    <template v-slot:item.reference="{ item }">
      {{ [null, ''].includes(item.reference) ? '-' : item.reference }}
    </template>
    <template v-slot:item.userid="{ item }">
      {{ [null, ''].includes(item.userid) ? '-' : item.userid }}
    </template>
    <template v-slot:item.transfer_status="{ item }">
      <transfer-status-cell :status="item.transfer_status" />
    </template>
    <template v-slot:item.products="{ item }">
      <transfer-quantity-cell :products="item.products" />
    </template>
    <template v-slot:item.created="{ item }">
      <transfer-date-cell :date="item.created" />
    </template>
    <template v-slot:item.destination_warehouse="{ item }">
      <transfer-warehouse-cell :uuid="item.destination_warehouse_id" />
    </template>
    <template v-slot:item.source_warehouse="{ item }">
      <transfer-warehouse-cell :uuid="item.origin_warehouse_id" />
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu
        offset-x
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-on="on"
            v-bind="attrs"
          >
            <v-icon color="primary">$vcEllipsisIcon</v-icon>
          </v-btn>
        </template>

        <v-list width="236">
          <v-dialog
            v-model="transferDialog"
            width="1000"
            light
            eager
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                ripple
                v-on="on"
                v-bind="attrs"
              >
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2">
                    View Transfer
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <section class="pa-5 white">
              <stock-transfer-form
                ref="stocktransferform"
                readonly
                :prepopulate-transfer="
                  {
                    ...item,
                    products: loadTransferProducts(item.products)
                  }
                "
                @cancel="transferDialog = false"
              />
            </section>
          </v-dialog>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import TransferStatusCell from './TransferStatusCell'
import TransferQuantityCell from './TransferQuantityCell'
import TransferDateCell from './TransferDateCell'
import TransferWarehouseCell from './TransferWarehouseCell'
import StockTransferForm from '@/components/stock-transfer-form/StockTransferForm'

export default {
  name: 'WarehouseTransfersTable',
  components: {
    TransferStatusCell,
    TransferQuantityCell,
    TransferDateCell,
    TransferWarehouseCell,
    StockTransferForm
  },
  props: {
    activeFilter: {
      type: String,
      required: true,
      default: () => ('all'),
      validator: (value) => (['all', 'pending', 'complete'].includes(value))
    },
    transfers: {
      type: Array,
      required: false,
      default: () => ([])
    },
    error: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    tableData: {
      type: [Array, Object],
      required: true
    },
    page: {
      type: [String, Number],
      required: true
    },
    itemsPerPage: {
      type: [String, Number],
      required: true
    }
  },
  data: () => ({
    transferDialog: false,
    pageCount: 0,
    search: '',
    headers: [
      {
        text: 'Transfer ID',
        align: 'start',
        sortable: true,
        value: 'reference',
        class: 'headings--text'
      },
      {
        text: 'Created Date',
        align: 'start',
        sortable: true,
        value: 'created',
        class: 'headings--text'
      },
      {
        text: 'Reason',
        align: 'start',
        sortable: false,
        value: 'transfer_reason',
        width: '100px',
        class: 'headings--text'
      },
      {
        text: 'Source',
        align: 'start',
        sortable: false,
        value: 'source_warehouse',
        class: 'headings--text'
      },
      {
        text: 'Destination',
        align: 'start',
        sortable: false,
        value: 'destination_warehouse',
        class: 'headings--text'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'transfer_status',
        width: '100px',
        class: 'headings--text'
      },
      {
        text: 'Transfer QTY',
        align: 'start',
        sortable: true,
        value: 'products',
        class: 'headings--text',
        sort: (a, b) => {
          return a[0].transferQuantity - b[0].transferQuantity
        }
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions',
        width: '100px',
        class: 'headings--text'
      }
    ],
    footerOptions: {
      'items-per-page-options': [10, 20, 50, 100, -1]
    }
  }),
  computed: {
    ...mapGetters({
      products: 'ecommerce/products',
      productData: 'ecommerce/singleProduct'
    }),
    pageModel: {
      get () {
        return this.page
      },
      set (val) {
        return this.$emit('update:page', val)
      }
    },
    model () {
      if (this.activeFilter === 'pending') return this.tableData.filter(transfer => transfer.transfer_status === 'pending')
      if (this.activeFilter === 'complete') return this.tableData.filter(transfer => transfer.transfer_status === 'complete')

      return this.tableData
    }
  },
  methods: {
    loadTransferProducts (transferProducts) {
      try {
        return transferProducts.map(transferProduct => ({
          ...this.productData(transferProduct.id) ?? {},
          ...transferProduct
        }))
      } catch {
        return []
      }
    },
    itemClasses (item) {
      return 'headings--text body-2'
    },
    loadStockClass (quantity) {
      if (Number(quantity) <= 0) {
        return 'red--text'
      }

      return 'green--text'
    }
  },
  watch: {
    transferDialog: {
      handler: function (val) {
        if (val) this.$refs.stocktransferform.prepopulateTransferForm()
        else this.$refs.stocktransferform.reset()
      }
    }
  }
}
</script>
