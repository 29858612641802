var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"stock-transfer-inventory mb-5"},[_c('h5',{staticClass:"text-h5 headings--text mb-5"},[_vm._v("Inventory")]),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.originWarehouseProducts.length > 0 || _vm.readonly)?_c('section',{key:"existing"},[(!_vm.readonly)?_c('v-autocomplete',{staticClass:"body-2",attrs:{"items":_vm.originWarehouseProducts,"loading":_vm.loading,"disabled":_vm.loading || _vm.notReady,"search-input":_vm.search,"filter":_vm.productSearchFilter,"placeholder":"Search Products","label":"Search Products","item-text":"title","item-value":"title","prepend-inner-icon":"mdi-magnify","dense":"","solo":"","flat":"","hide-no-data":"","hide-selected":"","multiple":"","outlined":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"item",fn:function(data){return [_c('autocomplete-product-item',{attrs:{"item":data.item}})]}}],null,false,2561369131),model:{value:(_vm.inventoryItems),callback:function ($$v) {_vm.inventoryItems=$$v},expression:"inventoryItems"}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.inventoryItems,"loading":_vm.loading,"fixed-header":"","disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.main",fn:function(ref){
var item = ref.item;
return [_c('resource-item',{key:item.id,attrs:{"sku":item.productsettings ? item.productsettings.sku : '-',"title":item.title,"image":_vm.generateProductImagePath(item),"stock":_vm.itemStock(item.id),"three-line":false,"display-stock":!_vm.readonly},scopedSlots:_vm._u([{key:"post-content",fn:function(){return [(!_vm.readonly)?_c('section',[(!_vm.selectedWarehouseInventory.find(function (product) { return Number(product.product_id) === Number(item.id); }))?_c('v-list-item-subtitle',{staticClass:"mt-2"},[_c('span',{staticClass:"red--text"},[_vm._v("This product is not available at your source location.")])]):(Number(item.transferQuantity) > Number(_vm.itemStock))?_c('v-list-item-subtitle',{staticClass:"mt-2"},[_c('span',{staticClass:"red--text"},[_vm._v("Quantity exceeds current stock, the origin point only has "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(Number(_vm.itemStock)))]),_vm._v(" items available.")])]):(Number(item.transferQuantity) <= 0 || isNaN(item.transferQuantity))?_c('v-list-item-subtitle',{staticClass:"mt-2"},[_c('span',{staticClass:"red--text"},[_vm._v("A minimum of "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("1")]),_vm._v(" unit must be transferred between locations.")])]):_c('v-list-item-subtitle',{staticClass:"mt-2"},[_c('span',{staticClass:"orange--text"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(Number(item.transferQuantity)))]),_vm._v(" units will be removed from the origin transfer point.")])])],1):_vm._e()]},proxy:true}],null,true)})]}},{key:"item.actions",fn:function(slotProps){return [_c('section',{staticClass:"d-flex flex-row align-start justify-end py-3"},[(!_vm.readonly && _vm.selectedWarehouseInventory.find(function (product) { return Number(product.product_id) === Number(slotProps.item.id); }))?_c('v-text-field',{attrs:{"type":"number","disabled":_vm.readonly || slotProps.item.stock <= 0,"max":slotProps.item.max,"rules":[
                function(val) {
                  if (val > slotProps.item.max) { return false }
                  if (val <= 0) { return false }

                  return true
                }
              ],"min":"0","outlined":"","dense":""},model:{value:(slotProps.item.transferQuantity),callback:function ($$v) {_vm.$set(slotProps.item, "transferQuantity", _vm._n($$v))},expression:"slotProps.item.transferQuantity"}}):(_vm.readonly)?_c('span',[_vm._v(" "+_vm._s(slotProps.item.transferQuantity)+" ")]):_vm._e(),(!_vm.readonly)?_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","small":"","plain":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.$emit('removeProductByID', slotProps.item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$vcBinIcon")])],1):_vm._e()],1)]}}],null,false,2710163941)})],1):_c('section',{key:"missing"},[_c('h4',{staticClass:"text-h4 headings--text text-center text--disabled"},[_vm._v(" No products were found to select from. ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }