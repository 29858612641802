export default {
  load ({ rootGetters, commit, dispatch }) {
    const base = rootGetters.serviceEndpoint('datastore')
    const endpoint = base.concat('/vuejs/filestores')

    return fetch(endpoint)
      .then(async requestResponse => {
        const filestores = await requestResponse.json()

        if (!requestResponse.ok) {
          const error = (filestores && filestores.message) || requestResponse.statusText

          return Promise.reject(error)
        }

        return dispatch('store', filestores.data ?? {})
      })
      .catch(error => {
        console.error(`Failed loading filestores: ${error}`)
      })
  },
  store ({ commit }, payload) {
    commit('UPDATE_FILESTORES', payload)
  }
}
