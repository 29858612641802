export default {
  apiRequestsEndpoint: (state, getters, rootState, rootGetters) => (rootGetters.serviceEndpoint('inventory').concat('/api/v1/admin/inventory')),
  configRequestsEndpoint: (state, getters, rootState, rootGetters) => (rootGetters.serviceEndpoint('inventory').concat('/api/v1/admin/site')),
  transferRequestsEndpoint: (state, getters, rootState, rootGetters) => (getters.apiRequestsEndpoint.concat('/stock-transfer')),
  stockCountRequestsEndpoint: (state, getters, rootState, rootGetters) => (getters.apiRequestsEndpoint.concat('/stock-count')),
  stockCounts: (state) => state.stockCounts,
  transfers: (state, getters) => state.transfers,
  config: (state) => state.config,
  defaultConfig: (state, getters, rootState, rootGetters) => {
    const salesChannels = rootGetters.variableValues('transaction_type_descriptions')
    const posChannels = rootGetters.variableValues('pos_channels')
    const wineClubs = rootGetters['ecommerce/wineclubs']
    const defaultStructure = { warehouse: '', transferWarehouse: [] }

    const posChannelsConfig = Object.keys(posChannels).reduce((acc, key) => ({
      ...acc,
      [key]: Object.assign({}, defaultStructure)
    }), {})

    const wineclubsConfig = Object.keys(wineClubs).reduce((acc, key) => ({
      ...acc,
      [key]: Object.assign({}, defaultStructure)
    }), {})

    const configValue = (key) => {
      if (key === 'pos') return posChannelsConfig
      if (key === 'somnambulist') return wineclubsConfig

      return Object.assign({}, defaultStructure)
    }

    const config = Object.keys(salesChannels).reduce((acc, key) => ({
      ...acc,
      [key]: configValue(key)
    }), {})

    return config
  },
  findWarehouseInventory: (state, getters) => payload => state.warehouses[payload] ?? []
}
