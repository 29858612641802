<template>
  <v-app>
    <v-slide-x-transition mode="out-in">
      <component
        :key="activeDisplay"
        :is="activeDisplay"
      />
    </v-slide-x-transition>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import MainApplication from '@/layouts/MainApplication'
import LoginApplication from '@/layouts/LoginApplication'
import LoadingApplication from '@/layouts/LoadingApplication'

export default {
  name: 'App',
  components: {
    MainApplication,
    LoadingApplication,
    LoginApplication
  },
  computed: {
    ...mapGetters({
      ready: 'applicationReady',
      loading: 'applicationLoading',
      loggedIn: 'user/isLoggedIn'
    }),
    activeDisplay () {
      if (!this.loggedIn) return 'LoginApplication'

      return (!this.ready)
        ? 'loading-application'
        : 'main-application'
    }
  }
}
</script>

<style lang="scss">
.v-list-item--link {
  &:hover,
  &:active,
  &:focus {
    background: #F2F5FF;

    &:not(.v-list-item--active):not(.v-list-item--disabled) {
      .v-list-item__title {
        color: var(--v-anchor-base);
      }
    }
  }

}
</style>
