var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h4',{staticClass:"headings--text text-h4 mb-0"},[_vm._v(_vm._s(_vm.form.name))]),(_vm.form.description)?_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.form.description))]):_vm._e(),_c('section',{staticClass:"mt-11"},[_c('p',{staticClass:"text-h6 headings--text"},[_vm._v("Product Filter")]),_c('p',[_vm._v("These products can then be tabbed through to add the stock count on the number field.")]),_c('v-data-table',{staticClass:"mb-16",attrs:{"headers":_vm.headers,"items":_vm.filteredProductsModel,"search":_vm.search,"item-key":"value","disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"500"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-0 body-2",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search via SKU or Product Title","flat":"","clearable":"","simple":"","solo":"","plain":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tabs',{model:{value:(_vm.filterIndex),callback:function ($$v) {_vm.filterIndex=$$v},expression:"filterIndex"}},_vm._l((_vm.filters),function(item){return _c('v-tab',{key:item.label,staticClass:"text-capitalize text-h6"},[_vm._v(" "+_vm._s(item.label)+" "),_c('transition',{attrs:{"name":"bounce","mode":"out-in"}},[_c('v-chip',{staticClass:"ml-2 px-2 counter-pill",class:_vm.tabUpdated(item.value),attrs:{"pill":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.quantity)+" ")])],1)],1)}),1),_c('v-divider',{staticClass:"mb-8"})]},proxy:true},{key:"item.status",fn:function(slotProps){return [_c('compare-stock-count-row-status',{attrs:{"status":slotProps.value}})]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.loadStatusTextColor(item)},[_vm._v(" "+_vm._s(item.stock)+" ")])]}},{key:"item.counted_stock",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{key:item.product_id,attrs:{"type":"number","value":item.counted_stock,"tabindex":"0","hide-details":"","outlined":"","dense":""},on:{"blur":function (value) { return _vm.updateCountedStock(item.product_id, value); }}})]}}])}),_c('section',{staticClass:"d-md-flex flex-row align-center justify-space-between"},[_c('section',[_c('v-btn',{attrs:{"color":"body","outlined":"","large":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel Stock Count ")])],1),_c('section',{staticClass:"d-flex flex-row align-center justify-end"},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"400","top":"","left":"","offset-y":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary","outlined":"","dark":"","large":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.form.note !== '' ? 'Edit Note' : 'Add Note')+" ")])]}}]),model:{value:(_vm.menus.notes),callback:function ($$v) {_vm.$set(_vm.menus, "notes", $$v)},expression:"menus.notes"}},[_c('stock-count-note-editor',{ref:"stockcountnoteeditor",attrs:{"form":_vm.formModel},on:{"update:form":function($event){_vm.formModel=$event},"cancel":function($event){_vm.menus.notes = false},"save":function($event){_vm.menus.notes = false}}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","large":"","disabled":_vm.form.products.length <= 0 || _vm.loading,"loading":_vm.loading},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('custom-loader')]},proxy:true}],null,true)},'v-btn',attrs,false),on),[_vm._v(" Complete Stock Count ")])]}}]),model:{value:(_vm.dialogs.confirmationDialog),callback:function ($$v) {_vm.$set(_vm.dialogs, "confirmationDialog", $$v)},expression:"dialogs.confirmationDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 headings--text text-center"},[_vm._v(" Submit Stock Count ")]),_c('v-card-text',[_c('p',[_vm._v("Are you sure you want to submit this stock count?")]),_c('p',[_vm._v("Once submitted, stock counts are only available for preview and not adjustable.")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){_vm.dialogs.confirmationDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.confirmStockCountSubmission}},[_vm._v(" Yes ")])],1)],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }