<template>
  <section>
    <v-list>
      <v-radio-group
        v-model="selectedWarehouse"
      >
        <section
          v-for="(item, index) in warehouses"
          :key="item.warehouse_id"
        >
          <warehouse-select-item :item="item" />
          <v-divider
            v-if="index !== warehouses.length -1"
            class="my-5"
          />
        </section>
      </v-radio-group>
    </v-list>
  </section>
</template>

<script>
import WarehouseSelectItem from '@aspedia/vuetify-components/WarehouseSelectItem'

export default {
  name: 'WarehouseSelectList',
  components: {
    WarehouseSelectItem
  },
  props: {
    warehouses: {
      type: Array,
      required: true,
      default: () => ([])
    },
    value: {
      type: String,
      required: true,
      default: () => ('')
    }
  },
  computed: {
    selectedWarehouse: {
      get () {
        return this.value
      },
      set (val) {
        return this.$emit('input', val)
      }
    }
  }
}
</script>
