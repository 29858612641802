export default {
  namespaced: true,
  actions: {
    download ({ state, getters, dispatch, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        if (!payload.template) reject(new Error('Report downloads require a template specified.'))
        if (!payload.filename) reject(new Error('Report downloads require a file name specified.'))

        // -- REPORT DATA --
        const template = {
          recipe: payload.recipe ?? 'chrome/pdf',
          ...payload.template ?? {}
        }

        const options = {
          template: {
            siteid: rootGetters.siteid,
            jwt: rootGetters['user/JWT'],
            ...(
              (Object.prototype.hasOwnProperty.call(payload, 'options') && Object.prototype.hasOwnProperty.call(payload.options, 'template')) &&
              payload.options.template
            )
          }
        }

        const reportBody = JSON.stringify({
          template,
          options
        })

        // -- REQUEST SETTINGS
        const baseURL = rootGetters.serviceEndpoint('jsreports')
        const requestEndpoint = baseURL.concat('/api/report')
        const requestHeaders = {
          'Content-type': 'application/json',
          'X-Site-ID': rootGetters.siteid,
          Authorization: `Bearer ${rootGetters['user/JWT']}`
        }

        const requestOptions = {
          method: 'POST',
          headers: requestHeaders,
          body: reportBody
        }

        return fetch(requestEndpoint, requestOptions)
          .then((response) => {
            if (response.status === 200) return Promise.all([response.arrayBuffer(), response])

            reject(new Error(response.statusText))
          })
          .then(([resolved, response]) => {
            const blob = new Blob([resolved], { type: response.headers.get('Content-Type') })
            // Create "virtual" link
            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(blob)
            a.download = payload.filename
            a.click()

            resolve()
          })
          .catch((err) => reject(err))
      })
    }
  }
}
