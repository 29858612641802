import store from '../store'

const buildAddressString = (address) => {
  try {
    if (address === null || typeof address !== 'object') return ''
    const countries = store.getters['ecommerce/allowedCountries']

    const { street1, street2, city, postcode, country, state } = address

    return [street1, street2, city, [state, postcode].join(' ').trim(), countries[country]]
      .filter(x => x && x !== '')
      .join(', ')
      .replace(/^,/, '')
      .trim()
  } catch {
    return ''
  }
}

export default buildAddressString
