<template>
  <v-container>
    <v-row>
      <v-col>
        <section class="d-flex flex-row align-items-center">
          <v-btn
            color="primary"
            outlined
            class="px-0 mr-4"
            to="/"
            min-width="40px"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <Titlebar
            title="Stock Reports"
            titleClasses="text-h3 headings--text mr-3"
            class="mb-9 grow"
          >
            <template v-slot:actions>
              <v-btn
                color="primary"
                class="mr-3"
                :to="`/inventory-location/${id}/count-stock`"
                :disabled="warehouseHasNoProducts"
              >
                Create Stock Count
              </v-btn>
            </template>
          </Titlebar>
        </section>

        <v-sheet
          class="d-sm-flex flex-row align-center pa-4 mb-8"
          outlined
          rounded
        >
          <address-resource
            :title="selectedWarehouseName"
            :address="selectedWarehouseAddress"
            :icon="selectedWarehouseIcon"
            title-classes="subtitle-1"
          />

          <v-btn
            v-if="item"
            outlined
            color="primary"
            class="mr-3"
            @click="clearWarehouse"
          >
            Clear
          </v-btn>

          <v-dialog
            v-model="dialog"
            width="600"
            rounded
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                color="primary"
                :disabled="loading"
              >
                {{ item ? 'Change' : 'Select' }}
              </v-btn>
            </template>

            <warehouse-select-dialog
              :initial-value="uuid"
              @save="changeWarehouse"
              @cancel="closeDialog"
            />
          </v-dialog>
        </v-sheet>

        <stock-count-list-view
          :id="uuid"
          @loadingChanged="handleListViewLoading"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import warehouseMixin from '@/mixins/warehouse'
import AddressResource from '@/components/generic/AddressResource'
import StockCountListView from '@/components/stock-count-list-view/StockCountListView'
import WarehouseSelectDialog from '@aspedia/vuetify-components/WarehouseSelectDialog'
import Titlebar from '@/components/generic/Titlebar'

export default {
  name: 'WarehouseStockCountsOverview',
  props: {
    uuid: {
      type: String,
      required: true,
      default: ''
    }
  },
  components: {
    AddressResource,
    WarehouseSelectDialog,
    Titlebar,
    StockCountListView
  },
  mixins: [warehouseMixin],
  data: () => ({
    dialog: false,
    loading: false,
    error: false
  }),
  computed: {
    ...mapGetters({
      find: 'warehouses/find'
    }),
    item () {
      return this.find(this.uuid)
    },
    selectedWarehouseIcon () {
      return this.item
        ? this.icon
        : ''
    },
    selectedWarehouseName () {
      return this.item
        ? this.name
        : 'Select Inventory Location'
    },
    selectedWarehouseAddress () {
      return this.item
        ? this.addressString
        : 'This will filter the list below and enable the Stock Count creation.'
    }
  },
  methods: {
    clearWarehouse () {
      this.changeWarehouse('')
    },
    handleListViewLoading (payload) {
      this.loading = payload
    },
    changeWarehouse (uuid) {
      this.$router.push(`/stock-count-overview/${uuid}`)
      this.closeDialog()
    },
    closeDialog () {
      if (this.dialog) {
        this.dialog = false
      }
    }
  },
  watch: {
    uuid: {
      handler: 'loadWarehouseInventory',
      immediate: true
    }
  }
}
</script>
