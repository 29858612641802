<template>
  <summarised-stock-count-form-panel
    :products="unmatchedProducts"
    header-classes="orange--text"
    no-products-label="There are no Unmatched items in this stock count."
  >
    <template v-slot:expansion-panel-header>
      Unmatched ({{ unmatchedProducts.length }})
    </template>
    <template v-slot:under-table>
      <section v-if="unmatchedProducts.length > 0">
        <h3 class="black--text text-h3 mb-8">Stock Discrepancies</h3>

        <v-row>
          <v-col cols="12" md="3">
            <p>
              <span class="red--text font-weight-bold">{{ missingInventoryTotal | toPrice }}</span>
              in Missing Inventory ({{ missingInventoryCount }} Items)
            </p>
          </v-col>
          <v-col cols="12" md="3">
            <p>
              <span class="green--text font-weight-bold">{{ surplusInventoryTotal | toPrice }}</span>
              in Surplus Inventory ({{ surplusInventoryCount }} Items)
            </p>
          </v-col>
        </v-row>
      </section>
    </template>
  </summarised-stock-count-form-panel>
</template>

<script>
import SummarisedStockCountFormPanel from './SummarisedStockCountFormPanel'

export default {
  name: 'UnmatchedStockCountPanel',
  props: {
    unmatchedProducts: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },
  components: {
    SummarisedStockCountFormPanel
  },
  computed: {
    // Surplus
    surplusInventoryProducts () {
      return this.unmatchedProducts.filter(product => {
        return Number(product.counted_stock) > Number(product.stock)
      })
    },
    surplusInventoryCount () {
      return this.surplusInventoryProducts.reduce((acc, product) => {
        return acc + (Number(product.counted_stock) - Number(product.stock))
      }, 0)
    },
    surplusInventoryTotal () {
      return this.surplusInventoryProducts.reduce((acc, product) => {
        const surplusInventoryTotal = (product.counted_stock - product.stock) * product.productsettings.prices.unit_price
        return (acc += surplusInventoryTotal)
      }, 0.00)
    },
    // Missing
    missingInventoryProducts () {
      return this.unmatchedProducts.filter(product => {
        return Number(product.counted_stock) < Number(product.stock)
      })
    },
    missingInventoryCount () {
      return this.missingInventoryProducts.reduce((acc, product) => {
        return acc + ((product.stock) - Number(product.counted_stock))
      }, 0)
    },
    missingInventoryTotal () {
      return this.missingInventoryProducts.reduce((acc, product) => {
        const missingInventoryCost = (Number(product.stock) - Number(product.counted_stock)) * parseFloat(product.productsettings.prices.unit_price)
        return (acc += missingInventoryCost)
      }, 0.00)
    }
  }
}
</script>
