<template>
  <v-tabs v-model="tab">
    <v-tab
      v-for="item in filters"
      :key="item.value"
      :disabled="disabled"
      class="text-capitalize text-h6 mr-3 primary--text"
      @click="tabClickHandler(item)"
    >
      {{ item.label }}

      <v-chip
        pill
        outlined
        small
        class="ml-2 px-2"
      >
        {{ item.quantity }}
      </v-chip>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'TableFilters',
  props: {
    filters: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    tab: 0
  }),
  methods: {
    tabClickHandler (tabItem) {
      this.$emit('tabClicked', tabItem)
    },
    tabModelHandler () {
      this.$emit('tabChanged', this.tab)
    }
  },
  watch: {
    tab: {
      handler: 'tabModelHandler'
    }
  }
}
</script>
