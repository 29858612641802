import { GraphQLClient } from '@/lib/graphqlclient'
import queries from '@/lib/queries'

const queryChunkSize = 50
const MAX_WORKERS = 2

export default {
  init ({ dispatch }) {
    return Promise.all([
      dispatch('loadConfig').then(() => dispatch('loadRegions')),
      dispatch('loadProducts'),
      dispatch('loadWineClubs')
    ])
  },
  loadConfig ({ rootGetters, commit }) {
    const requestURL = rootGetters.serviceEndpoint('datastore').concat('/vuejs/ecommerce/config')

    const requestOptions = {
      method: 'GET'
    }

    return fetch(requestURL, requestOptions)
      .then((response) => response.json())
      .then((config) => {
        commit('SET_ECOMMERCE_CONFIG', config.data)
        return config.data
      })
      .catch((err) => (console.error(`Error loading ecommerce configuration: ${err.message}`)))
  },
  loadRegions ({ state, rootGetters, commit }) {
    let countries = 'au'

    try {
      countries = Object.keys(state.config.address.allowed_countries).join(',')
    } catch (_err) {
      console.error('Using default country list: au')
    }

    const requestURL = rootGetters.serviceEndpoint('datastore').concat('/vuejs/ecommerce/provinces/', countries)
    const requestOptions = { method: 'GET' }

    return fetch(requestURL, requestOptions)
      .then(response => response.json())
      .then(response => {
        commit('SET_REGIONS', response.data)
        return response.data
      })
      .catch((err) => console.error(`Error loading ecommerce provines: ${err.message}`))
  },
  loadWineClubs ({ getters, rootGetters, commit }, payload) {
    return new GraphQLClient(rootGetters.serviceEndpoint('datastore').concat('/graphql'))
      .query({
        opName: 'getWineClubs',
        query: queries
      })
      .then(resp => {
        if (resp.data === undefined) {
          // Handle errors
          // No More records to be loaded
          return false
        } else {
          if (resp.data.wineclubs) {
            const wineclubs = {
              default: {
                title: 'Default'
              }
            }
            resp.data.wineclubs.forEach((club) => {
              wineclubs[club.clubid] = club
            })
            commit('SET_WINECLUBS', wineclubs)
          }
          if (resp.errors) {
            // Handle errors
            return true
          }
        }
      })
  },
  loadProducts ({ getters, rootGetters, commit }, payload) {
    const fetchedProducts = []
    let workers = 0
    let start = 0

    const loadProducts = function (start) {
      return new GraphQLClient(rootGetters.serviceEndpoint('datastore').concat('/graphql'))
        .onQuery(context => {
          return {
            ...context,
            headers: {
              ...context.headers,
              Authorization: `Bearer ${rootGetters['user/JWT']}`
            }
          }
        })
        .query({
          opName: 'getProducts',
          query: queries,
          variables: {
            start: start,
            limit: queryChunkSize
          }
        })
        .then(resp => {
          if (resp.data === undefined) {
            // Handle errors
            // No More records to be loaded
            return false
          } else {
            if (resp.data.products) {
              fetchedProducts.push(resp.data.products.filter(product => product !== null))

              // True implies more products to be loaded
              // as a full resultset was returned.
              return resp.data.products.length === queryChunkSize
            }

            if (resp.errors) {
              // Handle errors
              return true
            }
          }
        })
    }

    const p = new Promise((resolve, reject) => {
      (function run () {
        while (workers <= MAX_WORKERS) {
          const workerFunc = () => {
            workers++
            loadProducts(start)
              .then(moreRecords => {
                workers--
                if (moreRecords) {
                  setTimeout(run, 0)
                } else if (workers === 0) {
                  resolve()
                }
              })
            start += queryChunkSize
          }

          workerFunc()
        }
      })()
    })

    return p.then(_ => {
      const products = {}
      fetchedProducts.flat().forEach((prod) => {
        products[prod.id] = prod
      })
      commit('SET_PRODUCTS', products)
      return products
    })
  }
}
