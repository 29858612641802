<template>
  <v-list-item
    :three-line="threeLine"
    class="py-5 px-0 align-start"
  >
    <v-sheet
      rounded
      outlined
      class="pa-2 mr-4"
    >
      <v-img
        :src="image"
        height="30"
        width="30"
        contain
        @error="imageLoadFailed"
      >
        <template v-slot:placeholder>
          <v-skeleton-loader
            v-if="imageLoading"
            type="image" width="50" height="50"
          />
        </template>
      </v-img>
    </v-sheet>
    <v-list-item>
      <v-list-item-content class="py-0">
        <v-list-item-title class="headings--text mb-1">{{ title }}</v-list-item-title>
        <v-list-item-subtitle class="mt-0 mb-0 body-2">
          <span>SKU {{ skuLabel }}</span>
          <span
            v-if="displayStock"
            class="pl-2"
            :class="Number(stock) > 0 ? 'green--text' : 'red--text'"
          >
            {{ [null, '', undefined].includes(stock) ? '0' : stock }} available.
          </span>
        </v-list-item-subtitle>
        <slot name="post-content"></slot>
      </v-list-item-content>
    </v-list-item>
  </v-list-item>
</template>

<script>
export default {
  name: 'resource-item',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    sku: {
      type: String,
      required: false,
      default: ''
    },
    image: {
      type: String,
      required: false,
      default: ''
    },
    stock: {
      type: [String, Number],
      required: false,
      default: '0'
    },
    threeLine: {
      type: Boolean,
      required: false,
      default: () => (true)
    },
    displayStock: {
      type: Boolean,
      required: false,
      default: () => (false)
    }
  },
  data: () => ({
    quantity: 0,
    imageFailed: false
  }),
  computed: {
    imageLoading () {
      return !this.imageFailed && this.image !== ''
    },
    skuLabel () {
      return [null, undefined, ''].includes(this.sku)
        ? '-'
        : this.sku
    }
  },
  methods: {
    imageLoadFailed () {
      this.imageFailed = true
    }
  }
}
</script>
