<template>
  <v-alert
    border="left"
    colored-border
    :value="true"
    :type="type"
    light
    fixed
    bottom
    right
    rounded
    width="400"
    elevation="2"
  >
    {{ text }}
  </v-alert>
</template>

<script>
export default {
  name: 'notification',
  props: {
    text: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validation: (val) => ['info', 'success', 'error', 'warning'].includes(val)
    }
  }
}
</script>
